import { environment } from '../../../environments/environment';
import { CoordinateSystem } from '../../tenant/tenant.model';

export const LOCAL_COORDINATE_SYSTEM_ID = 'LOCAL';

export function isLocalCS(cs: CoordinateSystem) {
  return cs && cs.id === LOCAL_COORDINATE_SYSTEM_ID;
}

export type ServiceName =
  | 'fms'
  | 'mms'
  | 'ums'
  | 'file'
  | 'tenant'
  | 'jms'
  | 'package'
  | 'integration'
  | 'nms'
  | 'activity'
  | 'resourcelinks'
  | 'aimanagement';

export function getServiceUrl(serviceName: ServiceName) {
  const baseUrl = environment.serviceUrls?.[serviceName] || environment.serverUrl;
  return `${baseUrl}/${serviceName}`;
}

export enum ServerError {
  SiteDoesNotExist = 'SITE_DOES_NOT_EXIST',
  TaskDoesNotExist = 'TASK_DOES_NOT_EXIST',
  LockedUser = 'LOCKED_USER',
  LoginFail = 'LOGIN_FAIL',
  ExceptionInValidateCounter = 'EXCEPTION_IN_VALIDATE_COUNTER',
  ForgotPasswordTokenNotMatch = 'FORGOT_PASSWORD_TOKEN_NOT_MATCH',
  TokenNotValid = 'TOKEN_NOT_VALID',
  GradeCheckReportNameExists = 'GRADE_CHECK_REPORT_NAME_EXISTS',
  TaskNotInUpdateGcpState = 'TASK_NOT_IN_UPDATE_GCP_STATE',
  ThereAreTasksInProcessing = 'THERE_ARE_TASK_IN_PROCESSING',
  InvalidProjectPlanName = 'INVALID_PROJECT_PLAN_NAME',
  TaskContainsDirtyGCP = 'TASK_CONTAINS_DIRTY_GCP',
  ProjectPlanNameExists = 'PROJECT_PLAN_NAME_EXISTS',
  OrthophotoDoesNotExist = 'ORTHOPHOTO_DOES_NOT_EXIST',
  ECWGeneratingStillProcessing = 'ECW_GENERATING_STILL_PROCESSING',
  ZipImagesGeneratingStillProcessing = 'ZIP_IMAGES_GENERATING_STILL_PROCESSING',
  userMultiActiveSessions = 'USER_MULTI_ACTIVE_SESSIONS'
}
