import { isDefined } from '../../../shared/utils/general';
import { roundTo } from '../../../shared/utils/math';
import { convertToMeters, DistanceUnitsEnum } from '../../../shared/utils/unit-conversion';
import { CanvasSize } from '../../site-map/designs-layer/fill-patterns-utils';

const DEFAULT_PATTERN_LINE_WIDTH_PX = 2;
const DEFAULT_PATTERN_SPACING_PX = 2;
const DEFAULT_FILL_ALPHA = '4D'; // 30%
const DEFAULT_LINES_ALPHA = '99'; // 60%

export const DGN_FEATURE_STYLE_FLAGS = {
  useDgnStyle: true,
  scaleHatch: true,
  scaleDashedHatch: true
};

export enum DGNFeatureFillType {
  SOLID = 'solid',
  LINES = 'lines',
  CROSS_LINES = 'cross-lines'
}

interface DGNFeatureGeneralStyleProperties {
  /** Analog of css z-index */
  priority?: number;
  /** Color of border line. HEX8 format: #RRGGBBAA (AA is alpha i.e. opacity). */
  color?: string;
  /**
   * DGN (and other formats - ?) uses a counterclockwise rotation:
   * Y
   * |   /
   * |  / )<- positive rotation value
   * 0 -------X
   *
   * But the canvas rotates clockwise:
   * 0 -------X
   * |  \ ) <- positive rotation value
   * |   \
   * Y
   *
   * => multiply the rotation by -1 for the expected behaviour
   */
  /** Angle of feature rotation. Degrees in range [-360, 360] */
  rotation?: number;
}

export interface DGNFeatureFillProperties {
  /** Type of feature filling. DETECTED ON THE FE SIDE. */
  fill_type_detected?: DGNFeatureFillType;
  /** Background color. Transparant if undefined.
   *
   *  HEX8 format: #RRGGBBAA (AA is alpha: '00' - fully transparent, 'ff' - fully opaque).
   */
  color?: string;
  /** Color of hatching lines. HEX8 format: #RRGGBBAA (AA is alpha i.e. opacity).
   *
   * Use general.color, if undefined on the BE side. */
  pattern_color?: string;
  /** Dash lines style: [length of dash, length of space, length of dash, length of space, ...].
   *
   * Solid line if [1, 0]. */
  pattern_style?: number[];
  /**
   * 0 ------------X
   * |\   \   \ ) <- pattern_angle (positive)
   * | \   \   \
   * |  \   \< >\
   * Y         pattern_spacing
   */
  /** Slope angle of hatching lines. Degrees in range [-90, 90].
   *
   * Considers rotation and direction on the BE side: -1 * (pattern_angle + general.rotation). */
  pattern_angle?: number;
  /** Slope angle of cross hatching lines. Degrees in range [-90, 90].
   *
   * Considers rotation and direction on the BE side: -1 * (pattern_angle + general.rotation).  */
  cross_pattern_angle?: number;
  /** Slope angle of hatching lines. Radians in range [-Pi/2, Pi/2]. */
  pattern_angle_rad?: number;
  /** Slope angle of cross hatching lines. Radians in range [Pi/2, Pi/2]. */
  cross_pattern_angle_rad?: number;
  /** Distance between hatching lines. Ground units (meters/mm/foot etc). */
  pattern_spacing?: number;
  /** Distance between cross hatching lines. Ground units (meters/mm/foot etc). */
  cross_pattern_spacing?: number;
  /** Distance between hatching lines. Px. DETECTED ON THE FE SIDE. */
  pattern_spacing_px?: number;
  /** Distance between cross hatching lines. Px. DETECTED ON THE FE SIDE. */
  cross_pattern_spacing_px?: number;
  /** Line weight, is used to recalculate line width. 0 if undefined. */
  weight?: number;
  /** Hatching lines width, px.
   *
   * DETECTED ON THE FE SIDE by formula: width = (weight + 1) * default. Default is 2px. */
  width?: number;
  /** NOT IN USE FOR NOW. */
  fill_type?: 'hatch';
  /** NOT IN USE FOR NOW. */
  snappable?: boolean;
  /** [x, y, z]. NOT IN USE FOR NOW. */
  offset?: number[];
}

interface DGNFeatureBorderStyleProperties {
  /** Line weight, is used to recalculate line width. */
  weight?: number;
  pattern?: number;
}

export interface DGNFeatureStyle {
  general?: DGNFeatureGeneralStyleProperties;
  fill?: DGNFeatureFillProperties;
  border?: DGNFeatureBorderStyleProperties;
}

/**
 * @description pattern style [1, 0] means solid line
 */
export const isSolidLine = (patternStyle: number[]) =>
  isDefined(patternStyle) && patternStyle.length === 2 && patternStyle[0] === 1 && patternStyle[1] === 0;

export const isValidPatternSize = (patternSize: CanvasSize, parrentCanvasSize: CanvasSize) =>
  patternSize?.w && patternSize?.h && patternSize.w < parrentCanvasSize.w && patternSize.h < parrentCanvasSize.h;

const convertPatternSpacingToPx = (spacing: number, meterToPixelRatio = 1, units = DistanceUnitsEnum.METER) => {
  if (!isDefined(spacing)) {
    return DEFAULT_PATTERN_SPACING_PX;
  }
  const spacingMeter = convertToMeters(spacing, units);
  return Math.max(roundTo(spacingMeter * meterToPixelRatio, 4), DEFAULT_PATTERN_SPACING_PX);
};

const replaceAlphaByDefaultInHEX8Color = (color: string, colorType: 'fill' | 'line') => {
  if (!isDefined(color)) {
    return;
  }
  const alpha = colorType === 'fill' ? DEFAULT_FILL_ALPHA : DEFAULT_LINES_ALPHA;
  return color.slice(0, -2) + alpha;
};

export function parseDGNFeatureStyle(
  style: string,
  featureId: string,
  meterToPixelRatio = 1,
  units = DistanceUnitsEnum.METER
): DGNFeatureStyle {
  // if feature doesn't have style properties at all, return null without error
  if (!DGN_FEATURE_STYLE_FLAGS.useDgnStyle || !isDefined(style)) {
    return null;
  }
  try {
    const parsedStyle: DGNFeatureStyle = JSON.parse(style.replaceAll('/', ''));

    if (isDefined(parsedStyle?.general?.color)) {
      parsedStyle.general.color = replaceAlphaByDefaultInHEX8Color(parsedStyle.general.color, 'line');
    }

    if (isDefined(parsedStyle?.fill)) {
      parsedStyle.fill = parseDGNFillProperties(parsedStyle, meterToPixelRatio, units);
    }

    return parsedStyle;
  } catch (error) {
    console.error(`Error parsing tile feature style${featureId ? ' for feature id ' + featureId : ''}:`, error);
    return null;
  }
}

function parseDGNFillProperties(
  featureStyle: DGNFeatureStyle,
  meterToPixelRatio: number,
  units: DistanceUnitsEnum
): DGNFeatureFillProperties {
  const fillProps = featureStyle?.fill;
  if (!isDefined(fillProps)) {
    return;
  }

  const fill_type_detected = detectFillType(featureStyle);

  let paramsToUpdate: DGNFeatureFillProperties = {
    color: replaceAlphaByDefaultInHEX8Color(fillProps.color, 'fill')
  };
  if ([DGNFeatureFillType.LINES, DGNFeatureFillType.CROSS_LINES].includes(fill_type_detected)) {
    paramsToUpdate = {
      ...paramsToUpdate,
      width: ((fillProps.weight ?? 0) + 1) * DEFAULT_PATTERN_LINE_WIDTH_PX,
      pattern_color: replaceAlphaByDefaultInHEX8Color(fillProps.pattern_color, 'line') ?? featureStyle.general.color,
      pattern_angle_rad: fillProps.pattern_angle ? roundTo(Cesium.Math.toRadians(fillProps.pattern_angle), 4) : 0,
      pattern_spacing_px: convertPatternSpacingToPx(fillProps.pattern_spacing, meterToPixelRatio, units)
    };
  }
  if (fill_type_detected === DGNFeatureFillType.CROSS_LINES) {
    paramsToUpdate = {
      ...paramsToUpdate,
      cross_pattern_angle_rad: fillProps.cross_pattern_angle ? roundTo(Cesium.Math.toRadians(fillProps.cross_pattern_angle), 4) : 0,
      cross_pattern_spacing_px: convertPatternSpacingToPx(fillProps.cross_pattern_spacing, meterToPixelRatio, units)
    };
  }
  return {
    ...fillProps,
    fill_type_detected,
    ...paramsToUpdate
  };
}

const detectFillType = (style: DGNFeatureStyle) => {
  if (!isDefined(style.fill)) {
    return;
  }
  const SOLID_REQUIRED_PROPS = ['color', 'pattern_style'];
  const LINES_REQUIRED_PROPS = ['pattern_angle', 'pattern_spacing'];
  const CROSS_LINES_REQUIRED_PROPS = ['cross_pattern_angle', 'cross_pattern_spacing'];

  const fillPropsKeys = new Set(Object.keys(style.fill));

  const hasSolidFillProps = SOLID_REQUIRED_PROPS.reduce((res: boolean, prop: string) => res && fillPropsKeys.has(prop), true);
  const hasLinesProps = LINES_REQUIRED_PROPS.reduce((res: boolean, prop: string) => res && fillPropsKeys.has(prop), true);
  const hasCrossLinesProps = CROSS_LINES_REQUIRED_PROPS.reduce((res: boolean, prop: string) => res && fillPropsKeys.has(prop), true);

  let type = null;
  if (hasCrossLinesProps && hasLinesProps) {
    type = DGNFeatureFillType.CROSS_LINES;
  } else if (hasLinesProps) {
    type = DGNFeatureFillType.LINES;
  } else if (hasSolidFillProps && isSolidLine(style.fill.pattern_style)) {
    type = DGNFeatureFillType.SOLID;
  }

  return type;
};
