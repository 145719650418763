import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { GetNotificationResponse } from '../../../../../generated/nms/model/getNotificationResponse';

export type Notification = GetNotificationResponse;
export import NotificationSeverity = GetNotificationResponse.SeverityEnum;

export interface NotificationsState extends EntityState<Notification> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'notifications' })
export class NotificationsStore extends EntityStore<NotificationsState> {
  constructor() {
    super();
  }
}
