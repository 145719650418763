import { Injectable } from '@angular/core';
import { Cartesian3 } from '@datumate/angular-cesium';

import { GeoUtils } from '../../../shared/utils/geo';
import { CalcService } from '../../services/calc-services';
import { TerrainSamplingService } from '../../services/terrain-sampling.service';
import { DetailedSiteService } from '../../state/detailed-site.service';
import { FIELD_MAPPING } from '../../state/detailed-site.utils';
import { MapEntity, TaskOrDesignValues } from '../../state/detailed-site-entities/detailed-site-entities.model';

@Injectable({
  providedIn: 'root'
})
export class AngleCalcService extends CalcService {
  constructor(private terrainSampling: TerrainSamplingService, private siteService: DetailedSiteService) {
    super();
  }

  async calcResults(
    entity: MapEntity,
    siteId: string,
    modelsData: { id: string; type: 'TASK' | 'DESIGN' }[]
  ): Promise<{ calcResult: TaskOrDesignValues[] }> {
    let calcResult: TaskOrDesignValues[] = entity?.calcResult ?? [];
    for (let i = 0; i < modelsData.length; i++) {
      const modelData = modelsData[i];
      const modelTerrain = await this.siteService.getTerrainProvider(siteId, modelData.id, modelData.type, entity.sourceModel);
      const detailedPositionsCartographic = await this.terrainSampling.sampleTerrain(entity.positions, modelTerrain);
      const detailedPositionsCartesian3 = detailedPositionsCartographic.map(pos =>
        Cesium.Cartesian3.fromDegrees(pos.longitude, pos.latitude, pos.height)
      );
      const distances = [];
      detailedPositionsCartographic.forEach((position, index, array) => {
        const nextPosition = array[(index + 1) % array.length];
        distances.push(GeoUtils.distance(position, nextPosition, false));
      });
      const circumcircleRadius =
        (distances[0] * distances[1] * distances[2]) /
        Math.sqrt(
          (distances[0] + distances[1] + distances[2]) *
            (distances[1] + distances[2] - distances[0]) *
            (distances[0] + distances[2] - distances[1]) *
            (distances[0] + distances[1] - distances[2])
        );

      const angles = [];
      detailedPositionsCartesian3.forEach((position, index, array) => {
        const nextPosition = array[(index + 1) % array.length];
        const nextNextPosition = array[(index + 2) % array.length];

        angles.push(this.angle3Cartesians(nextNextPosition, position, nextPosition));
      });

      calcResult = [
        ...calcResult,
        {
          id: modelData.id,
          type: modelData.type,
          values: [
            { ...FIELD_MAPPING.circumcircleRadius, value: circumcircleRadius },
            ...angles.map((angle, i) => ({
              ...FIELD_MAPPING[`angle${i + 1}`],
              value: angle
            })),
            ...distances.map((distance, i) => ({
              ...FIELD_MAPPING[`distance${i + 1}`],
              value: distance
            }))
          ]
        }
      ];
    }

    return { calcResult };
  }

  private angle3Cartesians(firstCart: Cartesian3, midCartesian: Cartesian3, thirdCart: Cartesian3) {
    const v1 = { x: firstCart.x - midCartesian.x, y: firstCart.y - midCartesian.y, z: firstCart.z - midCartesian.z };
    const v2 = { x: thirdCart.x - midCartesian.x, y: thirdCart.y - midCartesian.y, z: thirdCart.z - midCartesian.z };

    const v1mag = Math.sqrt(v1.x * v1.x + v1.y * v1.y + v1.z * v1.z);
    const v1norm = { x: v1.x / v1mag, y: v1.y / v1mag, z: v1.z / v1mag };

    const v2mag = Math.sqrt(v2.x * v2.x + v2.y * v2.y + v2.z * v2.z);
    const v2norm = { x: v2.x / v2mag, y: v2.y / v2mag, z: v2.z / v2mag };

    const res = v1norm.x * v2norm.x + v1norm.y * v2norm.y + v1norm.z * v2norm.z;
    const angle = Math.acos(res); // radians

    return Cesium.Math.toDegrees(angle);
  }
}
