import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, forkJoin, Observable, of, tap, throwError } from 'rxjs';

import { REQUIRED_ACCESS_LEVEL_HEADER } from '../../../auth/state/auth.utils';
import { ResourceLinkType } from '../../../shared/resource-links/resource-links.model';
import { ResourceLinksService } from '../../../shared/resource-links/resource-links.service';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { ReportEntity, REPORTS_PERMISSIONS, ReportType } from './detailed-site-reports.model';
import { DetailedSiteReportsStore } from './detailed-site-reports.store';

export type LogoFiles = Record<string, File>;

export class UploadLogosError implements Error {
  name = 'UploadLogosError';
  message = 'Error uploading report logos';
  originalError?: Error;

  constructor(originalError?: Error) {
    this.originalError = originalError;
  }
}

@Injectable({ providedIn: 'root' })
export class BasicReportsService {
  constructor(
    private http: HttpClient,
    private reportsStore: DetailedSiteReportsStore,
    private resourceLinksService: ResourceLinksService,
    private analyticsService: AnalyticsService
  ) {}

  public updateReportLogos(urls: { [name: string]: string }, logoFiles: LogoFiles, editMode = false): Observable<Object[] | null> {
    const imageUploadRequests: Observable<Object>[] = [];

    Object.entries(logoFiles).forEach(([name, file]) => {
      if (file) {
        imageUploadRequests.push(this.http.put(urls[name], file));
      } else if (editMode) {
        imageUploadRequests.push(this.http.delete(urls[name]));
      }
    });

    if (imageUploadRequests.length === 0) {
      return of(null);
    }

    return forkJoin(imageUploadRequests).pipe(catchError(error => throwError(() => new UploadLogosError(error))));
  }

  public deleteReport(report: ReportEntity, url: string) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: REPORTS_PERMISSIONS[report.type].delete } };

    return this.http.delete(url, options).pipe(
      tap(() => {
        this.removeReportsOfTypeFromStore([report.id], report.type);
        this.analyticsService.deleteReport(report);
      })
    );
  }

  public removeReportsOfTypeFromStore(reportIds: string[], reportType: ReportType) {
    this.reportsStore.removeReportsOfType(reportIds, reportType);
    this.resourceLinksService.removeResourcesOfType(reportIds, ResourceLinkType.REPORT);
  }
}
