/// <reference types="@angular/localize" />

import '@angular/compiler';
import 'chartjs-adapter-moment';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { akitaConfig, enableAkitaProdMode } from '@datorama/akita';
import { Chart, Interaction, registerables } from 'chart.js';
import Annotation from 'chartjs-plugin-annotation';
import { enableMapSet } from 'immer';

import ChartInterpolatePlugin from '../src/app/shared/chart-plugins/chartjs-interpolate-plugin';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

Cesium.buildModuleUrl.setBaseUrl('/assets/cesium/');
Cesium.Label.enableRightToLeftDetection = true;

Chart.register(...registerables, Annotation);
// Add interpolate interaction mode for chart.js. Allows showing interpolated values in tooltips
(Interaction.modes as any).interpolate = ChartInterpolatePlugin.Interpolate;

akitaConfig({
  resettable: true
});

if (environment.production) {
  enableProdMode();
  enableAkitaProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(err => console.error(err));

enableMapSet();
