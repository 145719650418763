/**
 * Datumate
 * API's for mile stone I
 *
 * OpenAPI spec version: 3.7.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GetTasksModelResponse } from './getTasksModelResponse';

export interface GetTasksProgressReportModelResponse {
  surfaceType?: GetTasksProgressReportModelResponse.SurfaceTypeEnum;
  tasks?: Array<GetTasksModelResponse>;
}
export namespace GetTasksProgressReportModelResponse {
  export type SurfaceTypeEnum = 'DTM' | 'DSM';
  export const SurfaceTypeEnum = {
    DTM: 'DTM' as SurfaceTypeEnum,
    DSM: 'DSM' as SurfaceTypeEnum
  };
}
