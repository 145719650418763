import { Injectable } from '@angular/core';
import { QueryConfig, QueryEntity } from '@datorama/akita';
import moment from 'moment';

import { Notification, NotificationsState, NotificationsStore } from './notifications.store';

const notificationsSort = (n1: Notification, n2: Notification) => (moment(n1.creationTime).isAfter(n2.creationTime) ? -1 : 1);

@QueryConfig({ sortBy: notificationsSort })
@Injectable({ providedIn: 'root' })
export class NotificationsQuery extends QueryEntity<NotificationsState> {
  notifications$ = this.selectAll();
  unseenCount$ = this.selectCount(entity => !entity.seen);

  constructor(protected store: NotificationsStore) {
    super(store);
  }

  getUnseenCount() {
    return this.getCount(entity => !entity.seen);
  }
}
