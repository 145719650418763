import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';

import { ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResultEnum } from './confirmation-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {
  constructor(private dialog: MatDialog) {}

  openDialog(data: ConfirmationDialogData, dialogConfig?: MatDialogConfig) {
    return this.dialog.open<ConfirmationDialogComponent, ConfirmationDialogData, ConfirmationDialogResultEnum>(
      ConfirmationDialogComponent,
      {
        autoFocus: false,
        restoreFocus: false,
        width: '450px',
        panelClass: 'confirmation-dialog-panel',
        ...dialogConfig,
        disableClose: true,
        data
      }
    );
  }
}
