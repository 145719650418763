export enum FeatureType {
  UNKNOWN = 0,
  POINT = 1,
  LINE = 2,
  POLYGON = 3
}

export interface TileFeatureProperties {
  OGR_STYLE?: string;
  feature_id?: string;
  Layer?: string;
  style?: string;
}
