import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { differenceBy } from 'lodash';
import { catchError, of, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  CreateCrossSectionProgressReportResponse,
  CreateRoadGradeCheckingReportRequest,
  CreateRoadGradeCheckingReportResponse,
  GetAllRoadGradeCheckingReportsResponse,
  GetRoadGradeCheckingReportDataResponse,
  UpdateGradeCheckingReportRequest,
  UpdateGradeCheckingReportResponse
} from '../../../../../generated/file/model/models';
import { REQUIRED_ACCESS_LEVEL_HEADER } from '../../../../auth/state/auth.utils';
import { ResourceLinkType } from '../../../../shared/resource-links/resource-links.model';
import { ResourceLinksService } from '../../../../shared/resource-links/resource-links.service';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { getServiceUrl } from '../../../../shared/utils/backend-services';
import { isDefined } from '../../../../shared/utils/general';
import { BasicReportsService, LogoFiles } from '../basic-reports.service';
import { ReportEntity, REPORTS_PERMISSIONS, ReportType, RoadGradeCheckingReport } from '../detailed-site-reports.model';
import { DetailedSiteReportsQuery } from '../detailed-site-reports.query';
import { DetailedSiteReportsStore } from '../detailed-site-reports.store';

@Injectable({ providedIn: 'root' })
export class RoadGradeCheckingReportsService {
  readonly reportType = ReportType.ROAD_GRADE_CHECKING as const;
  readonly reportPermissions = REPORTS_PERMISSIONS[this.reportType];

  constructor(
    private http: HttpClient,
    private reportsStore: DetailedSiteReportsStore,
    private reportsQuery: DetailedSiteReportsQuery,
    private resourceLinksService: ResourceLinksService,
    private analyticsService: AnalyticsService,
    private basicReportsService: BasicReportsService
  ) {}

  handleFetchReports = (response: GetAllRoadGradeCheckingReportsResponse, siteId: string) => {
    const existingReports = this.reportsQuery.getAllRoadGradeCheckingReports();
    const newReports = response?.getAllRoadGradeCheckingReportsResponse;
    if (!isDefined(newReports)) {
      // Remove all reports from store
      this.basicReportsService.removeReportsOfTypeFromStore(
        existingReports.map(report => report.id),
        this.reportType
      );
      return;
    }

    // Find removed reports and update resource links
    const removedReports = differenceBy(existingReports, newReports, 'id');
    this.resourceLinksService.removeResourcesOfType(
      removedReports.map(report => report.id),
      ResourceLinkType.REPORT
    );

    this.reportsStore.upsertRoadGradeCheckingReports(newReports.map(report => ({ ...report, type: this.reportType, siteId })));
  };

  generateReport(siteId: string, request: CreateRoadGradeCheckingReportRequest, logoFiles: LogoFiles) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: this.reportPermissions.create } };

    return this.http
      .post(`${getServiceUrl('file')}/sites/${siteId}/roadGradeCheckingReports/generateRoadGradeCheckingReport`, request, options)
      .pipe(
        tap((response: CreateRoadGradeCheckingReportResponse) => {
          if (response) {
            const report: RoadGradeCheckingReport = {
              ...request,
              type: this.reportType,
              id: response.reportId,
              siteId
            };
            this.reportsStore.upsertRoadGradeCheckingReports([report]);
            this.analyticsService.generateReport(report);
          }
        }),
        switchMap((response: CreateCrossSectionProgressReportResponse) => {
          if (response) {
            const logoUrls = {
              consultantLogo: response.consultantLogoURL,
              customerLogo: response.customerLogoURL,
              signatureLogo: response.signatureLogoURL
            };
            return this.basicReportsService.updateReportLogos(logoUrls, logoFiles);
          }

          return of(null);
        })
      );
  }

  fetchReport(siteId: string, reportId: string) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: this.reportPermissions.read } };
    return this.http
      .get<GetRoadGradeCheckingReportDataResponse>(
        `${getServiceUrl('file')}/sites/${siteId}/roadGradeCheckingReports/${reportId}/data`,
        options
      )
      .pipe(
        tap(report => {
          if (isDefined(report)) {
            this.reportsStore.upsertRoadGradeCheckingReports([{ ...report, type: this.reportType, id: reportId, siteId: siteId }]);
          }
        }),
        catchError(error => {
          console.error('Error fetching road grade checking report', error);
          return of(null);
        })
      );
  }

  updateReport(siteId: string, reportId: string, request: UpdateGradeCheckingReportRequest, logoFiles: LogoFiles) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: REPORTS_PERMISSIONS[ReportType.ROAD_GRADE_CHECKING].update } };

    return this.http.put(`${getServiceUrl('file')}/sites/${siteId}/roadGradeCheckingReports/${reportId}`, request, options).pipe(
      tap((response: UpdateGradeCheckingReportResponse) => {
        if (response) {
          if (response) {
            const report: RoadGradeCheckingReport = {
              ...request,
              id: response.reportId,
              type: ReportType.ROAD_GRADE_CHECKING
            };
            this.reportsStore.upsertRoadGradeCheckingReports([report]);
            this.analyticsService.editReport(report);
          }
        }
      }),
      switchMap((response: UpdateGradeCheckingReportResponse) => {
        if (response) {
          const logoUrls = {
            consultantLogo: logoFiles.consultantLogo ? response.putConsultantLogoURL : response.deleteConsultantLogoURL,
            customerLogo: logoFiles.customerLogo ? response.putCustomerLogoURL : response.deleteCustomerLogoURL,
            signatureLogo: logoFiles.signatureLogo ? response.putSignatureLogoURL : response.deleteSignatureLogoURL
          };
          return this.basicReportsService.updateReportLogos(logoUrls, logoFiles, true);
        }

        return of(null);
      }),
      switchMap(() => this.fetchReport(siteId, reportId))
    );
  }

  deleteReport(report: ReportEntity<ReportType.ROAD_GRADE_CHECKING>) {
    const url = `${getServiceUrl('file')}/sites/${report.siteId}/roadGradeCheckingReports/${report.id}`;
    return this.basicReportsService.deleteReport(report, url);
  }
}
