/**
 * Activity Management Service
 * Manage Activities and Project Plan (GANTT)
 *
 * OpenAPI spec version: 1.9.5
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { BaseSurface } from './baseSurface';
import { CoordinateBlock } from './coordinateBlock';

export interface GetMeasurementResponse {
  baseSurface?: BaseSurface;
  coordinates?: Array<CoordinateBlock>;
  dataUrl?: string;
  id?: string;
  measurementType?: GetMeasurementResponse.MeasurementTypeEnum;
  name?: string;
  plannedActivityMeasurementId?: string;
  sourceModelType?: GetMeasurementResponse.SourceModelTypeEnum;
  sourceType?: GetMeasurementResponse.SourceTypeEnum;
  taskId?: string;
  terrainModelId?: string;
  terrainModelType?: GetMeasurementResponse.TerrainModelTypeEnum;
  values?: { [key: string]: number };
  volumeValueType?: GetMeasurementResponse.VolumeValueTypeEnum;
}
export namespace GetMeasurementResponse {
  export type MeasurementTypeEnum = 'PLANNED' | 'ACTUAL';
  export const MeasurementTypeEnum = {
    PLANNED: 'PLANNED' as MeasurementTypeEnum,
    ACTUAL: 'ACTUAL' as MeasurementTypeEnum
  };
  export type SourceModelTypeEnum = 'DSM' | 'DTM' | 'FLAT';
  export const SourceModelTypeEnum = {
    DSM: 'DSM' as SourceModelTypeEnum,
    DTM: 'DTM' as SourceModelTypeEnum,
    FLAT: 'FLAT' as SourceModelTypeEnum
  };
  export type SourceTypeEnum = 'MANUAL' | 'DESIGN' | 'NONE';
  export const SourceTypeEnum = {
    MANUAL: 'MANUAL' as SourceTypeEnum,
    DESIGN: 'DESIGN' as SourceTypeEnum,
    NONE: 'NONE' as SourceTypeEnum
  };
  export type TerrainModelTypeEnum = 'TASK' | 'DESIGN';
  export const TerrainModelTypeEnum = {
    TASK: 'TASK' as TerrainModelTypeEnum,
    DESIGN: 'DESIGN' as TerrainModelTypeEnum
  };
  export type VolumeValueTypeEnum = 'CUT' | 'FILL';
  export const VolumeValueTypeEnum = {
    CUT: 'CUT' as VolumeValueTypeEnum,
    FILL: 'FILL' as VolumeValueTypeEnum
  };
}
