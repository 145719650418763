import Point from '@mapbox/point-geometry';
import { VectorTile, VectorTileFeature } from '@mapbox/vector-tile';
import { booleanIntersects, buffer, centerOfMass, lineString, point, polygon, radiansToLength } from '@turf/turf';
import {
  Credit,
  ImageryLayerFeatureInfo,
  ImageryProvider,
  Proxy,
  Rectangle,
  Resource,
  TextureMagnificationFilter,
  TextureMinificationFilter,
  TileDiscardPolicy,
  WebMercatorTilingScheme
} from 'cesium';
import { Feature } from 'geojson';
import Protobuf from 'pbf';

import { getHatchPattern } from '../../detailed-site/site-map/designs-layer/fill-patterns-utils';
import {
  DGNFeatureFillProperties,
  DGNFeatureFillType,
  parseDGNFeatureStyle
} from '../../detailed-site/state/detailed-site-designs/designs-dgn-utils';
import { FeatureStyle, parseOGRStyle } from '../../detailed-site/state/detailed-site-designs/designs-ogr-utils';
import { isDefined } from '../utils/general';
import { DistanceUnitsEnum } from '../utils/unit-conversion';
import { FeatureType, TileFeatureProperties } from './mapbox-vector-tiles.model';

export const DEFAULT_TILE_SIZE = 1024;

export interface VectorTilesImageryProviderOptions {
  commonProperties?: object;
  layers: string[];
  tilesResource: Resource;
  maximumLevel?: number;
  minimumLevel?: number;
  siteUnits: DistanceUnitsEnum;
  tileWidth?: number;
  tileHeight?: number;
}

export class VectorTilesImageryProvider implements ImageryProvider {
  ready = true;
  readyPromise = Promise.resolve(true);
  tilingScheme: WebMercatorTilingScheme = new Cesium.WebMercatorTilingScheme();
  rectangle = this.tilingScheme.rectangle;
  errorEvent = new Cesium.Event();
  hasAlphaChannel = true;
  defaultAlpha: number;
  defaultNightAlpha: number;
  defaultDayAlpha: number;
  defaultBrightness: number;
  defaultContrast: number;
  defaultHue: number;
  defaultSaturation: number;
  defaultGamma: number;
  defaultMinificationFilter: TextureMinificationFilter;
  defaultMagnificationFilter: TextureMagnificationFilter;
  tileDiscardPolicy: TileDiscardPolicy;
  credit: Credit;
  proxy: Proxy;

  maximumLevel: number;
  minimumLevel: number;
  tileWidth = 1024;
  tileHeight = 1024;

  commonProperties: object;
  siteUnits: DistanceUnitsEnum;
  tilesResource: Resource;
  private layers: Set<string>;

  constructor(options: VectorTilesImageryProviderOptions) {
    this.maximumLevel = options.maximumLevel ?? Infinity;
    this.minimumLevel = options.minimumLevel ?? 0;

    this.commonProperties = options.commonProperties;
    this.siteUnits = options.siteUnits;
    this.tilesResource = options.tilesResource;
    this.tileWidth = options.tileWidth ?? DEFAULT_TILE_SIZE;
    this.tileHeight = options.tileHeight ?? DEFAULT_TILE_SIZE;
    this.layers = new Set(options.layers);
  }

  // eslint-disable-next-line unused-imports/no-unused-vars
  getTileCredits(x: number, y: number, level: number): Credit[] {
    return undefined;
  }

  setLayers(layers: string[]) {
    this.layers = new Set(layers);
    (this as any)._reload();
  }

  async requestImage(x: number, y: number, level: number): Promise<HTMLCanvasElement> {
    const tileRect = this.tilingScheme.tileXYToRectangle(x, y, level);
    const tileHeightMeters = radiansToLength(tileRect.height, 'meters');
    const meterToPixelRatio = this.tileHeight / tileHeightMeters;

    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d', { willReadFrequently: true });

    canvas.height = this.tileHeight;
    canvas.width = this.tileWidth;

    // Fill canvas with transparent color
    ctx.fillStyle = 'rgba(0,0,0,0)';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    const tile = await this.fetchVectorTile(level, x, y);
    if (!isDefined(tile)) {
      return canvas;
    }

    const layers = Object.values(tile.layers).filter(layer => this.layers.has(layer.name));
    layers.forEach(layer => {
      const canvasToLayerRatio = canvas.height / layer.extent;
      const features: VectorTileFeature[] = [];
      for (let i = 0; i < layer.length; i++) {
        features.push(layer.feature(i));
      }

      if (!isDefined(features)) {
        return;
      }

      const featureProps = features.map(feature => {
        const props = feature.properties as TileFeatureProperties;
        return {
          geometry: feature.loadGeometry(),
          ogrStyle: parseOGRStyle(props?.OGR_STYLE, meterToPixelRatio * canvasToLayerRatio, this.siteUnits),
          dgnFeatureStyle: parseDGNFeatureStyle(props?.style, props?.feature_id, meterToPixelRatio, this.siteUnits),
          type: feature.type
        };
      });

      featureProps
        .sort((props1, props2) => {
          const priority1 = props1.dgnFeatureStyle?.general?.priority ?? 0;
          const priority2 = props2.dgnFeatureStyle?.general?.priority ?? 0;
          return priority1 - priority2;
        })
        .forEach(props => {
          ctx.save();
          // Don't use canvasToLayerRatio for font sizes
          if (props.ogrStyle.type === 'LABEL') {
            props.ogrStyle.fontSize /= canvasToLayerRatio;
          }

          const dgnFeatureFillProps = props?.dgnFeatureStyle?.fill;

          this.setContextStyle(ctx, props.ogrStyle, dgnFeatureFillProps, { w: canvas.width, h: canvas.height });

          if (props.ogrStyle.type === 'LABEL') {
            const point = props.geometry[0][0];
            let textX = point.x * canvasToLayerRatio;
            let textY = point.y * canvasToLayerRatio;
            const textWidth = ctx.measureText(props.ogrStyle.text).width;

            if (props.ogrStyle.textOffset) {
              ctx.translate(props.ogrStyle.textOffset.x, props.ogrStyle.textOffset.y);
            }

            if (props.ogrStyle.angle) {
              ctx.translate(textX, textY);
              textX = 0;
              textY = 0;
              ctx.rotate(-props.ogrStyle.angle * (Math.PI / 180));
            }

            let maxWidth = textWidth;
            if (props.ogrStyle.textStretch < 1) {
              maxWidth = textWidth * props.ogrStyle.textStretch;
            } else if (props.ogrStyle.textStretch > 1) {
              ctx.scale(props.ogrStyle.textStretch, 1);
            }

            if (props.ogrStyle.backgroundColor) {
              const oldFillStyle = ctx.fillStyle;
              ctx.fillStyle = props.ogrStyle.backgroundColor;
              const padding = 20;

              const bgChar = String.fromCharCode(9608); // Space char
              const bgWidth = ctx.measureText(bgChar).width;
              const repeatTimes = Math.ceil(maxWidth / bgWidth) + 1;
              const bgX = props.ogrStyle.textAnchor?.horizontal === 'center' ? textX : textX - padding / 2;
              ctx.fillText(bgChar.repeat(repeatTimes), bgX, textY, maxWidth + padding / 2);

              ctx.fillStyle = oldFillStyle;
            }

            ctx.strokeText(props.ogrStyle.text, textX, textY, maxWidth);
            ctx.fillText(props.ogrStyle.text, textX, textY, maxWidth);
          } else {
            ctx.beginPath();

            props.geometry.forEach((points: Point[]) => {
              if (props.type === FeatureType.POINT) {
                const p = points[0];
                ctx.arc(p.x * canvasToLayerRatio, p.y * canvasToLayerRatio, props.ogrStyle.width || 2, 0, 2 * Math.PI, false);
              } else {
                for (let k = 0; k < points.length; k++) {
                  const p = points[k];
                  if (k) {
                    ctx.lineTo(p.x * canvasToLayerRatio, p.y * canvasToLayerRatio);
                  } else {
                    ctx.moveTo(p.x * canvasToLayerRatio, p.y * canvasToLayerRatio);
                  }
                }
              }
            });

            if (props.type === FeatureType.POLYGON || props.type === FeatureType.POINT) {
              ctx.fill();
            }

            if (props.type === FeatureType.LINE && dgnFeatureFillProps) {
              ctx.fill();
            }

            ctx.stroke();
          }

          ctx.restore();
        });
    });
    return canvas;
  }

  private async fetchVectorTile(level: number, x: number, y: number) {
    try {
      const tileResource = this.tilesResource.getDerivedResource({ templateValues: { level, x, y } });

      const tileBuffer = await tileResource.fetchArrayBuffer();
      if (!tileBuffer.byteLength) {
        // Empty tile
        return;
      }

      const protoBuffer = new Protobuf(tileBuffer);
      return new VectorTile(protoBuffer);
    } catch (error) {
      console.error('Error fetching tile', `${level}-${x}-${y}`, error);
      return null;
    }
  }

  private setContextStyle(
    ctx: CanvasRenderingContext2D,
    style: FeatureStyle,
    fillProps: DGNFeatureFillProperties,
    canvasSize: { w: number; h: number }
  ) {
    if (style.type === 'LABEL') {
      // Texts
      const fontSize = style.fontSize ? Math.max(style.fontSize, 1) : 12;
      ctx.font = `${fontSize}px ${style.font || 'sans-serif'}`;
      ctx.fillStyle = style.fillColor;
      ctx.lineWidth = 2;
      ctx.strokeStyle = style.outlineColor || 'transparent';
      if (style.textAnchor) {
        ctx.textAlign = style.textAnchor.horizontal;
        ctx.textBaseline = style.textAnchor.vertical;
      }
    } else if (style.type === 'SYMBOL') {
      // Symbols - currently unsupported
    } else {
      // Polygons
      if (style.type.includes('BRUSH') && !isDefined(fillProps)) {
        ctx.fillStyle = style.fillColor;
        ctx.globalAlpha = 0.3;
      }
      // Polylines
      if (style.type.includes('PEN')) {
        // Don't allow line width smaller than 1 px
        ctx.lineWidth = style.width ? Math.max(style.width, 1) : 2;
        ctx.strokeStyle = style.outlineColor || 'black';

        if (style.pattern) {
          // Don't allow line patterns smaller than 1 px
          ctx.setLineDash(style.pattern.map(val => Math.max(val, 1)));
        }

        // Closed filled polylines (= polygons), generated from DGN format.
        // Has both ogrStyle (PEN + BRUSH) data and style data.
        // Filling properties are taken from style data, border properties - from OGR.
        if (fillProps) {
          if (fillProps.fill_type_detected === DGNFeatureFillType.SOLID) {
            ctx.fillStyle = fillProps.color;
          } else if ([DGNFeatureFillType.LINES, DGNFeatureFillType.CROSS_LINES].includes(fillProps.fill_type_detected)) {
            const patternCanvas = getHatchPattern(fillProps, canvasSize);
            ctx.fillStyle = ctx.createPattern(patternCanvas, 'repeat');
          }
        }
      }
    }
  }

  async pickFeatures(x: number, y: number, level: number, longitude: number, latitude: number): Promise<ImageryLayerFeatureInfo[]> {
    const tile = await this.fetchVectorTile(level, x, y);
    if (!tile?.layers || Object.values(tile.layers).length === 0) {
      return [];
    }

    // Make click buffer as 1% of tile width/height
    const tileRect = this.tilingScheme.tileXYToRectangle(x, y, level);
    const clickBufferMeters = radiansToLength(Math.min(tileRect.width, tileRect.height), 'meters') * 0.01;

    const pickPoint = point([Cesium.Math.toDegrees(longitude), Cesium.Math.toDegrees(latitude)]);
    const bufferedPoint = buffer(pickPoint, clickBufferMeters, { units: 'meters' });

    const pickedFeatures: ImageryLayerFeatureInfo[] = [];
    const layers = Object.values(tile.layers).filter(layer => this.layers.has(layer.name));
    layers.forEach(layer => {
      for (let i = 0; i < layer.length; i++) {
        const feature = this.convertVTFeatureToFeature(tileRect, layer.feature(i));
        if (feature && this.isFeatureClicked(feature, bufferedPoint)) {
          pickedFeatures.push({
            name: feature.properties.Layer,
            data: feature,
            position: this.getFeatureCenterPosition(feature)
          } as ImageryLayerFeatureInfo);
        }
      }
    });

    return pickedFeatures;
  }

  private convertVTFeatureToFeature(tileRect: Rectangle, feature: VectorTileFeature) {
    try {
      switch (feature.type) {
        case FeatureType.POINT: {
          const positions = this.convertGeometry(tileRect, feature.extent, feature.loadGeometry());
          return point(positions[0][0], { ...feature.properties, ...this.commonProperties });
        }

        case FeatureType.LINE: {
          const positions = this.convertGeometry(tileRect, feature.extent, feature.loadGeometry());
          return lineString(positions[0], { ...feature.properties, ...this.commonProperties });
        }

        case FeatureType.POLYGON: {
          const positions = this.convertGeometry(tileRect, feature.extent, feature.loadGeometry());
          const validPositions = positions.filter((linearRing: [number, number][]) => linearRing.length >= 4);
          if (validPositions.length > 0) {
            return polygon(validPositions, { ...feature.properties, ...this.commonProperties });
          }
        }
      }
    } catch (e) {
      console.error('Could not convert vector tile feature to geojson feature', feature, e);
      return null;
    }
  }

  private convertGeometry(tileRect: Rectangle, extent: number, positions: Point | Point[] | Point[][]) {
    if (!Array.isArray(positions)) {
      const position = positions as Point;
      return [
        Cesium.Math.toDegrees(Cesium.Math.lerp(tileRect.west, tileRect.east, position.x / extent)),
        Cesium.Math.toDegrees(Cesium.Math.lerp(tileRect.north, tileRect.south, position.y / extent))
      ];
    } else {
      return (positions as Point[] | Point[][]).map((p: Point | Point[]) => this.convertGeometry(tileRect, extent, p));
    }
  }

  private isFeatureClicked(feature: Feature, bufferedPoint: Feature) {
    // Turfjs contains booleanIntersects but doesn't export it in it's types
    return booleanIntersects(feature, bufferedPoint);
  }

  private getFeatureCenterPosition(feature: Feature) {
    const center = centerOfMass(feature);
    return {
      longitude: center.geometry.coordinates[0],
      latitude: center.geometry.coordinates[1],
      height: center.geometry.coordinates[2]
    };
  }
}
