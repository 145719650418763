import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import { map } from 'rxjs';

import { generateResourceLinksEntityId, ResourceLinkType } from './resource-links.model';
import { ResourceLinksState, ResourceLinksStore } from './resource-links.store';

@Injectable({ providedIn: 'root' })
export class ResourceLinksQuery extends QueryEntity<ResourceLinksState> {
  constructor(protected store: ResourceLinksStore) {
    super(store);
  }

  selectResourceLinks(id: string, type: ResourceLinkType) {
    return this.selectEntity(generateResourceLinksEntityId(id, type)).pipe(
      map(entity => entity?.links?.filter(entity => !entity.markedForDelete) ?? [])
    );
  }

  getResourceLinks(id: string, type: ResourceLinkType) {
    return this.getEntity(generateResourceLinksEntityId(id, type))?.links?.filter(entity => !entity.markedForDelete) ?? [];
  }

  isResourceLinksDirty(id: string, type: ResourceLinkType) {
    const resourceLinkEntity = this.getEntity(generateResourceLinksEntityId(id, type));
    return !!resourceLinkEntity?.isDirty;
  }
}
