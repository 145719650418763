import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, Store, StoreConfig } from '@datorama/akita';

import { LoginResponse } from '../../../generated/ums/model/models';
import { isDefined } from '../../shared/utils/general';
import { AccessLevelEnum, getUserAccessLevel, getUserRoleByAccessLevel, LogInStateEnum, UserRole } from './auth.utils';

export type UserSession = LoginResponse[];
export const USER_STORAGE_KEY = '@user';
export const ACTIVE_TENANT_STORAGE_KEY = '@tenant';

export interface AuthState {
  loggedIn: boolean;
  userAccessLevel: AccessLevelEnum;
  userRole: UserRole;
}

export function getUserSessionFromLocalStorage(): UserSession {
  const userSessionString = localStorage.getItem(USER_STORAGE_KEY);
  if (!userSessionString) {
    return null;
  }

  const parsedUserSession = JSON.parse(userSessionString);

  let userSession: UserSession;
  if (Array.isArray(parsedUserSession)) {
    userSession = parsedUserSession;
  } else {
    userSession = [parsedUserSession];
  }

  return userSession;
}

function createInitialState(): AuthState {
  return {
    loggedIn: false,
    userAccessLevel: null,
    userRole: null
  };
}

export interface LoginResponseState extends EntityState<LoginResponse>, ActiveState {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'auth' })
export class AuthStore extends Store<AuthState> {
  loginResponses = new EntityStore<LoginResponseState>({}, { name: 'login-list-response', idKey: 'tenantId' });

  constructor() {
    super(createInitialState());

    this.initStateFromLocalStorage();
  }

  private initStateFromLocalStorage() {
    const userSession = getUserSessionFromLocalStorage();
    this.initStateFromUserSession(userSession);
  }

  initStateFromUserSession(userSession: UserSession) {
    if (!isDefined(userSession)) {
      return;
    }

    const accessTokens = userSession
      ?.filter(session => !!session.accessToken && session.logInState === LogInStateEnum.SUCCESS)
      .map(session => session.accessToken);

    if (!isDefined(accessTokens)) {
      return;
    }

    const allUserRoles = userSession.map(session => getUserRoleByAccessLevel(session.accessLevel));
    const userRole = allUserRoles.some(role => role === UserRole.OPERATOR) ? UserRole.OPERATOR : UserRole.ACCOUNT_USER;

    this.update({
      loggedIn: true,
      userRole,
      userAccessLevel: userRole === UserRole.OPERATOR ? AccessLevelEnum.OPERATORUSER : null
    });

    this.loginResponses.set(userSession);
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(userSession));

    // Set active tenant if account user
    if (userRole === UserRole.ACCOUNT_USER) {
      let activeTenantId = userSession.find(loginResponse => loginResponse.logInState === LogInStateEnum.SUCCESS)?.tenantId;
      const storageTenantId = localStorage.getItem(ACTIVE_TENANT_STORAGE_KEY);
      if (storageTenantId) {
        activeTenantId = storageTenantId;
      }

      this.setActiveTenant(activeTenantId);
    }
  }

  updateStateFromUserSession(userSession: UserSession) {
    const accessTokens = userSession
      ?.filter(session => !!session.accessToken && session.logInState === LogInStateEnum.SUCCESS)
      .map(session => session.accessToken);

    if (!isDefined(accessTokens)) {
      this.update(createInitialState());
      return;
    }

    this.loginResponses.upsertMany(userSession);
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(userSession));

    // Update account user access level in case it was changed
    if (this.getValue().userRole === UserRole.ACCOUNT_USER) {
      const activeTenantId = localStorage.getItem(ACTIVE_TENANT_STORAGE_KEY);
      const activeTenant = this.loginResponses.getValue().entities[activeTenantId];
      const userAccessLevel = getUserAccessLevel(activeTenant.accessToken);
      this.update({ userAccessLevel });
    }
  }

  setActiveTenant(id: string) {
    if (id) {
      this.loginResponses.setActive(id);
      localStorage.setItem(ACTIVE_TENANT_STORAGE_KEY, id);
    } else {
      this.loginResponses.setActive(null);
      localStorage.removeItem(ACTIVE_TENANT_STORAGE_KEY);
    }
  }

  resetStore() {
    this.reset();
    this.loginResponses.reset();
  }
}
