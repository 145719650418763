import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './auth/auth.guard';
import { HomePageRedirectGuard } from './auth/home-page-redirect.guard';
import { LoginPageGuard } from './auth/login-page.guard';
import { LoginPageComponent } from './auth/login-page/login-page.component';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { VersionPageComponent } from './shared/version-page/version-page.component';

const appRoutes: Routes = [
  { path: 'login', component: LoginPageComponent, canActivate: [LoginPageGuard] },
  { path: 'changePassword/:userData', component: LoginPageComponent },
  { path: 'setNewUserPassword/:userData', component: LoginPageComponent, data: { newUser: true } },
  { path: 'version', component: VersionPageComponent },
  { path: 'flights', loadChildren: () => import('./operator/operator.module').then(m => m.OperatorModule) },
  { path: ':tenantId/sites/:siteId/reports', loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule) },
  { path: ':tenantId/sites/:siteId', loadChildren: () => import('./detailed-site/detailed-site.module').then(m => m.DetailedSiteModule) },
  { path: ':tenantId/sites', loadChildren: () => import('./all-sites/all-sites.module').then(m => m.AllSitesModule) },
  { path: 'sites', redirectTo: '/' }, // for backwards compatibility, redirect old paths to front page

  { path: '', pathMatch: 'full', canActivate: [AuthGuard, HomePageRedirectGuard], children: [] },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(
      appRoutes
      // { enableTracing: true }
    )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
