import { Injectable } from '@angular/core';
import { Cartesian3, MapsManagerService } from '@datumate/angular-cesium';

import { isDefined } from '../../shared/utils/general';
import { Cartographic, GeoUtils } from '../../shared/utils/geo';
import { TerrainProviderService } from './terrain-provider.service';
import { TerrainSamplingService } from './terrain-sampling.service';

const POINT_HEIGHT_ZOOM = 100;
const ENTITY_PADDING_DEGREES = 0.0001;

@Injectable({
  providedIn: 'root'
})
export class SiteMapService {
  private mapId = 'detailed-site';

  constructor(
    private mapsManager: MapsManagerService,
    private terrainSampling: TerrainSamplingService,
    private terrainProviderService: TerrainProviderService
  ) {}

  async zoomInto(positions: Cartesian3[], immediate = false) {
    if (!isDefined(positions)) {
      return;
    }

    const cameraService = this.mapsManager.getMap(this.mapId).getCameraService();
    const terrain = await this.terrainProviderService.getCurrentTaskTerrain();

    if (terrain) {
      try {
        const sampledPositions = await this.terrainSampling.sampleTerrain(positions, terrain, false);
        const positionsDeg = sampledPositions.map((p: Cartographic) => [p.longitude, p.latitude, p.height]);
        if (positionsDeg.length === 1) {
          const [longitude, latitude, altitude] = positionsDeg[0];
          const destination = Cesium.Cartesian3.fromDegrees(
            longitude,
            latitude,
            altitude !== undefined ? altitude + 10 : POINT_HEIGHT_ZOOM
          );
          cameraService.cameraFlyTo({
            duration: immediate ? 0 : undefined,
            destination
          });
        } else {
          const bbox = GeoUtils.boundingBox(positionsDeg, ENTITY_PADDING_DEGREES);

          // Fly to bounding sphere instead of to rectangle to avoid a Cesium issue with flyTo moving camera below ground in height elevation terrains
          const elevations = positionsDeg.map(p => p[2]);
          const boundingSphere = Cesium.BoundingSphere.fromOrientedBoundingBox(
            Cesium.OrientedBoundingBox.fromRectangle(bbox, Math.min(...elevations), Math.max(...elevations))
          );

          cameraService.getCamera().flyToBoundingSphere(boundingSphere, {
            duration: immediate ? 0 : undefined,
            offset: new Cesium.HeadingPitchRange(Cesium.Math.toRadians(0), Cesium.Math.toRadians(-90.0), 0)
          });
        }

        return sampledPositions;
      } catch (error) {
        console.error('Error calculating detailed positions to zoom into, using given positions instead', error);
      }
    }

    const cartogarpicPositions = positions.map((p: Cartesian3) => GeoUtils.cartesian3ToDeg(p));
    if (cartogarpicPositions.length === 1) {
      const { longitude, latitude, height } = cartogarpicPositions[0];
      cameraService.cameraFlyTo({
        destination: Cesium.Cartesian3.fromDegrees(longitude, latitude, height !== undefined ? height + 10 : 100)
      });
    } else {
      const points = cartogarpicPositions.map(p => [p.longitude, p.latitude]);
      const bbox = GeoUtils.boundingBox(points, 0.0001);
      cameraService.cameraFlyTo({
        destination: bbox
      });
    }

    return cartogarpicPositions;
  }
}
