import { Injectable } from '@angular/core';
import { MapsManagerService } from '@datumate/angular-cesium';
import { featureCollection } from '@turf/turf';
import { ColorMaterialProperty, EntityCollection, Viewer } from 'cesium';
import { Feature, Geometry, GeometryCollection } from 'geojson';

import { isDefined } from '../../../shared/utils/general';
import { DESIGN_ENTITY_ID_PREFIX } from '../detailed-site.utils';
import { FeatureStyle, parseOGRStyle } from './designs-ogr-utils';

const DEFAULT_POLYGON_FILL_ALPHA = 0.3;

const toCesiumColor = (cssColor: string) => {
  let color = Cesium.Color.BLACK;
  if (cssColor) {
    if (cssColor.length <= 7) {
      // Normal color string
      color = Cesium.Color.fromCssColorString(cssColor);
    } else if (cssColor.length <= 9) {
      // Color string with alpha
      const alpha = parseInt(cssColor.slice(7), 16) / 255;
      color = Cesium.Color.fromCssColorString(cssColor.slice(0, 7)).withAlpha(alpha);
    }
  }
  return color;
};

function reduceOffset(coordinates, offset: number) {
  if (!coordinates || coordinates.length === 0) {
    return coordinates;
  }

  if (typeof coordinates[0] === 'number') {
    if (coordinates.length === 3) {
      return [coordinates[0], coordinates[1], coordinates[2] - offset];
    } else {
      return coordinates;
    }
  } else {
    return coordinates.map(c => reduceOffset(c, offset));
  }
}

@Injectable({
  providedIn: 'root'
})
export class DesignsGeoJsonService {
  constructor(private mapsManager: MapsManagerService) {}

  loadDesignGeoJson(
    features: Feature<Exclude<Geometry, GeometryCollection>>[],
    offset: number,
    mapId: string,
    initialShow = true
  ): Promise<EntityCollection> {
    return new Promise(async (resolve, reject) => {
      const viewer: Viewer = this.mapsManager.getMap(mapId).getCesiumViewer();

      // Reduce terrain offset from design features with height
      if (offset) {
        features = features.map(feature => {
          if (isDefined(feature.geometry?.coordinates)) {
            const coordinates = reduceOffset(feature.geometry.coordinates, offset);
            return {
              ...feature,
              geometry: {
                ...feature.geometry,
                coordinates
              }
            };
          }

          return feature;
        });
      }

      try {
        const dataSource = await viewer.dataSources.add(Cesium.GeoJsonDataSource.load(featureCollection(features)));
        const entityCollection = dataSource.entities;
        for (const entity of entityCollection.values) {
          entity.show = initialShow;
          if (entity.billboard) {
            this.setPointStyle(entity);
          } else if (entity.polygon) {
            this.setPolygonStyle(entity);
          } else if (entity.polyline) {
            this.setPolylineStyle(entity);
          }

          // For later pick()
          (entity as any).acEntity = { id: `${DESIGN_ENTITY_ID_PREFIX}${entity.id}`, ...entity.properties.getValue(null) };
        }

        resolve(entityCollection);
      } catch (e) {
        reject(e);
      }
    });
  }

  setEntityCollectionAlpha(entityCollection: EntityCollection, alpha = 100) {
    entityCollection.values.forEach(entity => {
      if (entity.polygon) {
        const material = entity.polygon.material as ColorMaterialProperty;
        material.color = material.color.getValue(null)?.withAlpha(alpha * DEFAULT_POLYGON_FILL_ALPHA);
        entity.polygon.outlineColor = entity.polygon.outlineColor.getValue(null)?.withAlpha(alpha);
      } else if (entity.polyline) {
        const material = entity.polyline.material as ColorMaterialProperty;
        material.color = material.color.getValue(null)?.withAlpha(alpha);
      } else if (entity.point) {
        entity.point.color = entity.point.color.getValue(null)?.withAlpha(alpha);
      } else if (entity.label) {
        entity.label.fillColor = entity.label.fillColor?.getValue(null)?.withAlpha(alpha);
        entity.label.outlineColor = entity.label.outlineColor?.getValue(null)?.withAlpha(alpha);
        entity.label.backgroundColor = entity.label.backgroundColor?.getValue(null)?.withAlpha(alpha);
      } else if (entity.billboard) {
        entity.billboard.color = entity.billboard.color.getValue(null)?.withAlpha(alpha);
      }
    });
  }

  private setPointStyle(entity) {
    entity.billboard = undefined;
    const style = parseOGRStyle(entity.properties.OGR_STYLE.getValue());
    if (style.type === 'LABEL') {
      entity.label = new Cesium.LabelGraphics({
        text: style.text,
        font: `${style.fontSize || 12}px ${style.font || 'sans-serif'}`,
        fillColor: toCesiumColor(style.fillColor),
        outlineColor: toCesiumColor(style.outlineColor),
        distanceDisplayCondition: new Cesium.DistanceDisplayCondition(0.0, 700),
        pixelOffset: style.textOffset ? new Cesium.Cartesian2(style.textOffset.x, style.textOffset.y) : undefined,
        style: Cesium.LabelStyle.FILL_AND_OUTLINE,
        showBackground: !!style.backgroundColor,
        backgroundColor: toCesiumColor(style.backgroundColor),
        backgroundPadding: style.backgroundColor ? new Cesium.Cartesian2(5, 2) : undefined
      });
    } else if (style.type === 'PEN') {
      // If point has PEN the draw a Point
      entity.point = new Cesium.PointGraphics({
        color: toCesiumColor(style.outlineColor),
        pixelSize: style.width || 2
      });
    } else {
      console.warn('DXF - Got unsupported point type ' + style.type);
    }
  }

  private setPolygonStyle(entity) {
    const style = parseOGRStyle(entity.properties.OGR_STYLE.getValue());
    if (style.type === 'BRUSH') {
      entity.polygon.material = toCesiumColor(style.fillColor) || entity.polygon.material;
      entity.polygon.outline = false;
    } else if (style.type === 'BRUSH;PEN' || style.type === 'PEN;BRUSH') {
      entity.polygon.material = toCesiumColor(style.fillColor) || entity.polygon.material;
      entity.polygon.outline = true;
      entity.polygon.outlineColor = toCesiumColor(style.outlineColor) || entity.polygon.outlineColor;
    } else if (style.type === 'PEN') {
      const positions = entity.polygon.hierarchy.getValue(null).positions;
      entity.polygon = undefined;
      entity.polyline = new Cesium.PolylineGraphics({
        positions,
        material: this.getLineMaterial(style) || entity.polyline.material,
        width: 2
      });
    } else {
      console.warn('DXF - Got unsupported polygon type ' + style.type);
    }
  }

  private setPolylineStyle(entity) {
    const style = parseOGRStyle(entity.properties.OGR_STYLE.getValue());
    if (style.type === 'PEN') {
      entity.polyline.material = this.getLineMaterial(style) || entity.polyline.material;
      entity.polyline.width = style.width || 1;
    } else if (style.type === 'BRUSH') {
      const positions = entity.polyline.positions.getValue(null);
      entity.polyline = undefined;
      entity.polygon = new Cesium.PolylineGraphics({
        hierarchy: positions,
        material: toCesiumColor(style.fillColor)
      });
    } else {
      console.warn('DXF - Got unsupported line type ' + style.type);
    }
  }

  private getLineMaterial(style: FeatureStyle) {
    if (style.pattern) {
      return new Cesium.PolylineDashMaterialProperty({
        color: toCesiumColor(style.outlineColor)
      });
    } else {
      return toCesiumColor(style.outlineColor);
    }
  }
}
