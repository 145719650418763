import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, of, tap } from 'rxjs';

import { GetAllReportsResponse } from '../../../../generated/file/model/models';
import { AuthQuery } from '../../../auth/state/auth.query';
import { minAccessLevel, REQUIRED_ACCESS_LEVEL_HEADER } from '../../../auth/state/auth.utils';
import { AnalyticsService } from '../../../shared/services/analytics.service';
import { ApiPollingService } from '../../../shared/services/api-polling.service';
import { getServiceUrl } from '../../../shared/utils/backend-services';
import { assertNever } from '../../../shared/utils/general';
import { REPORT_TYPE_NAMES, ReportEntity, REPORTS_PERMISSIONS, ReportType } from './detailed-site-reports.model';
import { DetailedSiteReportsStore } from './detailed-site-reports.store';
import { CrossSectionProgressReportsService } from './reports/cross-section-progress-reports.service';
import { CrossSectionVolumeReportsService } from './reports/cross-section-volume-reports.service';
import { GridHeatmapReportsService } from './reports/grid-heatmap-reports.service';
import { RoadGradeCheckingReportsService } from './reports/road-grade-checking-reports.service';
import { SurfaceGradeCheckingReportsService } from './reports/surface-grade-checking-reports.service';
import { WaterflowReportsService } from './reports/waterflow-reports.service';

@Injectable({ providedIn: 'root' })
export class DetailedSiteReportsService {
  private reportServiceMap = {
    [ReportType.CROSS_SECTION_PROGRESS]: this.crossSectionProgressReportsService,
    [ReportType.CROSS_SECTION_VOLUME]: this.crossSectionVolumeReportsService,
    [ReportType.ROAD_GRADE_CHECKING]: this.roadGradeCheckingReportsService,
    [ReportType.VOLUME_GRID_HEATMAP]: this.gridHeatmapReportsService,
    [ReportType.ELEVATION_GRID_HEATMAP]: this.gridHeatmapReportsService,
    [ReportType.SURFACE_GRADE_CHECKING]: this.surfaceGradeCheckingReportsService,
    [ReportType.WATER_FLOW]: this.waterflowReportsService
  } as const;

  reportsAccessRead = minAccessLevel(...Object.values(REPORTS_PERMISSIONS).map(permission => permission.read));

  constructor(
    private reportsStore: DetailedSiteReportsStore,
    private authQuery: AuthQuery,
    private analyticsService: AnalyticsService,
    private crossSectionProgressReportsService: CrossSectionProgressReportsService,
    private crossSectionVolumeReportsService: CrossSectionVolumeReportsService,
    private roadGradeCheckingReportsService: RoadGradeCheckingReportsService,
    private gridHeatmapReportsService: GridHeatmapReportsService,
    private surfaceGradeCheckingReportsService: SurfaceGradeCheckingReportsService,
    private waterflowReportsService: WaterflowReportsService,
    private http: HttpClient,
    private apiPoller: ApiPollingService
  ) {}

  getReportServiceByType<T extends ReportType>(reportType: T) {
    return this.reportServiceMap[reportType];
  }

  handleFetchReports = (response: GetAllReportsResponse, siteId: string) => {
    const keys = Object.keys(response) as (keyof GetAllReportsResponse)[];

    keys.forEach(key => {
      switch (key) {
        case 'crossSectionProgressReports':
          this.getReportServiceByType(ReportType.CROSS_SECTION_PROGRESS).handleFetchReports(response[key], siteId);
          break;
        case 'crossSectionVolumeReports':
          this.getReportServiceByType(ReportType.CROSS_SECTION_VOLUME).handleFetchReports(response[key], siteId);
          break;
        case 'roadGradeCheckingReports':
          this.getReportServiceByType(ReportType.ROAD_GRADE_CHECKING).handleFetchReports(response[key], siteId);
          break;
        case 'volumeGridReports':
          this.getReportServiceByType(ReportType.VOLUME_GRID_HEATMAP).handleFetchReports(response[key], siteId);
          break;
        case 'surfaceGradeCheckingReports':
          this.getReportServiceByType(ReportType.SURFACE_GRADE_CHECKING).handleFetchReports(response[key], siteId);
          break;
        case 'waterFlowReports':
          this.getReportServiceByType(ReportType.WATER_FLOW).handleFetchReports(response[key], siteId);
          break;
        default:
          assertNever(key);
      }
    });
  };

  private catchFetchSiteReportsError = (error: any) => {
    console.error('Error fetching site reports', error);
    return of(null);
  };

  fetchSiteReports(siteId: string) {
    if (!this.authQuery.hasAccessLevel(this.reportsAccessRead)) {
      return of(null);
    }

    this.reportsStore.setLoading(true);
    return this.http
      .get<GetAllReportsResponse>(`${getServiceUrl('file')}/generalReports/sites/${siteId}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: this.reportsAccessRead }
      })
      .pipe(
        tap(response => {
          this.handleFetchReports(response, siteId);
          this.reportsStore.setLoading(false);
        }),
        catchError(this.catchFetchSiteReportsError)
      );
  }

  startReportsPolling(siteId: string, pollingFreq?: number) {
    if (!this.authQuery.hasAccessLevel(this.reportsAccessRead)) {
      return of(null);
    }

    return this.apiPoller
      .poll<GetAllReportsResponse>(`${getServiceUrl('file')}/generalReports/sites/${siteId}`, this.reportsAccessRead, pollingFreq, true)
      .pipe(
        tap(response => this.handleFetchReports(response, siteId)),
        catchError(this.catchFetchSiteReportsError)
      );
  }

  deleteReport(report: ReportEntity) {
    return this.getReportServiceByType(report.type).deleteReport(report as any);
  }

  generateReportURL(report: ReportEntity) {
    const activeTenantId = this.authQuery.getActiveTenantId();
    const reportType = REPORT_TYPE_NAMES[report.type];

    return `/${activeTenantId}/sites/${report.siteId}/reports/${reportType}/${report.id}`;
  }

  openReport(report: ReportEntity) {
    this.analyticsService.showReport(report);

    const url = this.generateReportURL(report);
    window.open(url, '_blank');
  }

  resetStore() {
    this.reportsStore.resetStore();
  }
}
