import { Injectable } from '@angular/core';
import { EntityDirtyCheckPlugin, Query, QueryEntity } from '@datorama/akita';
import { AcNotification, ActionType } from '@datumate/angular-cesium';
import moment from 'moment';
import { combineLatest, filter, map, merge, Observable } from 'rxjs';

import { isDefined, sortByNumericStringField, sortNumericStrings } from '../../../shared/utils/general';
import { DetailedSiteQuery } from '../detailed-site.query';
import { isNewEntity } from '../detailed-site.utils';
import {
  Activity,
  ActivityEntityType,
  ActivityGeometricFilterType,
  ActivityMeasurement,
  ActivityMeasurementModelSourceType,
  ActivitySorting,
  ActivitySortingType,
  ActivityType,
  ProjectPlan
} from './detailed-site-activities.model';
import { DetailedSiteActivitiesState, DetailedSiteActivitiesStore } from './detailed-site-activities.store';
import { isActual, isCount, isPlanned } from './detailed-site-activities-utils.service';

export const sortActivitiesFunc = (sorting: ActivitySorting) => (a1: Activity, a2: Activity) => {
  switch (sorting.type) {
    case ActivitySortingType.PLANNED_START_DATE: {
      if (sorting.isDesc) {
        return moment(a1.startDate).isAfter(a2.startDate) ? -1 : 1;
      } else {
        return moment(a1.startDate).isAfter(a2.startDate) ? 1 : -1;
      }
    }
    case ActivitySortingType.PLANNED_END_DATE:
      if (sorting.isDesc) {
        return moment(a1.endDate).isAfter(a2.endDate) ? -1 : 1;
      } else {
        return moment(a1.endDate).isAfter(a2.endDate) ? 1 : -1;
      }
    case ActivitySortingType.NAME: {
      return sortNumericStrings(a1.name, a2.name, sorting.isDesc);
    }
    case ActivitySortingType.ID:
    default: {
      return activitiesDefaultSort(a1, a2, sorting.isDesc);
    }
  }
};

export const activitiesDefaultSort = (a1: Activity, a2: Activity, isDesc?: boolean) => {
  if (a1.tracked !== a2.tracked) {
    return (a1.tracked ? -1 : 1) * (isDesc ? -1 : 1);
  }

  if (a1.type === a2.type) {
    if (a1.type === ActivityEntityType.ACTIVITY) {
      return sortNumericStrings(a1.sourceActivityUniqueId, a2.sourceActivityUniqueId, isDesc);
    } else {
      return sortNumericStrings(a1.name, a2.name, isDesc);
    }
  } else {
    return (a1.type === ActivityEntityType.STANDALONE ? -1 : 1) * (isDesc ? -1 : 1);
  }
};

export const sortProjectPlanFunc = sortByNumericStringField<ProjectPlan>('version');

const activityDirtyCheckComparator = (head: Activity, current: Activity) => {
  return (
    current.description !== head.description ||
    current.name !== head.name ||
    current.complete !== head.complete ||
    current.activityType !== head.activityType ||
    current.amount !== head.amount ||
    current.userActualStartDate !== head.userActualStartDate ||
    current.userActualEndDate !== head.userActualEndDate ||
    current.forecastEndDate !== head.forecastEndDate
  );
};

const activityMeasurementDirtyCheckComparator = (head: ActivityMeasurement, current: ActivityMeasurement) => {
  return head.markedForDelete !== current.markedForDelete || head.markedForSave !== current.markedForSave;
};

@Injectable({ providedIn: 'root' })
export class DetailedSiteActivitiesQuery extends Query<DetailedSiteActivitiesState> {
  projectPlanUploadingState$ = this.select(state => state.projectPlanUploadingState);

  projectPlansQuery = new QueryEntity(this.store.projectPlans);
  projectPlans$ = this.projectPlansQuery.selectAll({ sortBy: sortProjectPlanFunc });
  activeProjectPlanId$ = this.projectPlansQuery.selectActiveId() as Observable<string>;
  activeVersion$ = this.projectPlansQuery.selectActive().pipe(
    filter(pp => !!pp),
    map(pp => pp.version)
  );

  activitiesQuery = new QueryEntity(this.store.activities);
  activities$ = this.activitiesQuery.selectAll();
  activitiesLoading$ = this.select(state => state.activitiesLoading);
  activeActivityId$ = this.activitiesQuery.selectActiveId() as Observable<string>;
  activeActivity$ = this.activitiesQuery.selectActive();

  activityMeasurementsQuery = new QueryEntity(this.store.activityMeasurements);

  inDrawingMode$ = this.activeActivity$.pipe(map(activity => activity?.inDrawingMode));

  inEditMode$ = this.activeActivity$.pipe(map(activity => activity?.inEditMode));

  hasEnabledEditors$ = this.activityMeasurementsQuery
    .selectAll({ filterBy: ({ isEnabledEditor }) => isEnabledEditor })
    .pipe(map(measurements => isDefined(measurements)));

  activityMeasurementsMapModifying$ = combineLatest([this.inDrawingMode$, this.inEditMode$]).pipe(
    map(([inDrawingMode, inEditMode]) => inDrawingMode || inEditMode)
  );

  activityMeasurementsIds$ = this.activityMeasurementsQuery
    .selectAll({
      filterBy: [({ markedForDelete }) => !markedForDelete]
    })
    .pipe(map(measurements => measurements.map(m => m.id)));
  plannedMeasurements$ = this.activityMeasurementsQuery.selectAll({
    filterBy: [({ measurementType }) => isPlanned(measurementType), ({ markedForDelete }) => !markedForDelete]
  });
  actualMeasurements$ = this.activityMeasurementsQuery.selectAll({
    filterBy: [({ measurementType }) => isActual(measurementType), ({ markedForDelete }) => !markedForDelete]
  });
  hasActivityMeasurements$ = this.activityMeasurementsQuery
    .selectAll({
      filterBy: [({ markedForDelete }) => !markedForDelete]
    })
    .pipe(map(measurements => isDefined(measurements)));
  activityMeasurementsLoading$ = this.select(state => state.activityMeasurementsLoading);

  tempPlannedDesignMeasurement$ = this.select(state => state.tempPlannedDesignMeasurement);

  sorting$ = this.select(state => state.sorting);
  filtering$ = this.select(state => state.filtering);

  constructor(protected store: DetailedSiteActivitiesStore, private siteQuery: DetailedSiteQuery) {
    super(store);
  }

  getAllProjectPlans() {
    return this.projectPlansQuery.getAll({ sortBy: sortProjectPlanFunc });
  }

  getProjectPlansCount() {
    return this.projectPlansQuery.getAll()?.length;
  }

  getActiveProjectPlan() {
    return this.projectPlansQuery.getActive();
  }

  getProjectPlanById(id: string) {
    return this.projectPlansQuery.getEntity(id);
  }

  getProjectPlanIdByVersion(version: string) {
    return this.getAllProjectPlans().find(pp => pp.version === version).id;
  }

  getProjectPlanName(id: string) {
    return this.getProjectPlanById(id).name;
  }

  getActiveProjectPlanId() {
    return this.getActiveProjectPlan()?.id as string;
  }

  getActiveVersion() {
    return this.getActiveProjectPlan()?.version;
  }

  getAllActivities() {
    return this.activitiesQuery.getAll();
  }

  getAllGanttActivities() {
    return this.activitiesQuery.getAll({ filterBy: activity => activity.type === ActivityEntityType.ACTIVITY });
  }

  getGeometricGanttActivitiesByType(activityType: ActivityType) {
    return this.activitiesQuery.getAll({
      filterBy: activity => activity.geometric && activity.type === ActivityEntityType.ACTIVITY && activity.activityType === activityType
    });
  }

  getIsEmptyActivities() {
    return this.activitiesQuery.getCount() === 0;
  }

  getActivityById(id: string) {
    return this.activitiesQuery.getEntity(id);
  }

  getActiveActivityId() {
    return this.activitiesQuery.getActiveId() as string;
  }

  getActiveActivity() {
    return this.activitiesQuery.getActive();
  }

  hasDisabledEditors() {
    const disabledEditors = this.activityMeasurementsQuery.getAll({ filterBy: ({ isEnabledEditor }) => !isEnabledEditor });
    return isDefined(disabledEditors);
  }

  getActivityDirtyCheck(id: string) {
    const dirtyCheck = new EntityDirtyCheckPlugin(this.activitiesQuery, { entityIds: id, comparator: activityDirtyCheckComparator });
    dirtyCheck.setHead();
    return dirtyCheck;
  }

  getActivityMeasurementDirtyCheck(measurementIds: string[]) {
    const dirtyCheck = new EntityDirtyCheckPlugin(this.activityMeasurementsQuery, {
      entityIds: measurementIds,
      comparator: activityMeasurementDirtyCheckComparator
    });
    dirtyCheck.setHead();
    return dirtyCheck;
  }

  getActivityMeasurements() {
    return this.activityMeasurementsQuery.getAll({ filterBy: [({ markedForDelete }) => !markedForDelete] });
  }

  hasActivityMeasurements() {
    return isCount(this.getActiveActivity()) ? this.hasActualMeasurements() : isDefined(this.getActivityMeasurements());
  }

  /**
   * Covers the case when activity type was changed
   */
  hasOnlyNewActivityMeasurements() {
    const measurements = this.getActivityMeasurements();
    return measurements.filter(m => m.isNew)?.length === measurements?.length;
  }

  getPlannedMeasurements() {
    return this.activityMeasurementsQuery.getAll({
      filterBy: [({ measurementType }) => isPlanned(measurementType), ({ markedForDelete }) => !markedForDelete]
    });
  }

  getActualMeasurements() {
    return this.activityMeasurementsQuery.getAll({
      filterBy: [({ measurementType }) => isActual(measurementType), ({ markedForDelete }) => !markedForDelete]
    });
  }

  hasActualMeasurements() {
    return isDefined(this.getActualMeasurements());
  }

  getTempActivityMeasurements() {
    return this.getActivityMeasurements().filter(m => m.isTemp);
  }

  hasTempActivityMeasurements() {
    return isDefined(this.getTempActivityMeasurements());
  }

  getActualMeasurementsByPlannedMeasurementId(plannedMeasurementId: string) {
    return this.activityMeasurementsQuery.getAll({
      filterBy: [
        ({ measurementType }) => isActual(measurementType),
        ({ plannedActivityMeasurementId }) => (plannedMeasurementId ? plannedActivityMeasurementId === plannedMeasurementId : true),
        ({ markedForDelete }) => !markedForDelete
      ]
    });
  }

  getActualMeasurementsByPlannedMeasurementIdAndTaskId(plannedMeasurementId: string, taskIdForFilter: string) {
    return this.activityMeasurementsQuery.getAll({
      filterBy: [
        ({ measurementType }) => isActual(measurementType),
        ({ plannedActivityMeasurementId }) => (plannedMeasurementId ? plannedActivityMeasurementId === plannedMeasurementId : true),
        ({ markedForDelete }) => !markedForDelete,
        ({ taskId }) => taskId === taskIdForFilter
      ]
    });
  }

  getActivityMeasurementsToSaveOrDelete() {
    return this.activityMeasurementsQuery.getAll().filter(m => m.markedForSave || m.markedForDelete);
  }

  getActivityMeasurementById(id: string) {
    return this.activityMeasurementsQuery.getEntity(id);
  }

  getTempPlannedDesignMeasurement() {
    return this.getValue().tempPlannedDesignMeasurement;
  }

  getAllActivityMeasurementEditors() {
    return this.getValue().activityMeasurementEditors;
  }

  getActivityMeasurementByEditorId(editorId: string) {
    const measurementId = this.getAllActivityMeasurementEditors().find(me => me.editorId === editorId)?.measurementId;
    return this.getActivityMeasurementById(measurementId);
  }

  getEditorByMeasurementId(measurementId: string) {
    return this.getAllActivityMeasurementEditors().find(me => me.measurementId === measurementId)?.editor$;
  }

  getMapNotificationsObserver() {
    return merge(this.getMapAddOrUpdateNotificationsObserver(), this.getMapDeleteNotificationsObserver());
  }

  getSourceModelType() {
    // Make measurements on DTM when FLAT model is selected
    const currentSourceModel = this.siteQuery.getCurrentSourceModel();
    return currentSourceModel === ActivityMeasurementModelSourceType.FLAT ? ActivityMeasurementModelSourceType.DTM : currentSourceModel;
  }

  private getMapDeleteNotificationsObserver() {
    return this.store.deletedCountMeasurementIdsSubject$.pipe(
      filter(id => isDefined(id)),
      map(
        id =>
          ({
            actionType: ActionType.DELETE,
            id
          } as AcNotification)
      )
    );
  }

  private getMapAddOrUpdateNotificationsObserver() {
    return this.store.addOrUpdateCountMeasurementsSubject$.pipe(
      filter(measurement => isDefined(measurement)),
      map(
        measurement =>
          ({
            actionType: ActionType.ADD_UPDATE,
            id: measurement.id,
            entity: measurement
          } as AcNotification)
      )
    );
  }

  get classifiedActivities$() {
    return combineLatest([this.activities$, this.filtering$, this.sorting$]).pipe(
      map(([activities, filtering, sorting]) => {
        let classifiedActivities: Activity[] = activities.filter(activity => activity.tracked && !isNewEntity(activity.id));
        if (filtering?.geometricFilter) {
          classifiedActivities = classifiedActivities.filter(activity => {
            return filtering.geometricFilter === ActivityGeometricFilterType.GEOMETRIC ? activity.geometric : !activity.geometric;
          });
        }
        if (filtering?.finishingFilter) {
          classifiedActivities = classifiedActivities.filter(activity => activity.complete < 100);
        }
        if (sorting) {
          classifiedActivities = classifiedActivities.sort(sortActivitiesFunc(sorting));
        }
        return classifiedActivities;
      })
    );
  }
}
