import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter, skipWhile, switchMap } from 'rxjs/operators';

import { ConfirmationDialogResultEnum } from '../shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from '../shared/confirmation-dialog/confirmation-dialog.service';
import { UploadWizardDialogData, UploadWizardMode } from './state/upload-wizard.model';
import { UploadWizardQuery } from './state/upload-wizard.query';
import { UploadWizardService } from './state/upload-wizard.service';
import { Step } from './wizard-stepper/steps';

@UntilDestroy()
@Component({
  selector: 'upload-wizard',
  templateUrl: './upload-wizard.component.html',
  styleUrls: ['./upload-wizard.component.scss']
})
export class UploadWizardComponent implements OnInit {
  Step = Step;
  Mode = UploadWizardMode;

  taskIndex: number;
  currentStep: Step;
  mode: UploadWizardMode;
  prevTaskHadGCP: boolean;
  prevTaskId: string;
  private taskSubscription: Subscription;

  constructor(
    public uploadWizardQuery: UploadWizardQuery,
    public uploadWizardService: UploadWizardService,
    private confirmationDialog: ConfirmationDialogService,
    private elementRef: ElementRef,
    private dialogRef: MatDialogRef<UploadWizardComponent>,
    @Inject(MAT_DIALOG_DATA) private data: UploadWizardDialogData
  ) {
    this.uploadWizardService.setStep(this.data.step);
    this.uploadWizardService.setIsFromBackup(this.data.isFromBackup);
    this.uploadWizardService.setMode(this.data.mode);

    this.prevTaskHadGCP = !!this.data.prevTaskHadGCP;
    this.prevTaskId = this.data.prevTaskId;

    this.taskIndex = this.data.taskIndex;
    this.uploadWizardService.setFirstFlight(this.data.taskIndex === 1);

    this.uploadWizardService.setSite(this.data.site);
    this.uploadWizardService.setTaskId(this.data.taskId);

    this.mode = this.uploadWizardQuery.getMode();

    this.uploadWizardService.setCallbacks(this.data.callbacks);
  }

  ngOnInit() {
    this.taskSubscription = this.uploadWizardQuery.taskId$
      .pipe(
        untilDestroyed(this),
        distinctUntilChanged(),
        filter(taskId => !!taskId),
        switchMap(taskId => this.data.callbacks.selectTask(taskId).pipe(untilDestroyed(this))),
        skipWhile(task => !task)
      )
      .subscribe(task => this.uploadWizardService.setTask(task));

    this.uploadWizardQuery.step$.pipe(untilDestroyed(this)).subscribe(step => (this.currentStep = step));

    this.dialogRef.afterClosed().subscribe(() => this.uploadWizardService.resetStore());

    this.dialogRef.backdropClick().subscribe(() => this.onClose());
    this.dialogRef.keydownEvents().subscribe((e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        this.onClose();
      }
    });
  }

  onClose() {
    const isAutomodel = this.uploadWizardQuery.getIsAutomodel();
    const uploadingLoading = this.uploadWizardQuery.getUploadingLoading();
    const uploadingError = this.uploadWizardQuery.getIsUploadingError();
    const allImages = this.uploadWizardQuery.getAllFilesToUpload();
    if (
      (this.currentStep === Step.IMAGES && allImages?.length > 0) ||
      this.currentStep === Step.FLIGHT_INFO ||
      (this.currentStep === Step.PREPROCESSING && !isAutomodel && (uploadingLoading || uploadingError)) ||
      (this.currentStep === Step.MODEL_GENERATION && isAutomodel && (uploadingLoading || uploadingError))
    ) {
      const dialogElement = this.elementRef.nativeElement as HTMLElement;
      const elementClientRect = dialogElement.getBoundingClientRect();
      const width = 450;
      const buffer = 10;
      const dialogConfig = {
        width: width + 'px',
        height: '150px',
        position: {
          top: elementClientRect.top + buffer + 'px',
          left: elementClientRect.right - width - buffer + 'px'
        }
      };
      const mode = this.uploadWizardQuery.getMode();
      const dialogData = {
        title: 'Leave the Flight Wizard?',
        content: mode === UploadWizardMode.CREATE ? `The flight and the selected images won't be saved` : `The changes won't be saved`,
        noCaption: 'No, Keep editing',
        yesCaption: mode === UploadWizardMode.CREATE ? 'Yes, Discard flight' : 'Yes, Discard changes'
      };
      const dialogRef = this.confirmationDialog.openDialog(dialogData, dialogConfig);
      dialogRef.afterClosed().subscribe(result => {
        if (result === ConfirmationDialogResultEnum.YES) {
          this.close();
        }
      });
    } else {
      this.close();
    }
  }

  close = () => {
    this.taskSubscription.unsubscribe();
    this.uploadWizardService.abortUpload(true);
    this.uploadWizardService.resetStore();
    this.dialogRef.close();
  };

  goToNextStep() {
    this.uploadWizardService.nextStep();
  }
}
