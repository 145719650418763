import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { isDefined } from '../utils/general';

export interface ConfirmationDialogData {
  title: string;
  /** Can be an HTML string. <b> will be marked red and <i> as warning. */
  content?: string;
  noCaption?: string;
  yesCaption?: string;
  alternativeButtonCaption?: string;
  disableClose?: boolean;
  beforeClosedValidation?: (result: ConfirmationDialogResultEnum) => void;
  beforeClosedValidationDialogResult?: ConfirmationDialogResultEnum;
}

export enum ConfirmationDialogResultEnum {
  NO,
  YES,
  ALTERNATIVE
}

@Component({
  selector: 'confirmation-dialog',
  template: `
    <button
      mat-icon-button
      (click)="close(undefined, ConfirmationDialogResultEnum.NO)"
      class="close-button"
      *ngIf="!data.disableClose"
      [disabled]="loading"
    >
      <mat-icon>close</mat-icon>
    </button>
    <h1 mat-dialog-title>{{ data.title }}</h1>
    <div mat-dialog-content [innerHTML]="data.content"></div>
    <div mat-dialog-actions>
      <button
        *ngIf="data.alternativeButtonCaption"
        mat-stroked-button
        class="light-btn alternative-button"
        (click)="close($event, ConfirmationDialogResultEnum.ALTERNATIVE)"
        [disabled]="loading && data.beforeClosedValidationDialogResult !== ConfirmationDialogResultEnum.ALTERNATIVE"
        [class.disable-click]="loading"
      >
        <mat-spinner
          *ngIf="
            loading && data.beforeClosedValidationDialogResult === ConfirmationDialogResultEnum.ALTERNATIVE;
            else alternativeButtonCaption
          "
          diameter="18"
        ></mat-spinner>
        <ng-template #alternativeButtonCaption>{{ data.alternativeButtonCaption }}</ng-template>
      </button>
      <button
        *ngIf="data.noCaption"
        mat-stroked-button
        class="light-btn"
        (click)="close($event, ConfirmationDialogResultEnum.NO)"
        [disabled]="loading && data.beforeClosedValidationDialogResult !== ConfirmationDialogResultEnum.NO"
        [class.disable-click]="loading"
      >
        <mat-spinner
          *ngIf="loading && data.beforeClosedValidationDialogResult === ConfirmationDialogResultEnum.NO; else noButtonCaption"
          diameter="18"
        ></mat-spinner>
        <ng-template #noButtonCaption>{{ data.noCaption }}</ng-template>
      </button>
      <button
        *ngIf="data.yesCaption"
        mat-flat-button
        class="dark-btn"
        (click)="close($event, ConfirmationDialogResultEnum.YES)"
        [disabled]="loading && data.beforeClosedValidationDialogResult !== ConfirmationDialogResultEnum.YES"
        [class.disable-click]="loading"
      >
        <mat-spinner
          *ngIf="loading && data.beforeClosedValidationDialogResult === ConfirmationDialogResultEnum.YES; else yesButtonCaption"
          diameter="18"
        ></mat-spinner>
        <ng-template #yesButtonCaption>{{ data.yesCaption }}</ng-template>
      </button>
    </div>
  `,
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  ConfirmationDialogResultEnum = ConfirmationDialogResultEnum;

  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData,
    private cd: ChangeDetectorRef
  ) {
    if (!this.data.disableClose) {
      this.dialogRef.backdropClick().subscribe(() => this.close());
      this.dialogRef.keydownEvents().subscribe((e: KeyboardEvent) => {
        if (e.key === 'Escape') {
          this.close();
        }
      });
    }
  }

  loading = false;

  ngOnInit() {}

  close(event?: MouseEvent, result: ConfirmationDialogResultEnum = ConfirmationDialogResultEnum.NO) {
    if (isDefined(this.data.beforeClosedValidation)) {
      if (result === (this.data.beforeClosedValidationDialogResult ?? ConfirmationDialogResultEnum.YES)) {
        this.loading = true;
        const buttonEl = event.currentTarget as HTMLElement;
        if (buttonEl) {
          // Keep original button width
          const width = buttonEl.getBoundingClientRect().width;
          buttonEl.style.width = width + 'px';
          buttonEl.style.minWidth = width + 'px';
        }
        this.cd.detectChanges();
      }
      this.data.beforeClosedValidation(result);
    } else {
      this.dialogRef.close(result);
    }
  }
}
