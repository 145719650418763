import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

import { AuthQuery } from './state/auth.query';

@Injectable({
  providedIn: 'root'
})
class HomePageRedirectGuardService {
  constructor(private router: Router, private authQuery: AuthQuery) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const userRole = this.authQuery.getUserRole();
    const defaultUrl = this.authQuery.getDefaultUrl(userRole);
    return this.router.parseUrl(defaultUrl);
  }
}

export const HomePageRedirectGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  return inject(HomePageRedirectGuardService).canActivate(next, state);
};
