import { Injectable, Injector } from '@angular/core';

import { isDefined } from '../../../shared/utils/general';
import { ActivityAreaCalcService } from '../../activity-details-box/calculations/activity-area-calc.service';
import { ActivityLengthCalcService } from '../../activity-details-box/calculations/activity-length-calc.service';
import { ActivityVolumeCalcService } from '../../activity-details-box/calculations/activity-volume-calc.service';
import { CalcService, PolygonWithSamplesCalcService, PolylineCalcService, PrecalcData } from '../../services/calc-services';
import { DetailedSiteQuery } from '../detailed-site.query';
import { ActivityMeasurement, ActivityType } from './detailed-site-activities.model';

export const ACTIVITY_MEASUREMENTS_CALC_SERVICE_MAPPING = {
  [ActivityType.AREA]: ActivityAreaCalcService,
  [ActivityType.LENGTH]: ActivityLengthCalcService,
  [ActivityType.VOLUME]: ActivityVolumeCalcService
};

@Injectable({
  providedIn: 'root'
})
export class ActivityMeasurementCalcService {
  constructor(private injector: Injector, private siteQuery: DetailedSiteQuery) {}

  async getCalcValues(activityType: ActivityType, measurement: ActivityMeasurement) {
    const measurementVertices = measurement.positions;
    if (!isDefined(measurementVertices)) {
      return;
    }
    const siteId = this.siteQuery.getSiteId();
    const calcService = this.injector.get<CalcService>(ACTIVITY_MEASUREMENTS_CALC_SERVICE_MAPPING[activityType]);

    let precalcData: PrecalcData;
    const isPolygonWithSamplesCalcService = calcService instanceof PolygonWithSamplesCalcService;
    const isPolylineCalcService = calcService instanceof PolylineCalcService;

    if (isPolygonWithSamplesCalcService || isPolylineCalcService) {
      const verticesForCalc = isPolygonWithSamplesCalcService ? [...measurementVertices, measurementVertices[0]] : measurementVertices;
      precalcData = calcService.precalc(verticesForCalc);
    }

    const modelData = {
      id: measurement.terrainModelId,
      type: measurement.terrainModelType
    };
    return await calcService.calcResults(measurement, siteId, modelData, precalcData);
  }
}
