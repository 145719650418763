/**
 * Datumate Tenant Service
 * APIs
 *
 * OpenAPI spec version: 3.3.4
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { GetCategoryResponse } from './getCategoryResponse';
import { GetCoordinateSystemResponse } from './getCoordinateSystemResponse';
import { GetSiteGroupResponse } from './getSiteGroupResponse';
import { GetSiteUserEntityResponse } from './getSiteUserEntityResponse';
import { SiteThumbnailBlock } from './siteThumbnailBlock';
import { TaskModel } from './taskModel';

export interface GetSiteResponse {
  address?: string;
  category?: GetCategoryResponse;
  coordinateSystem?: GetCoordinateSystemResponse;
  creationTime?: Date;
  customCategory?: string;
  description?: string;
  endDate?: Date;
  id?: string;
  lastModifiedTime?: Date;
  latitude?: number;
  longitude?: number;
  maxNumberOfFlights?: number;
  name?: string;
  numberOfFlights?: number;
  recentTask?: TaskModel;
  siteGroup?: GetSiteGroupResponse;
  siteOffset?: number;
  startDate?: Date;
  tenantId?: string;
  thumbnail?: SiteThumbnailBlock;
  units?: GetSiteResponse.UnitsEnum;
  users?: Array<GetSiteUserEntityResponse>;
  volumeUnits?: GetSiteResponse.VolumeUnitsEnum;
}
export namespace GetSiteResponse {
  export type UnitsEnum = 'METER' | 'USFOOT' | 'YARD' | 'INTFOOT';
  export const UnitsEnum = {
    METER: 'METER' as UnitsEnum,
    USFOOT: 'USFOOT' as UnitsEnum,
    YARD: 'YARD' as UnitsEnum,
    INTFOOT: 'INTFOOT' as UnitsEnum
  };
  export type VolumeUnitsEnum = 'METER' | 'USFOOT' | 'YARD' | 'INTFOOT';
  export const VolumeUnitsEnum = {
    METER: 'METER' as VolumeUnitsEnum,
    USFOOT: 'USFOOT' as VolumeUnitsEnum,
    YARD: 'YARD' as VolumeUnitsEnum,
    INTFOOT: 'INTFOOT' as VolumeUnitsEnum
  };
}
