import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { User } from '../state/users-and-teams.model';
import { UserDialogComponent, UserDialogData } from './user-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class UserDialogService {
  constructor(private dialog: MatDialog) {}

  openDialog(data: UserDialogData = {}) {
    return this.dialog.open<UserDialogComponent, UserDialogData, User>(UserDialogComponent, {
      autoFocus: false,
      restoreFocus: false,
      height: '60%',
      minWidth: '800px',
      minHeight: '500px',
      data
    });
  }
}
