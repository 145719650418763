import { saveAs } from 'file-saver';
import { v4 as uuidv4 } from 'uuid';

export async function sleep(timeout: number) {
  await new Promise<void>(resolve => setTimeout(() => resolve(), timeout));
}

export function generateUniqueId() {
  return uuidv4();
}

export function getArrayDepth(a: Array<any>, depth = 0) {
  if (!Array.isArray(a)) {
    return depth;
  }

  return getArrayDepth(a[0], depth + 1);
}

interface DownloadFileWithRetriesOptions {
  pollingFrequencySec: number;
  retryCount: number;
}

const DOWNLOAD_FILE_WITH_RETRIES_DEFAULT_OPTIONS: DownloadFileWithRetriesOptions = {
  pollingFrequencySec: 7 * 1000,
  retryCount: 10
};

export async function downloadFileWithRetries(
  url: string,
  fileName: string,
  options: DownloadFileWithRetriesOptions = DOWNLOAD_FILE_WITH_RETRIES_DEFAULT_OPTIONS
) {
  let success = false;

  if (url) {
    try {
      for (let i = 0; i < options.retryCount; i++) {
        const result = await fetch(url, { method: 'GET' });
        if (result.ok) {
          // File is ready, save it and stop retries
          const fileBlob = await result.blob();
          saveAs(fileBlob, fileName);
          success = true;
          break;
        } else if (result.status === 404) {
          // File isn't ready, retry after timeout
          await sleep(options.pollingFrequencySec);
          continue;
        } else {
          // Unknown error, stop retries
          const errorBody = await result.json();
          throw new Error(errorBody.message);
        }
      }
    } catch (error) {
      console.error('Error downloading file', error);
      success = false;
    }
  }

  return success;
}

export function isDefined(value: unknown): boolean {
  if (typeof value === 'number' || typeof value === 'boolean') {
    return value !== null && value !== undefined;
  } else if (typeof value === 'string' || value instanceof Array) {
    return !!value && value.length !== 0;
  } else if (value instanceof Date) {
    return !!value;
  } else if (value instanceof Set) {
    return !!value && value.size !== 0;
  } else if (typeof value === 'object') {
    return !!value && Object.keys(value).length !== 0;
  } else {
    return !!value;
  }
}

export function isSetEqual<T>(s1: Set<T>, s2: Set<T>) {
  return s1.size === s2.size && [...s1].every(v => s2.has(v));
}

export function isStringContains(str: string, searchStr: string, caseInsensitive = true) {
  if (caseInsensitive) {
    str = str.toLowerCase();
    searchStr = searchStr.toLowerCase();
  }

  return str.includes(searchStr);
}

export function sortNumericStrings(value1: string, value2: string, isDesc = false) {
  if (isDesc) {
    return value2.localeCompare(value1, undefined, { numeric: true });
  } else {
    return value1.localeCompare(value2, undefined, { numeric: true });
  }
}

export function sortByNumericStringField<T extends object>(field: string, isDesc = false) {
  return (value1: T, value2: T) => sortNumericStrings(value1[field], value2[field], isDesc);
}

export function rgbaToHex(rgbaString: string) {
  const match = rgbaString.match(/^rgba\((\d+),\s*(\d+),\s*(\d+),\s*((?:\d*\.)?\d+)\)$/);

  if (!match) {
    return rgbaString;
  }

  const [, red, green, blue, alpha] = match.map(Number);

  const normalizedAlpha = Math.max(0, Math.min(alpha, 1));

  const alpha255 = Math.round(normalizedAlpha * 255);

  const hexColor = `#${[red, green, blue, alpha255].map(n => n.toString(16).padStart(2, '0')).join('')}`;

  return hexColor;
}

export function waitForElementToExist(selector: string) {
  return new Promise(resolve => {
    const checkAndResolve = () => {
      const element = document.querySelector(selector);
      if (element) {
        resolve(element);
        observer.disconnect();
      }
    };

    checkAndResolve();

    const observer = new MutationObserver(checkAndResolve);

    observer.observe(document.body, {
      subtree: true,
      childList: true
    });
  });
}

export async function checkIsImageCached(imageUrl: string, timeout = 25) {
  // Init timeout promise
  let timerID: NodeJS.Timeout;
  const timer = new Promise<boolean>(resolve => {
    timerID = setTimeout(() => resolve(false), timeout);
  });

  // Init image loader
  const image = new Image();
  const imageLoader = new Promise<boolean>(resolve => {
    image.onload = () => resolve(true);
    image.onerror = () => resolve(true);
    image.src = imageUrl;
  });

  // Check if image is cached
  const isCached = await Promise.race([timer, imageLoader]);

  // Clear timeout and stop image fetch
  clearTimeout(timerID);
  image.src = '';

  return isCached;
}

/**
 * A utility function that acts as a compile-time check to ensure all possible cases have been handled.
 * This function should be used in exhaustive checks, such as in switch statements, to assert that
 * all possible values of a discriminated union have been accounted for.
 *
 * @param _ - The value that should never occur. If this function is called, it indicates that
 *            there is a missing case in the exhaustive check.
 */
export const assertNever = (_: never): void => void 0;
