import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { fileExtension } from '../../../shared/utils/file-utils';
import { AnnotationFile } from '../../state/detailed-site-entities/detailed-site-entities.model';
import { PreviewableFileTypes } from '../annotation-details-box-content/annotation-utils.service';

enum PreviewType {
  DOC = 'document',
  IMAGE = 'image',
  VIDEO = 'video'
}

@Component({
  selector: 'annotation-file-preview-dialog',
  templateUrl: './annotation-file-preview-dialog.component.html',
  styleUrls: ['./annotation-file-preview-dialog.component.scss']
})
export class AnnotationFilePreviewDialogComponent {
  readonly PreviewType = PreviewType;

  fileData: {
    name: string;
    type: PreviewType;
    url: string;
    safeUrl: SafeResourceUrl;
  };

  constructor(
    private dialogRef: MatDialogRef<AnnotationFilePreviewDialogComponent>,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) private data: { file: AnnotationFile }
  ) {
    const url = this.data.file.markedForSave ? URL.createObjectURL(this.data.file.file) : this.data.file.url;
    const name = this.data.file.name;
    this.fileData = {
      name,
      type: PreviewableFileTypes[fileExtension(name)],
      url,
      safeUrl: this.sanitizer.bypassSecurityTrustResourceUrl(url)
    };

    this.dialogRef.keydownEvents().subscribe(event => {
      if (event.key === 'Escape') {
        event.stopPropagation();
        this.close();
      }
    });
  }

  close() {
    this.dialogRef.close();
  }
}
