import { Injectable } from '@angular/core';
import { FeatureCollection, Point } from 'geojson';

import { isDefined } from '../../../shared/utils/general';
import { roundTo } from '../../../shared/utils/math';
import { PolygonWithSamplesCalcService, PrecalcData } from '../../services/calc-services';
import { TerrainSamplingService } from '../../services/terrain-sampling.service';
import { DetailedSiteQuery } from '../../state/detailed-site.query';
import { DetailedSiteService } from '../../state/detailed-site.service';
import {
  ActivityMeasurement,
  ActivityMeasurementBaseSurface,
  ActivityMeasurementBaseSurfaceType,
  ActivityMeasurementModelSourceType,
  ActivityMeasurementValues
} from '../../state/detailed-site-activities/detailed-site-activities.model';

@Injectable({
  providedIn: 'root'
})
export class ActivityVolumeCalcService extends PolygonWithSamplesCalcService {
  constructor(
    protected siteQuery: DetailedSiteQuery,
    protected terrainSampling: TerrainSamplingService,
    protected siteService: DetailedSiteService
  ) {
    super(siteQuery, terrainSampling, siteService);
  }

  async calcResults(
    measurement: ActivityMeasurement,
    siteId: string,
    modelData: { id: string; type: 'TASK' | 'DESIGN' },
    precalcData?: PrecalcData
  ): Promise<{ calcResult: ActivityMeasurementValues }> {
    const { pointGridFeatureCollection, cellSide } = precalcData;

    const baseSurfaceSamplesHeights = await this.getActivityBaseSurfaceSamplesHeights(siteId, measurement, pointGridFeatureCollection);

    const modelSamplesHeights = await this.getActivityModelSamplesHeights(
      siteId,
      modelData,
      measurement.sourceModelType,
      pointGridFeatureCollection
    );

    const { cut, fill } = this.getVolumesData(modelSamplesHeights, baseSurfaceSamplesHeights, cellSide, measurement.baseSurface);

    return {
      calcResult: {
        values: { cut, fill }
      }
    };
  }

  private async getActivityBaseSurfaceSamplesHeights(
    siteId: string,
    measurement: ActivityMeasurement,
    pointGridFeatureCollection: FeatureCollection<Point>
  ) {
    // bsSamplePointsCartographic is null if BS type is MINELEVATION or CUSTOMELEVATION
    if (
      measurement.baseSurface.type === ActivityMeasurementBaseSurfaceType.MINELEVATION ||
      measurement.baseSurface.type === ActivityMeasurementBaseSurfaceType.CUSTOMELEVATION
    ) {
      return;
    }
    const baseSurfaceProps = {
      type: measurement.baseSurface.type,
      terrain: await this.getBaseSurfaceTerrain(
        siteId,
        null,
        measurement.baseSurface.type,
        measurement.baseSurface.id,
        measurement.sourceModelType
      )
    };

    const bsSamplePointsCartographic = await this.getBaseSurfaceSamplePointsCartographic(
      measurement?.positions,
      baseSurfaceProps,
      pointGridFeatureCollection
    );
    return bsSamplePointsCartographic?.map(p => roundTo(p.height));
  }

  private async getActivityModelSamplesHeights(
    siteId: string,
    modelData: { id: string; type: 'TASK' | 'DESIGN' },
    sourceModel: ActivityMeasurementModelSourceType,
    pointGridFeatureCollection: FeatureCollection<Point>
  ) {
    const modelTerrain = await this.siteService.getTerrainProvider(siteId, modelData.id, modelData.type, sourceModel);
    const modelSamplePointsCartographic = modelTerrain
      ? await this.createSamplePointsCartographic(pointGridFeatureCollection, modelTerrain)
      : null;

    return modelSamplePointsCartographic?.map(p => roundTo(p.height));
  }

  private getVolumesData(
    modelSamplesHeights: number[],
    baseSurfaceSamplesHeights: number[],
    cellSide: number,
    baseSurface: ActivityMeasurementBaseSurface
  ) {
    let belowSurfaceVolume = 0;
    let aboveSurfaceVolume = 0;
    let elevationMin = Number.MAX_SAFE_INTEGER;
    let elevationMax = Number.MIN_SAFE_INTEGER;
    let baseSurfaceHeight = baseSurface.elevation;
    const pointsCount = modelSamplesHeights.length;
    const volumeValues: number[] = new Array(pointsCount).fill(null);

    const getDeltaVolume = (a: number, b: number) => {
      const diff = a - b;
      const deltaVolume = cellSide * cellSide * diff;
      return roundTo(deltaVolume, 6);
    };

    const updateElevationMin = (modelHeights: number[], index: number, baseSurfaceHeights: number[] | number) => {
      if (isDefined(modelHeights[index]) && modelHeights[index] < elevationMin) {
        elevationMin = modelHeights[index];
      }
    };

    const updateElevationMax = (modelHeights: number[], index: number, baseSurfaceHeights: number[] | number) => {
      if (isDefined(modelHeights[index]) && modelHeights[index] > elevationMax) {
        elevationMax = modelHeights[index];
      }
    };

    const updateVolumesWithBSTerrainHeights = (modelHeights: number[], index: number, baseSurfaceHeights: number[]) => {
      if (!isDefined(modelHeights[index]) || !isDefined(baseSurfaceHeights[index])) {
        volumeValues[index] = 0;
        return;
      }

      const volumeDiff = getDeltaVolume(modelHeights[index], baseSurfaceHeights[index]);
      volumeValues[index] = volumeDiff;
      if (volumeDiff > 0) {
        aboveSurfaceVolume += volumeDiff;
      } else if (volumeDiff < 0) {
        belowSurfaceVolume += volumeDiff;
      }
    };

    const updateVolumesWithBSHeight = (modelHeights: number[], index: number, baseSurfaceHeight: number) => {
      if (!isDefined(modelHeights[index]) || !isDefined(baseSurfaceHeight)) {
        volumeValues[index] = 0;
        return;
      }

      const volumeDiff = getDeltaVolume(modelHeights[index], baseSurfaceHeight);
      volumeValues[index] = volumeDiff;
      if (volumeDiff > 0) {
        aboveSurfaceVolume += volumeDiff;
      } else if (volumeDiff < 0) {
        belowSurfaceVolume += volumeDiff;
      }
    };

    const iterateSamples = (func: Function) => {
      for (let i = 0; i < pointsCount; i++) {
        func(i);
      }
    };

    let actionsList: Function[];
    const updateResData = (i: number) => actionsList.forEach(f => f(modelSamplesHeights, i, baseSurfaceSamplesHeights));
    const updateResDataWithBSHeight = (i: number) => actionsList.forEach(f => f(modelSamplesHeights, i, baseSurfaceHeight));

    switch (baseSurface.type) {
      case ActivityMeasurementBaseSurfaceType.INTERPOLATED:
      case ActivityMeasurementBaseSurfaceType.TASK:
      case ActivityMeasurementBaseSurfaceType.DESIGN: {
        actionsList = [updateElevationMin, updateElevationMax, updateVolumesWithBSTerrainHeights];
        iterateSamples(updateResData);
        break;
      }
      case ActivityMeasurementBaseSurfaceType.MINELEVATION: {
        actionsList = [updateElevationMin, updateElevationMax];
        iterateSamples(updateResData);
        // calc volumes after min elevation was calc
        baseSurfaceHeight = elevationMin;
        actionsList = [updateVolumesWithBSHeight];
        iterateSamples(updateResDataWithBSHeight);
        break;
      }
      case ActivityMeasurementBaseSurfaceType.CUSTOMELEVATION: {
        actionsList = [updateElevationMin, updateElevationMax, updateVolumesWithBSHeight];
        iterateSamples(updateResDataWithBSHeight);
        break;
      }
    }

    /**
     * Base Surface (baseSurface.isTarget = false):
     * above:     Fill
     *       ---------------------
     * below:      Cut
     */

    /**
     * Target Surface (baseSurface.isTarget = true):
     * above:    Cut
     *       ---------------------
     * below:    Fill
     */

    return {
      cut: baseSurface.isTarget ? Math.abs(aboveSurfaceVolume) : Math.abs(belowSurfaceVolume),
      fill: baseSurface.isTarget ? Math.abs(belowSurfaceVolume) : Math.abs(aboveSurfaceVolume)
    };
  }
}
