import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { differenceBy } from 'lodash';
import { catchError, of, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  CreateWaterFlowReportRequest,
  CreateWaterFlowReportResponse,
  GetWaterFlowReportResponse,
  GetWaterFlowReportsResponse,
  UpdateWaterFlowReportDataRequest,
  UpdateWaterFlowReportDataResponse
} from '../../../../../generated/file/model/models';
import { REQUIRED_ACCESS_LEVEL_HEADER } from '../../../../auth/state/auth.utils';
import { ResourceLinkType } from '../../../../shared/resource-links/resource-links.model';
import { ResourceLinksService } from '../../../../shared/resource-links/resource-links.service';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { getServiceUrl } from '../../../../shared/utils/backend-services';
import { isDefined } from '../../../../shared/utils/general';
import { BasicReportsService, LogoFiles } from '../basic-reports.service';
import { ReportEntity, REPORTS_PERMISSIONS, ReportState, ReportType, WaterFlowReport } from '../detailed-site-reports.model';
import { DetailedSiteReportsQuery } from '../detailed-site-reports.query';
import { DetailedSiteReportsStore } from '../detailed-site-reports.store';

@Injectable({ providedIn: 'root' })
export class WaterflowReportsService {
  readonly reportType = ReportType.WATER_FLOW as const;
  readonly reportPermissions = REPORTS_PERMISSIONS[this.reportType];

  constructor(
    private http: HttpClient,
    private reportsStore: DetailedSiteReportsStore,
    private reportsQuery: DetailedSiteReportsQuery,
    private resourceLinksService: ResourceLinksService,
    private analyticsService: AnalyticsService,
    private basicReportsService: BasicReportsService
  ) {}

  fetchReport(siteId: string, reportId: string) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: this.reportPermissions.read } };
    return this.http
      .get<GetWaterFlowReportResponse>(`${getServiceUrl('file')}/waterFlowReports/sites/${siteId}/reports/${reportId}`, options)
      .pipe(
        tap(report => {
          if (isDefined(report)) {
            this.reportsStore.upsertWaterFlowReports([{ ...report, type: ReportType.WATER_FLOW }]);
          }
        }),
        catchError(error => {
          console.error('Error fetching water flow report', error);
          return of(null);
        })
      );
  }

  handleFetchReports = (response: GetWaterFlowReportsResponse, siteId: string) => {
    const existingReports = this.reportsQuery.getAllWaterFlowReports();
    const newReports = response?.waterFlowReports;
    if (!isDefined(newReports)) {
      // Remove all reports from store
      this.basicReportsService.removeReportsOfTypeFromStore(
        existingReports.map(report => report.id),
        this.reportType
      );
      return;
    }

    // Find removed reports and update resource links
    const removedReports = differenceBy(existingReports, newReports, 'id');
    this.resourceLinksService.removeResourcesOfType(
      removedReports.map(report => report.id),
      ResourceLinkType.REPORT
    );

    this.reportsStore.upsertWaterFlowReports(
      newReports.map(report => ({ ...report, type: ReportType.WATER_FLOW, siteId } as WaterFlowReport))
    );
  };

  generateReport(request: CreateWaterFlowReportRequest, logoFiles: LogoFiles) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: this.reportPermissions.create } };
    return this.http.post(`${getServiceUrl('file')}/waterFlowReports/generateWaterFlowReport`, request, options).pipe(
      tap((response: CreateWaterFlowReportResponse) => {
        if (response) {
          const report: WaterFlowReport = {
            ...request,
            id: response.reportId,
            type: ReportType.WATER_FLOW,
            creationTime: new Date(),
            lastModifiedTime: new Date(),
            state: ReportState.PROCESSING
          };
          this.reportsStore.upsertWaterFlowReports([report]);
          this.analyticsService.generateReport(report);
        }
      }),
      switchMap((response: CreateWaterFlowReportResponse) => {
        if (response) {
          const logoUrls = {
            consultantLogo: response.consultantLogoURL,
            customerLogo: response.customerLogoURL,
            signatureLogo: response.signatureLogoURL
          };
          return this.basicReportsService.updateReportLogos(logoUrls, logoFiles);
        }

        return of(null);
      })
    );
  }

  updateReport(siteId: string, reportId: string, request: UpdateWaterFlowReportDataRequest, logoFiles: LogoFiles) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: this.reportPermissions.update } };
    return this.http.put(`${getServiceUrl('file')}/waterFlowReports/sites/${siteId}/reports/${reportId}`, request, options).pipe(
      tap((response: UpdateWaterFlowReportDataResponse) => {
        if (response) {
          const report: WaterFlowReport = {
            ...request,
            id: response.reportId,
            type: ReportType.WATER_FLOW
          };
          this.reportsStore.upsertWaterFlowReports([report]);
          this.analyticsService.editReport(report);
        }
      }),
      switchMap((response: UpdateWaterFlowReportDataResponse) => {
        if (response) {
          const logoUrls = {
            consultantLogo: logoFiles.consultantLogo ? response.putConsultantLogoURL : response.deleteConsultantLogoURL,
            customerLogo: logoFiles.customerLogo ? response.putCustomerLogoURL : response.deleteCustomerLogoURL,
            signatureLogo: logoFiles.signatureLogo ? response.putSignatureLogoURL : response.deleteSignatureLogoURL
          };
          return this.basicReportsService.updateReportLogos(logoUrls, logoFiles, true);
        }

        return of(null);
      }),
      switchMap(() => this.fetchReport(siteId, reportId))
    );
  }

  deleteReport(report: ReportEntity<ReportType.WATER_FLOW>) {
    const url = `${getServiceUrl('file')}/waterFlowReports/sites/${report.siteId}/reports/${report.id}`;
    return this.basicReportsService.deleteReport(report, url);
  }
}
