import { animate, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'field-error',
  template: ` <mat-error [@fadeEnterUp]>{{ text }}</mat-error> `,
  styles: [
    `
      .mat-mdc-form-field-error {
        font-size: 12px;
        font-weight: 500;
      }
    `
  ],
  animations: [
    trigger('fadeEnterUp', [
      transition(':enter', [
        style({ transform: 'translateY(10px)', opacity: 0 }),
        animate(100, style({ transform: 'translateY(0px)', opacity: 1 }))
      ])
    ])
  ]
})
export class FieldErrorComponent implements OnInit {
  @Input() text: string;

  constructor() {}

  ngOnInit() {}
}
