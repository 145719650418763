/**
 * Datumate
 * API's for mile stone I
 *
 * OpenAPI spec version: 3.7.3
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GetRoadDesignProgressReportModelResponse {
  filePath?: string;
  id?: string;
  name?: string;
  surfaceType?: GetRoadDesignProgressReportModelResponse.SurfaceTypeEnum;
}
export namespace GetRoadDesignProgressReportModelResponse {
  export type SurfaceTypeEnum = 'TOP' | 'BOTTOM';
  export const SurfaceTypeEnum = {
    TOP: 'TOP' as SurfaceTypeEnum,
    BOTTOM: 'BOTTOM' as SurfaceTypeEnum
  };
}
