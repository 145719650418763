import { Component, Input, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';

type LoaderType = 'folding-cube' | 'filling-square';

@Component({
  selector: 'datu-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  @Input() size = 40;
  @Input() borderWidth: number;

  loaderType: LoaderType;

  constructor() {
    this.loaderType = (environment.whitelabel.loaderType as LoaderType) ?? 'folding-cube';
  }

  ngOnInit() {}
}
