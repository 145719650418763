import { Injectable } from '@angular/core';
import { EntityState, EntityStore, Store, StoreConfig } from '@datorama/akita';
import { produce } from 'immer';

import {
  CrossSectionProgressReport,
  CrossSectionVolumeReport,
  ElevationGridHeatmapReport,
  ReportEntity,
  ReportType,
  RoadGradeCheckingReport,
  SurfaceGradeCheckingReport,
  VolumeGridHeatmapReport,
  WaterFlowReport
} from './detailed-site-reports.model';

export interface DetailedSiteReportsState {
  loading: boolean;
}

export function createInitialState(): DetailedSiteReportsState {
  return {
    loading: true
  };
}

interface CrossSectionVolumeReportsState extends EntityState<CrossSectionVolumeReport> {}
interface CrossSectionProgressReportsState extends EntityState<CrossSectionProgressReport> {}
interface VolumeGridReportsState extends EntityState<VolumeGridHeatmapReport> {}
interface ElevationGridReportsState extends EntityState<ElevationGridHeatmapReport> {}
interface SurfaceGradeCheckingReportsState extends EntityState<SurfaceGradeCheckingReport> {}
interface RoadGradeCheckingReportsState extends EntityState<RoadGradeCheckingReport> {}
interface WaterFlowReportState extends EntityState<WaterFlowReport> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'site-reports' })
export class DetailedSiteReportsStore extends Store<DetailedSiteReportsState> {
  crossSectionVolumeReports = new EntityStore<CrossSectionVolumeReportsState>({}, { name: 'cross-section-volume-reports', idKey: 'id' });
  crossSectionProgressReports = new EntityStore<CrossSectionProgressReportsState>(
    {},
    { name: 'cross-section-progress-reports', idKey: 'id' }
  );
  volumeGridReports = new EntityStore<VolumeGridReportsState>({}, { name: 'volume-grid-reports', idKey: 'id' });
  elevationGridReports = new EntityStore<ElevationGridReportsState>({}, { name: 'elevation-grid-reports', idKey: 'id' });
  surfaceGradeCheckingReports = new EntityStore<SurfaceGradeCheckingReportsState>(
    {},
    { name: 'surface-grade-checking-reports', idKey: 'id' }
  );
  roadGradeCheckingReports = new EntityStore<RoadGradeCheckingReportsState>({}, { name: 'road-grade-checking-reports', idKey: 'id' });
  waterFlowReports = new EntityStore<WaterFlowReportState>({}, { name: 'water-flow-reports', idKey: 'id' });

  private reportStoreMap = {
    [ReportType.CROSS_SECTION_PROGRESS]: this.crossSectionProgressReports,
    [ReportType.CROSS_SECTION_VOLUME]: this.crossSectionVolumeReports,
    [ReportType.ROAD_GRADE_CHECKING]: this.roadGradeCheckingReports,
    [ReportType.VOLUME_GRID_HEATMAP]: this.volumeGridReports,
    [ReportType.ELEVATION_GRID_HEATMAP]: this.elevationGridReports,
    [ReportType.SURFACE_GRADE_CHECKING]: this.surfaceGradeCheckingReports,
    [ReportType.WATER_FLOW]: this.waterFlowReports
  } as const;

  constructor() {
    super(createInitialState());
  }

  setLoading(loading: boolean) {
    this.update(
      produce((draftState: DetailedSiteReportsState) => {
        draftState.loading = loading;
      })
    );
  }

  resetStore() {
    this.reset();

    Object.values(this.reportStoreMap).forEach(store => store.reset());
  }

  upsertCrossSectionVolumeReports(reports: CrossSectionVolumeReport[]) {
    this.crossSectionVolumeReports.upsertMany(reports);
  }

  upsertCrossSectionProgressReports(reports: CrossSectionProgressReport[]) {
    this.crossSectionProgressReports.upsertMany(reports);
  }

  upsertSurfaceGradeCheckingReports(reports: SurfaceGradeCheckingReport[]) {
    this.surfaceGradeCheckingReports.upsertMany(reports);
  }

  upsertRoadGradeCheckingReports(reports: RoadGradeCheckingReport[]) {
    this.roadGradeCheckingReports.upsertMany(reports);
  }

  upsertVolumeGridReports(reports: VolumeGridHeatmapReport[]) {
    this.volumeGridReports.upsertMany(reports);
  }

  upsertElevationGridReports(reports: ElevationGridHeatmapReport[]) {
    this.elevationGridReports.upsertMany(reports);
  }

  upsertWaterFlowReports(reports: WaterFlowReport[]) {
    this.waterFlowReports.upsertMany(reports);
  }

  removeReport(report: ReportEntity) {
    this.reportStoreMap[report.type].remove(report.id);
  }

  removeReportsOfType(reportIds: string[], reportType: ReportType) {
    this.reportStoreMap[reportType].remove(reportIds);
  }
}
