import { Injectable } from '@angular/core';
import { ActiveState, EntityState, EntityStore, Store, StoreConfig } from '@datorama/akita';
import { produce } from 'immer';
import { partition } from 'lodash';
import { BehaviorSubject } from 'rxjs';

import { PolygonPolylineEditorObservable } from '../../../shared/utils/cesium-common';
import { CoordinateSystem } from '../../../tenant/tenant.model';
import { DEFAULT_DRAWING_STYLE_PROPS } from '../../measurements-details-box/annotation-details-box-content/annotation-utils.service';
import {
  Analytic,
  AnalyticType,
  Annotation,
  AnnotationFile,
  AnnotationNote,
  AnnotationType,
  CrossSectionPoint,
  Drawing,
  DrawingEditor,
  EntityType,
  Group,
  Layer,
  LineType,
  MapEntity,
  Measurement,
  MeasurementType,
  ModelEdit,
  ModelEditType,
  PointType,
  PolygonType,
  StoreDrawingStyleProps
} from './detailed-site-entities.model';

export interface DetailedSiteEntitiesState {
  siteId: string;
  siteCoordinateSystem: CoordinateSystem;
  crossSectionPoint: CrossSectionPoint;
  activeEntityId: string;
  activeEntityType: EntityType;
  activeEditorModifying: boolean;
  activeEditor: PolygonPolylineEditorObservable;
  showMapEditorEndDrawingButtons: boolean;
  isMapEntitySelectionDisabled: boolean;
  mapVisualizationLoading: boolean;
  calculationLoading: boolean;
  deltaCalculationLoading: boolean;
  showDrawingTools: boolean;
  addingMarkerForActiveEntity: boolean;
  drawingEditors: DrawingEditor[];
  drawingsLoading: boolean;
  activeDrawingStyleProps: StoreDrawingStyleProps;
}

function createInitialState() {
  return {
    siteId: null,
    activeEntityId: null,
    activeEntityType: null,
    activeEditorModifying: false,
    activeEditor: null,
    showMapEditorEndDrawingButtons: false,
    isMapEntitySelectionDisabled: false,
    mapVisualizationLoading: false,
    calculationLoading: false,
    deltaCalculationLoading: false,
    showDrawingTools: false,
    addingMarkerForActiveEntity: false,
    drawingEditors: [],
    drawingsLoading: false,
    activeDrawingStyleProps: DEFAULT_DRAWING_STYLE_PROPS
  };
}

export interface AnalyticState extends EntityState<Analytic>, ActiveState {}
export interface MeasurementState extends EntityState<Measurement>, ActiveState {}
export interface AnnotationState extends EntityState<Annotation>, ActiveState {}
export interface DrawingState extends EntityState<Drawing>, ActiveState {}
export interface ModelEditState extends EntityState<ModelEdit>, ActiveState {}
export interface LayerState extends EntityState<Layer> {}
export interface GroupState extends EntityState<Group> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'site-entities' })
export class DetailedSiteEntitiesStore extends Store<DetailedSiteEntitiesState> {
  analytics = new EntityStore<AnalyticState>({ active: null }, { name: 'site-analytics' });
  measurements = new EntityStore<MeasurementState>({ active: null, loading: false }, { name: 'site-measurements' });
  annotations = new EntityStore<AnnotationState>({ active: null }, { name: 'site-annotations' });
  drawings = new EntityStore<DrawingState>({ active: null }, { name: 'site-annotation-drawings' });
  modelEdits = new EntityStore<ModelEditState>({ active: null }, { name: 'site-model-edits' });
  layers = new EntityStore<LayerState>({}, { name: 'site-layers' });
  groups = new EntityStore<GroupState>({}, { name: 'site-groups' });

  deletedMarkers$ = new BehaviorSubject<string>(undefined);
  deletedLines$ = new BehaviorSubject<string>(undefined);
  deletedPolygons$ = new BehaviorSubject<string>(undefined);
  deletedModelEdits$ = new BehaviorSubject<string>(undefined);

  constructor() {
    super(createInitialState());
  }

  initStore(siteId: string, siteCoordinateSystem: CoordinateSystem) {
    this.update(
      produce((draftState: DetailedSiteEntitiesState) => {
        draftState.siteId = siteId;
        draftState.siteCoordinateSystem = siteCoordinateSystem;
      })
    );
  }

  pinMapEntities(entities: MapEntity[], pinned = true) {
    const { analytics, measurements, annotations, modelEdits } = this.groupEntityIdsByType(entities);

    this.analytics.update(analytics, { pinned });
    this.measurements.update(measurements, { pinned });
    this.annotations.update(annotations, { pinned });
    this.modelEdits.update(modelEdits, { pinned });
  }

  private getStoreByType(type: EntityType): EntityStore<AnalyticState | MeasurementState | AnnotationState | ModelEditState> {
    if (type in AnalyticType) {
      return this.analytics;
    } else if (type in MeasurementType) {
      return this.measurements;
    } else if (type in AnnotationType) {
      return this.annotations;
    } else if (type in ModelEditType) {
      return this.modelEdits;
    }
  }

  private groupEntityIdsByType(entities: Partial<MapEntity>[]) {
    const results = {
      analytics: [] as string[],
      measurements: [] as string[],
      annotations: [] as string[],
      modelEdits: [] as string[]
    };

    entities?.forEach(entity => {
      if (entity.type in AnalyticType) {
        results.analytics.push(entity.id);
      } else if (entity.type in MeasurementType) {
        results.measurements.push(entity.id);
      } else if (entity.type in AnnotationType) {
        results.annotations.push(entity.id);
      } else if (entity.type in ModelEditType) {
        results.modelEdits.push(entity.id);
      }
    });

    return results;
  }

  addEntity(entity: MapEntity) {
    this.getStoreByType(entity.type).add(entity);
  }

  updateEntity(entity: Partial<MapEntity>) {
    this.getStoreByType(entity.type).update(entity.id, entity);
  }

  removeEntity(id: string, type: EntityType) {
    this.removeEntities([{ id, type } as Partial<MapEntity>]);
  }

  removeEntities(entities: Partial<MapEntity>[]) {
    if (!entities || entities.length === 0) {
      return;
    }

    const { analytics, measurements, annotations, modelEdits } = this.groupEntityIdsByType(entities);
    this.analytics.remove(analytics);
    this.measurements.remove(measurements);
    this.annotations.remove(annotations);
    this.modelEdits.remove(modelEdits);

    entities.forEach(({ id, type }) => {
      if (type in PointType) {
        this.deletedMarkers$.next(id);
      } else if (type in LineType) {
        this.deletedLines$.next(id);
      } else if (type in PolygonType) {
        this.deletedPolygons$.next(id);
      } else if (type in ModelEditType) {
        this.deletedModelEdits$.next(id);
      }
    });
  }

  upsertAnalytic(entity: Partial<Analytic>) {
    this.analytics.upsert(entity.id, entity);
  }

  removeAnalytic(id: string) {
    this.analytics.remove(id);
  }

  upsertMeasurement(entity: Partial<Measurement>) {
    this.measurements.upsert(entity.id, entity);
  }

  removeMeasurement(id: string) {
    this.measurements.remove(id);
  }

  setAnalytics(analytics: Analytic[]) {
    this.analytics.set(analytics);
  }

  setAnnotations(annotations: Annotation[]) {
    this.annotations.set(annotations);
  }

  addAnnotationNote(entityId: string, note: Partial<AnnotationNote>) {
    this.annotations.update(entityId, entity => ({
      ...entity,
      notes: [...(entity.notes || []), note]
    }));
  }

  updateAnnotationNote(entityId: string, note: Partial<AnnotationNote>) {
    this.annotations.update(entityId, entity => ({
      ...entity,
      notes: entity.notes.map(n => (n.id === note.id ? { ...n, ...note } : n))
    }));
  }

  removeAnnotationNote(entityId: string, noteId: string) {
    this.annotations.update(entityId, entity => ({
      ...entity,
      notes: entity.notes.filter(note => note.id !== noteId)
    }));
  }

  addAnnotationFiles(id: string, attachments: Array<AnnotationFile>) {
    this.annotations.update(id, entity => ({
      ...entity,
      attachments: [...entity.attachments, ...attachments]
    }));
  }

  updateAnnotationFile(id: string, attachment: Partial<AnnotationFile>) {
    this.annotations.update(id, entity => ({
      ...entity,
      attachments: entity.attachments.map(a => (a.id === attachment.id ? { ...a, ...attachment } : a))
    }));
  }

  removeAnnotationFile(entityId: string, fileId: string) {
    this.annotations.update(entityId, entity => ({
      ...entity,
      attachments: entity.attachments.filter(file => file.id !== fileId)
    }));
  }

  addAnnotationDrawings(id: string, drawings: Array<Drawing>) {
    this.annotations.update(id, entity => ({
      ...entity,
      drawings: [...entity.drawings, ...drawings]
    }));
  }

  updateAnnotationDrawing(id: string, drawing: Partial<Drawing>) {
    this.annotations.update(id, entity => ({
      ...entity,
      drawings: entity.drawings.map(d => (d.id === d.id ? { ...d, ...drawing } : d))
    }));
  }

  removeAnnotationDrawings(entityId: string, drawingIds: string[]) {
    this.annotations.update(entityId, entity => ({
      ...entity,
      drawings: entity.drawings.filter(drawing => !drawingIds.includes(drawing.id))
    }));
  }

  setMeasurements(measurements: Measurement[]) {
    this.measurements.set(measurements);
  }

  setModelEdits(edits: ModelEdit[]) {
    this.modelEdits.set(edits);
  }

  addTaskToModelEdits(edits: ModelEdit[], taskId: string, taskElevations: number[]) {
    this.modelEdits.update(
      edits.map(edit => edit.id),
      e => ({
        ...e,
        positionsElevation: {
          ...e.positionsElevation,
          [taskId]: taskElevations
        }
      })
    );
  }

  removeTaskFromModelEdits(edits: ModelEdit[], taskId: string) {
    this.modelEdits.update(
      edits.map(edit => edit.id),
      e => {
        const { [taskId]: _, ...positionsElevation } = e.positionsElevation;
        return { ...e, positionsElevation };
      }
    );
  }

  updateCrossPoint(point: Partial<CrossSectionPoint>) {
    this.update(
      produce((draftState: DetailedSiteEntitiesState) => {
        draftState.crossSectionPoint = { ...draftState.crossSectionPoint, ...point };
      })
    );
  }

  setMapVisualizationLoading(loading: boolean) {
    this.update(
      produce((draftState: DetailedSiteEntitiesState) => {
        draftState.mapVisualizationLoading = loading;
      })
    );
  }

  setCalculationLoading(loading: boolean) {
    this.update(
      produce((draftState: DetailedSiteEntitiesState) => {
        draftState.calculationLoading = loading;
      })
    );
  }

  setDeltaCalculationLoading(loading: boolean) {
    this.update(
      produce((draftState: DetailedSiteEntitiesState) => {
        draftState.deltaCalculationLoading = loading;
      })
    );
  }

  setActiveEntity(entity: MapEntity) {
    this.update(
      produce((draftState: DetailedSiteEntitiesState) => {
        draftState.activeEntityId = entity?.id;
        draftState.activeEntityType = entity?.type;
      })
    );
  }

  setActiveEditor(activeEditor: PolygonPolylineEditorObservable) {
    this.update(
      produce((draftState: DetailedSiteEntitiesState) => {
        draftState.activeEditor = activeEditor;
      })
    );
  }

  showMapEditorEndDrawingButtons(show: boolean) {
    this.update(
      produce((draftState: DetailedSiteEntitiesState) => {
        draftState.showMapEditorEndDrawingButtons = show;
      })
    );
  }

  showDrawingTools(show: boolean) {
    this.update(
      produce((draftState: DetailedSiteEntitiesState) => {
        draftState.showDrawingTools = show;
      })
    );
  }

  setIsMapEntitySelectionDisabled(isDisabled: boolean) {
    this.update(
      produce((draftState: DetailedSiteEntitiesState) => {
        draftState.isMapEntitySelectionDisabled = isDisabled;
      })
    );
  }

  setActiveEditorModifying(modified: boolean) {
    this.update(
      produce((draftState: DetailedSiteEntitiesState) => {
        draftState.activeEditorModifying = modified;
      })
    );
  }

  setAddingMarkerForActiveEntity(inAdding: boolean) {
    this.update(
      produce((draftState: DetailedSiteEntitiesState) => {
        draftState.addingMarkerForActiveEntity = inAdding;
      })
    );
  }

  setActiveEditorDisabled(disabled: boolean) {
    this.update(
      produce((draftState: DetailedSiteEntitiesState) => {
        const activeEditor = draftState.activeEditor;
        if (activeEditor) {
          disabled ? activeEditor.disable() : activeEditor.enable();
        }
      })
    );
  }

  upsertDrawings(drawings: Drawing[]) {
    this.drawings.upsertMany(drawings);
  }

  setDrawingsLoading(isLoading: boolean = true) {
    this.update(
      produce((draftState: DetailedSiteEntitiesState) => {
        draftState.drawingsLoading = isLoading;
      })
    );
  }

  setActiveDrawingStyleProps(style: StoreDrawingStyleProps) {
    this.update(
      produce((draftState: DetailedSiteEntitiesState) => {
        draftState.activeDrawingStyleProps = style;
      })
    );
  }

  resetDrawingEditors() {
    this.update(
      produce((draftState: DetailedSiteEntitiesState) => {
        draftState.drawingEditors.forEach(drawingEditor => drawingEditor.editor$.dispose());
        draftState.drawingEditors = [];
      })
    );
  }

  addDrawingEditors(editors: DrawingEditor[]) {
    this.update(
      produce((draftState: DetailedSiteEntitiesState) => {
        draftState.drawingEditors = [...draftState.drawingEditors, ...editors];
      })
    );
  }

  removeEditorsByDrawingIds(drawingIds: string[]) {
    this.update(
      produce((draftState: DetailedSiteEntitiesState) => {
        const [editorsToDispose, editorsToKeep] = partition(draftState.drawingEditors, editor => drawingIds.includes(editor.drawingId));
        editorsToDispose.forEach(drawingEditor => drawingEditor.editor$.dispose());
        draftState.drawingEditors = editorsToKeep;
      })
    );
  }

  reset() {
    super.reset();
    this.analytics.reset();
    this.measurements.reset();
    this.annotations.reset();
    this.modelEdits.reset();
    this.layers.reset();
    this.groups.reset();
    this.drawings.reset();
    this.resetDrawingEditors();
  }
}
