/**
 * Activity Management Service
 * Manage Activities and Project Plan (GANTT)
 *
 * OpenAPI spec version: 1.9.5
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface GetActivityDependencyResponse {
  activityId?: string;
  dependencyType?: GetActivityDependencyResponse.DependencyTypeEnum;
  lag?: string;
  targetActivityId?: string;
}
export namespace GetActivityDependencyResponse {
  export type DependencyTypeEnum = 'SF' | 'FS' | 'SS' | 'FF';
  export const DependencyTypeEnum = {
    SF: 'SF' as DependencyTypeEnum,
    FS: 'FS' as DependencyTypeEnum,
    SS: 'SS' as DependencyTypeEnum,
    FF: 'FF' as DependencyTypeEnum
  };
}
