import { Pipe, PipeTransform } from '@angular/core';

import { isDefined } from '../utils/general';

@Pipe({
  name: 'isDefined'
})
export class IsDefinedPipe implements PipeTransform {
  transform(value: unknown): boolean {
    return isDefined(value);
  }
}
