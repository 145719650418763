import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { merge } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CreateTeamRequest } from '../../../../generated/tenant/model/createTeamRequest';
import { CreateUserForTenantRequest } from '../../../../generated/tenant/model/createUserForTenantRequest';
import { GetAllUsersForTenantResponse } from '../../../../generated/tenant/model/getAllUsersForTenantResponse';
import { UpdateTeamRequest } from '../../../../generated/tenant/model/updateTeamRequest';
import { UpdateUserForTenantRequest } from '../../../../generated/tenant/model/updateUserForTenantRequest';
import { REQUIRED_ACCESS_LEVEL_HEADER } from '../../../auth/state/auth.utils';
import PERMISSIONS from '../../../auth/state/permissions';
import { DetailedSiteQuery } from '../../../detailed-site/state/detailed-site.query';
import { DetailedSiteService } from '../../../detailed-site/state/detailed-site.service';
import { TenantService } from '../../../tenant/tenant.service';
import { AnalyticsService } from '../../services/analytics.service';
import { getServiceUrl } from '../../utils/backend-services';
import { Team, User } from './users-and-teams.model';
import { UsersAndTeamsStore } from './users-and-teams.store';

@Injectable({ providedIn: 'root' })
export class UsersAndTeamsService {
  constructor(
    private http: HttpClient,
    private store: UsersAndTeamsStore,
    private tenantService: TenantService,
    private siteService: DetailedSiteService,
    private siteQuery: DetailedSiteQuery,
    private analyticsService: AnalyticsService
  ) {}

  init() {
    return merge(
      this.http
        .get(`${getServiceUrl('tenant')}/sites/byTenantAdmin/users`, {
          headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.users.read }
        })
        .pipe(
          tap((response: GetAllUsersForTenantResponse) => {
            if (response && response.users) {
              this.store.addUsers(response.users);
            }
          })
        )
      // ,this.http
      // .get(`${getServiceUrl('tenant')}/teams`, {
      //   headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.teams.read }
      // })
      // .pipe(
      //   tap((response: GetAllTeamsResponse) => {
      //     if (response && response.teams) {
      //       this.store.addTeams(response.teams);
      //     }
      //   })
      // )
    );
  }

  createUser(user: User, shouldSendRegistrationEmail = true) {
    const { sites, teams, ...userInfo } = user;
    const sitesIds = sites.map(site => site.id);
    const teamsIds = teams.map(team => team.id);
    const request: CreateUserForTenantRequest = {
      ...userInfo,
      firstName: userInfo.firstName || '',
      lastName: userInfo.lastName || '',
      sitesIds,
      teamsIds
    };
    return this.http
      .post(`${getServiceUrl('tenant')}/sites/byTenantAdmin/users`, request, {
        params: { sendRegistrationEmail: '' + shouldSendRegistrationEmail },
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.users.create }
      })
      .pipe(
        map(({ id }: { id: string }) => {
          const newUser: User = { ...user, id };

          this.analyticsService.addUser(newUser, sitesIds, teamsIds);

          this.store.addUser(newUser);
          this.tenantService.addUser(this.toTenantUser(newUser));

          const currentSite = this.siteQuery.getSite();
          if (currentSite && sitesIds.includes(currentSite.id)) {
            this.siteService.updateSite({ users: [...currentSite.users, newUser] });
          }

          return newUser;
        })
      );
  }

  updateUser(user: User) {
    const { sites, teams, ...userInfo } = user;
    const sitesIds = sites.map(site => site.id);
    const teamsIds = teams.map(team => team.id);
    const request: UpdateUserForTenantRequest = {
      ...userInfo,
      firstName: userInfo.firstName || '',
      lastName: userInfo.lastName || '',
      sitesIds,
      teamsIds
    };
    return this.http
      .put(`${getServiceUrl('tenant')}/sites/byTenantAdmin/users/${user.id}`, request, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.users.update }
      })
      .pipe(
        map(() => {
          this.analyticsService.updateUser(request, sitesIds, teamsIds);

          this.store.updateUser(user);
          this.tenantService.updateUser(this.toTenantUser(user));

          const currentSite = this.siteQuery.getSite();
          if (currentSite && sitesIds.includes(currentSite.id)) {
            this.siteService.updateSite({ users: currentSite.users.map(u => (u.id === user.id ? user : u)) });
          }

          return user;
        })
      );
  }

  deleteUser(user: User) {
    return this.http
      .delete(`${getServiceUrl('tenant')}/sites/byTenantAdmin/users/${user.id}`, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.users.delete }
      })
      .pipe(
        tap(() => {
          this.analyticsService.deleteUser(user);

          this.store.deleteUser(user.id);
          this.tenantService.deleteUser(user);

          const currentSite = this.siteQuery.getSite();
          if (currentSite) {
            this.siteService.updateSite({ users: currentSite.users.filter(u => u.id !== user.id) });
          }
        })
      );
  }

  private toTenantUser(user: User) {
    const { sites, teams, ...rest } = user;
    return rest;
  }

  createTeam(team: Team) {
    const { users, ...teamInfo } = team;
    const request: CreateTeamRequest = {
      ...teamInfo,
      usersIds: users.map(user => user.id)
    };
    return this.http
      .post(`${getServiceUrl('tenant')}/teams`, request, { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.teams.create } })
      .pipe(
        map(({ id }: { id: string }) => {
          const newTeam = { ...team, id };
          this.store.addTeam(newTeam);
          return newTeam;
        })
      );
  }

  updateTeam(team: Team) {
    const { users, ...teamInfo } = team;
    const request: UpdateTeamRequest = {
      ...teamInfo,
      usersIds: users.map(user => user.id)
    };
    return this.http
      .put(`${getServiceUrl('tenant')}/teams/${team.id}`, request, {
        headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.teams.update }
      })
      .pipe(
        map(() => {
          this.store.updateTeam(team);
          return team;
        })
      );
  }

  deleteTeam(teamId: string) {
    return this.http
      .delete(`${getServiceUrl('tenant')}/teams/${teamId}`, { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: PERMISSIONS.teams.delete } })
      .pipe(tap(() => this.store.deleteTeam(teamId)));
  }
}
