import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, of } from 'rxjs';

import { GetNFIHintsResponse } from '../../../../generated/nms/model/models';
import { getServiceUrl } from '../../utils/backend-services';
import { FeatureHintsQuery } from './feature-hints.query';
import { FeatureHintData, FeatureHintPlace, FeatureHintsStore } from './feature-hints.store';

@Injectable({
  providedIn: 'root'
})
export class FeatureHintsService {
  constructor(private featureHintsStore: FeatureHintsStore, private featureHintsQuery: FeatureHintsQuery, private http: HttpClient) {}

  passHints(place: FeatureHintPlace) {
    const hints = this.featureHintsQuery.getHintsByPlace(place);
    const isKept = hints.some(h => h.kept);

    if (!isKept) {
      this.dismissHint(place);
    }
  }

  async dismissHint(place: FeatureHintPlace) {
    this.featureHintsStore.dismissHintsByPlace(place);
    await this.http.post(`${getServiceUrl('nms')}/nfiHints/dismissHint/${place}`, null).subscribe();
  }

  keepHint(place: FeatureHintPlace) {
    this.featureHintsStore.keepHintsByPlace(place);
  }

  disableHover(place: FeatureHintPlace) {
    this.featureHintsStore.disableHoverByPlace(place);
  }

  fetchHints() {
    return this.http.get<GetNFIHintsResponse>(`${getServiceUrl('nms')}/nfiHints/nfiHints`).pipe(
      map(data => {
        if (data) {
          return data.nfiHintsResponse.map(hint => ({
            showOnHover: true,
            kept: false,
            place: hint.place as FeatureHintPlace,
            parentPlace: hint.parentPlace,
            id: hint.id,
            tag: hint.tag,
            type: hint.type
          }));
        }

        return [] as FeatureHintData[];
      }),
      catchError(error => {
        console.error(error);
        return of([] as FeatureHintData[]);
      })
    );
  }

  init() {
    return this.fetchHints().pipe(
      map(newHints => {
        if (newHints) {
          this.featureHintsStore.initiateState(newHints);
        }
      })
    );
  }
}
