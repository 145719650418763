import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { differenceBy, partition } from 'lodash';
import { catchError, of, tap } from 'rxjs';
import { switchMap } from 'rxjs/operators';

import {
  CreateVolumeGridReportRequest,
  CreateVolumeGridReportResponse,
  GetVolumeGridReportsResponse,
  UpdateVolumeGridReportDataRequest,
  UpdateVolumeGridReportDataResponse
} from '../../../../../generated/file/model/models';
import { REQUIRED_ACCESS_LEVEL_HEADER } from '../../../../auth/state/auth.utils';
import { ResourceLinkType } from '../../../../shared/resource-links/resource-links.model';
import { ResourceLinksService } from '../../../../shared/resource-links/resource-links.service';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { getServiceUrl } from '../../../../shared/utils/backend-services';
import { isDefined } from '../../../../shared/utils/general';
import { BasicReportsService, LogoFiles } from '../basic-reports.service';
import {
  GridReportModel,
  GridReportType,
  ReportEntity,
  REPORTS_PERMISSIONS,
  ReportState,
  ReportType
} from '../detailed-site-reports.model';
import { DetailedSiteReportsQuery } from '../detailed-site-reports.query';
import { DetailedSiteReportsStore } from '../detailed-site-reports.store';

type GridHeatmapReport = ReportEntity<ReportType.ELEVATION_GRID_HEATMAP> | ReportEntity<ReportType.VOLUME_GRID_HEATMAP>;
type GridHeatmapReportType = ReportType.ELEVATION_GRID_HEATMAP | ReportType.VOLUME_GRID_HEATMAP;

@Injectable({ providedIn: 'root' })
export class GridHeatmapReportsService {
  constructor(
    private http: HttpClient,
    private reportsStore: DetailedSiteReportsStore,
    private reportsQuery: DetailedSiteReportsQuery,
    private resourceLinksService: ResourceLinksService,
    private analyticsService: AnalyticsService,
    private basicReportsService: BasicReportsService
  ) {}

  private getReportType(report: GridReportModel): GridHeatmapReportType {
    return report.gridReportType === GridReportType.EGHR ? ReportType.ELEVATION_GRID_HEATMAP : ReportType.VOLUME_GRID_HEATMAP;
  }

  fetchReport(siteId: string, reportId: string, reportType: GridHeatmapReportType) {
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: REPORTS_PERMISSIONS[reportType].read } };

    return this.http
      .get<GridReportModel>(`${getServiceUrl('file')}/volumeGridReports/sites/${siteId}/reports/${reportId}/data`, options)
      .pipe(
        tap(report => {
          if (isDefined(report)) {
            if (this.getReportType(report) === ReportType.ELEVATION_GRID_HEATMAP) {
              this.reportsStore.upsertElevationGridReports([{ ...report, type: ReportType.ELEVATION_GRID_HEATMAP }]);
            } else {
              this.reportsStore.upsertVolumeGridReports([{ ...report, type: ReportType.VOLUME_GRID_HEATMAP }]);
            }
          }
        }),
        catchError(error => {
          console.error('Error fetching grid report', error);
          return of(null);
        })
      );
  }

  handleFetchReports = (response: GetVolumeGridReportsResponse, siteId: string) => {
    const [volumeGridReports, elevationGridReports] = partition(
      response?.volumeGridReports || [],
      report => this.getReportType(report) === ReportType.VOLUME_GRID_HEATMAP
    );

    this.handleFetchVolumeGridReports(volumeGridReports, siteId);
    this.handleFetchElevationGridReports(elevationGridReports, siteId);
  };

  private handleFetchVolumeGridReports = (newReports: GridReportModel[], siteId: string) => {
    const existingReports = this.reportsQuery.getAllVolumeGridReports();
    if (!isDefined(newReports)) {
      this.basicReportsService.removeReportsOfTypeFromStore(
        existingReports.map(report => report.id),
        ReportType.VOLUME_GRID_HEATMAP
      );
      return;
    }

    // Find removed reports and update resource links
    const removedReports = differenceBy(existingReports, newReports, 'id');
    this.resourceLinksService.removeResourcesOfType(
      removedReports.map(report => report.id),
      ResourceLinkType.REPORT
    );

    this.reportsStore.upsertVolumeGridReports(newReports.map(report => ({ ...report, type: ReportType.VOLUME_GRID_HEATMAP, siteId })));
  };

  private handleFetchElevationGridReports = (newReports: GridReportModel[], siteId: string) => {
    const existingReports = this.reportsQuery.getAllElevationGridReports();
    if (!isDefined(newReports)) {
      this.basicReportsService.removeReportsOfTypeFromStore(
        existingReports.map(report => report.id),
        ReportType.ELEVATION_GRID_HEATMAP
      );
      return;
    }

    // Find removed reports and update resource links
    const removedReports = differenceBy(existingReports, newReports, 'id');
    this.resourceLinksService.removeResourcesOfType(
      removedReports.map(report => report.id),
      ResourceLinkType.REPORT
    );

    this.reportsStore.upsertElevationGridReports(
      newReports.map(report => ({ ...report, type: ReportType.ELEVATION_GRID_HEATMAP, siteId }))
    );
  };

  generateReport(request: CreateVolumeGridReportRequest, logoFiles: LogoFiles) {
    const type = this.getReportType(request);
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: REPORTS_PERMISSIONS[type].create } };
    return this.http.post(`${getServiceUrl('file')}/volumeGridReports/generateVolumeGridReport`, request, options).pipe(
      tap((response: CreateVolumeGridReportResponse) => {
        if (response) {
          const report: GridHeatmapReport = {
            ...request,
            id: response.reportId,
            type,
            creationTime: new Date(),
            lastModifiedTime: new Date(),
            state: ReportState.PROCESSING
          };
          if (report.type === ReportType.ELEVATION_GRID_HEATMAP) {
            this.reportsStore.upsertElevationGridReports([report]);
          } else {
            this.reportsStore.upsertVolumeGridReports([report]);
          }
          this.analyticsService.generateReport(report);
        }
      }),
      switchMap((response: CreateVolumeGridReportResponse) => {
        if (response) {
          const logoUrls = {
            consultantLogo: response.consultantLogoURL,
            customerLogo: response.customerLogoURL,
            signatureLogo: response.signatureLogoURL
          };
          return this.basicReportsService.updateReportLogos(logoUrls, logoFiles);
        }

        return of(null);
      })
    );
  }

  updateReport(siteId: string, reportId: string, request: UpdateVolumeGridReportDataRequest, logoFiles: LogoFiles) {
    const type = this.getReportType(request);
    const options = { headers: { [REQUIRED_ACCESS_LEVEL_HEADER]: REPORTS_PERMISSIONS[type].update } };

    return this.http.put(`${getServiceUrl('file')}/volumeGridReports/sites/${siteId}/reports/${reportId}`, request, options).pipe(
      tap((response: UpdateVolumeGridReportDataResponse) => {
        if (response) {
          const report: GridHeatmapReport = {
            ...request,
            id: response.reportId,
            type
          };
          if (report.type === ReportType.ELEVATION_GRID_HEATMAP) {
            this.reportsStore.upsertElevationGridReports([report]);
          } else {
            this.reportsStore.upsertVolumeGridReports([report]);
          }
          this.analyticsService.editReport(report);
        }
      }),
      switchMap((response: UpdateVolumeGridReportDataResponse) => {
        if (response) {
          const logoUrls = {
            consultantLogo: logoFiles.consultantLogo ? response.putConsultantLogoURL : response.deleteConsultantLogoURL,
            customerLogo: logoFiles.customerLogo ? response.putCustomerLogoURL : response.deleteCustomerLogoURL,
            signatureLogo: logoFiles.signatureLogo ? response.putSignatureLogoURL : response.deleteSignatureLogoURL
          };
          return this.basicReportsService.updateReportLogos(logoUrls, logoFiles, true);
        }

        return of(null);
      }),
      switchMap(() => this.fetchReport(siteId, reportId, type))
    );
  }

  deleteReport(report: GridHeatmapReport) {
    const url = `${getServiceUrl('file')}/volumeGridReports/sites/${report.siteId}/reports/${report.id}`;

    return this.basicReportsService.deleteReport(report, url);
  }
}
