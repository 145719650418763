import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

import { environment } from '../../../environments/environment';

const SEPERATOR = ' :: ';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  constructor(private titleService: Title) {}

  init() {
    this.titleService.setTitle(environment.whitelabel.appName);
  }

  set(str: string, override = false) {
    if (override) {
      this.titleService.setTitle(str);
    } else {
      this.titleService.setTitle(str + SEPERATOR + environment.whitelabel.appName);
    }
  }

  reset() {
    this.init();
  }
}
