import moment, { Moment } from 'moment';

import { TaskResponse } from '../../../generated/fms/model/taskResponse';
import { MlGcpStateEnum } from '../../detailed-site/state/detailed-site.model';
import { isDefined } from './general';
import { roundTo } from './math';

export function capitalize([firstLetter, ...rest]: string) {
  return [firstLetter.toLocaleUpperCase(), ...rest.join('').toLocaleLowerCase()].join('');
}

const FILE_SIZES = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
export function formatFileSize(bytes: number, accuracy?: number) {
  if (bytes === 0) {
    return '0 B';
  } else if (!bytes) {
    return null;
  }

  const k = 1024;

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return roundTo(bytes / Math.pow(k, i), accuracy) + ' ' + FILE_SIZES[i];
}

export function getTaskStateIcon(state: TaskResponse.StateEnum) {
  switch (state) {
    case TaskResponse.StateEnum.WAITINGFORGCP:
    case TaskResponse.StateEnum.UPLOADEDBYOPERATOR:
      return 'pending';

    case TaskResponse.StateEnum.SUCCESS:
      return 'do';

    case TaskResponse.StateEnum.FAILEDLINK:
    case TaskResponse.StateEnum.FAILEDDPC:
    case TaskResponse.StateEnum.FAILEDAUTOGEOREF:
    case TaskResponse.StateEnum.FAILEDSBA:
      return 'task-failure';

    default:
      return 'loading-spinner';
  }
}

export function getTaskStateLabel(task: TaskResponse): string {
  if (!task) {
    return '';
  }

  switch (task.state) {
    case TaskResponse.StateEnum.SBAINPROGRESS:
      return $localize`:@@utils.formatting.taskStateLabel.calculatingAccuracy:Calculating accuracy`;

    case TaskResponse.StateEnum.WAITINGFORGCP: {
      if (task.mlgcpState === MlGcpStateEnum.FAILED) {
        return $localize`:@@utils.formatting.taskStateLabel.failedAIGCPMarking:Failed AI GCP marking`;
      }

      return task.georefMethod === TaskResponse.GeorefMethodEnum.MANUAL
        ? $localize`:@@utils.formatting.taskStateLabel.pendingMonumentsMarking:Pending monuments marking`
        : $localize`:@@utils.formatting.taskStateLabel.pendingGCPMarking:Pending GCP marking`;
    }

    case TaskResponse.StateEnum.UPLOADINGBYOPERATOR:
      return $localize`:@@utils.formatting.taskStateLabel.uploadingByOperator:Uploading by operator`;

    case TaskResponse.StateEnum.UPLOADEDBYOPERATOR:
      return $localize`:@@utils.formatting.taskStateLabel.pendingProcessCompletion:Pending process completion`;

    case TaskResponse.StateEnum.MLGCPINPROGRESS:
      return $localize`:@@utils.formatting.taskStateLabel.AIMarkingGCP:AI marking GCP`;

    case TaskResponse.StateEnum.GENERATING3DMODELS:
      return $localize`:@@utils.formatting.taskStateLabel.generating3DModels:Generating 3D models`;

    case TaskResponse.StateEnum.LINKING:
    case TaskResponse.StateEnum.AUTOGCPINPROGRESS:
      return $localize`:@@utils.formatting.taskStateLabel.preProcessingModel:Pre-processing model`;

    case TaskResponse.StateEnum.UPLOADING:
      return $localize`:@@utils.formatting.taskStateLabel.uploading:Uploading`;

    case TaskResponse.StateEnum.SUCCESS:
      return $localize`:@@utils.formatting.taskStateLabel.done:Done`;

    case TaskResponse.StateEnum.FAILEDLINK:
      return $localize`:@@utils.formatting.taskStateLabel.failedPreProcessingModel:Failed pre-processing model`;

    case TaskResponse.StateEnum.FAILEDDPC:
      return $localize`:@@utils.formatting.taskStateLabel.failedGenerating3DModels:Failed generating 3D models`;

    case TaskResponse.StateEnum.FAILEDAUTOGEOREF:
      return $localize`:@@utils.formatting.taskStateLabel.failedAutoGeoReferencing:Failed auto geo-referencing`;

    case TaskResponse.StateEnum.FAILEDSBA:
      return $localize`:@@utils.formatting.taskStateLabel.failedGCPAccuracyCheck:Failed GCP accuracy check`;

    default:
      return '';
  }
}

export function formatName(nameObj: { firstName?: string; lastName?: string; email?: string; cameFrom?: string }) {
  if (!isDefined(nameObj)) {
    return '';
  }

  const { firstName, lastName, email, cameFrom } = nameObj;
  let name = '';
  if (firstName && lastName) {
    name = `${firstName} ${lastName}`;
  } else if (firstName || lastName) {
    name = firstName || lastName;
  } else if (email) {
    name = email;
    if (isDefined(cameFrom)) {
      name += ` (${capitalize(cameFrom)})`;
    }
  }

  return name;
}

export function decodeUnicodeString(s: string) {
  return decodeURIComponent(JSON.parse('"' + s.replace(/\"/g, '\\"') + '"'));
}

export const MATERIAL_COLORS = [
  '#f44336',
  '#e91e63',
  '#b388ff',
  '#9c27b0',
  '#673ab7',
  '#3f51b5',
  '#2196f3',
  '#03a9f4',
  '#00bcd4',
  '#009688',
  '#8bc34a',
  '#cddc39',
  '#ffeb3b',
  '#ffc107',
  '#ff9800',
  '#ff5722',
  '#795548',
  '#eeeeee',
  '#9e9e9e',
  '#607d8b',
  '#000000'
];

export function getAvatar(name: string, size = 24) {
  if (!name) {
    name = 'Unknown';
  }

  const backgroundColorIndex = name.split('').reduce((sum, c) => sum + c.charCodeAt(0), 0) % MATERIAL_COLORS.length;
  const color = MATERIAL_COLORS[backgroundColorIndex].slice(1); // Remove # char
  return `https://ui-avatars.com/api/?name=${name}&rounded=true&size=${size}&background=${color}&color=fff`;
}

const measuringCanvas = document.createElement('canvas');
export function getTextWidth(text: string, font: string) {
  const context = measuringCanvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

/**
 * @param inUTC is true by default - to remove timezone from date
 */
export function fromMomentToDate(date: Moment, inUTC = true) {
  if (!date?.isValid()) {
    return null;
  }
  return inUTC ? moment.utc(date.format('YYYY-MM-DD')).toDate() : moment(date.format('YYYY-MM-DD')).toDate();
}
