import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Cartesian3 } from '@datumate/angular-cesium';
import { capitalize } from 'lodash';
import moment from 'moment';

import { DrawingStyleProps } from '../../../../generated/mms/model/drawingStyleProps';
import { fileExtension } from '../../../shared/utils/file-utils';
import { isDefined } from '../../../shared/utils/general';
import {
  Annotation,
  AnnotationFile,
  AnnotationPriority,
  AnnotationStatus,
  DrawingType
} from '../../state/detailed-site-entities/detailed-site-entities.model';
import { DetailedSiteEntitiesQuery } from '../../state/detailed-site-entities/detailed-site-entities.query';
import { AnnotationFilePreviewDialogComponent } from '../annotation-file-preview-dialog/annotation-file-preview-dialog.component';

export enum PreviewableFileTypes {
  pdf = 'document',
  jpg = 'image',
  jpeg = 'image',
  png = 'image',
  gif = 'image',
  bmp = 'image',
  avi = 'video',
  mp4 = 'video'
}

enum AnnotationType {
  COMMISSIONING = 'Commissioning',
  ENVIRONMENTAL = 'Environmental',
  QUALITY = 'Quality',
  SAFETY = 'Safety',
  WARRANTY = 'Warranty',
  WORK_TO_COMPLETE = 'Work to Complete'
}

enum CommissioningSubtype {
  COMMISSIONING = 'Commissioning'
}

enum EnvironmentalSubtype {
  AIR_QUALITY = 'Air Quality',
  CULTURAL_HERITAGE = 'Cultural Heritage',
  HAZARDOUS_MATERIALS = 'Hazardous Materials',
  NOISE = 'Noise',
  SPILL = 'Spill',
  STORM_WATER = 'Storm Water',
  VEGETATION = 'Vegetation',
  WASTE_MANAGEMENT = 'Waste Management',
  WILDLIFE = 'Wildlife'
}

enum QualitySubtype {
  CORRECTIVE_ACTION = 'Corrective Action',
  DEFICIENCY = 'Deficiency',
  NON_CONFORMANCE = 'Non-Conformance',
  PRE_PUNCH = 'Pre-Punch'
}

enum SafetySubtype {
  CORRECTIVE_ACTION = 'Corrective Action',
  NEAR_MISS = 'Near Miss',
  PREVENTATIVE_ACTION = 'Preventative Action',
  SAFETY_HAZARD = 'Safety Hazard',
  SAFETY_NOTICE = 'Safety Notice',
  SAFETY_VIOLATION = 'Safety Violation'
}

enum WarrantySubtype {
  SERVICE_CALL = 'Service Call'
}

enum WorkToCompleteSubtype {
  WORK_TO_COMPLETE = 'Work to Complete'
}

export function getAnnotationCategories() {
  const categories: { type: string; subType: string }[] = [];
  Object.values(CommissioningSubtype).forEach(subType => categories.push({ type: AnnotationType.COMMISSIONING, subType }));
  Object.values(EnvironmentalSubtype).forEach(subType => categories.push({ type: AnnotationType.ENVIRONMENTAL, subType }));
  Object.values(QualitySubtype).forEach(subType => categories.push({ type: AnnotationType.QUALITY, subType }));
  Object.values(SafetySubtype).forEach(subType => categories.push({ type: AnnotationType.SAFETY, subType }));
  Object.values(WarrantySubtype).forEach(subType => categories.push({ type: AnnotationType.WARRANTY, subType }));
  Object.values(WorkToCompleteSubtype).forEach(subType => categories.push({ type: AnnotationType.WORK_TO_COMPLETE, subType }));
  return categories;
}

export const ANNOTATION_PRIORITY_TEXT = Object.freeze({
  [AnnotationPriority.CRITICAL]: $localize`:@@detailedSite.detailsBox.annotationDetails.priorityCritical:Critical`,
  [AnnotationPriority.HIGH]: $localize`:@@detailedSite.detailsBox.annotationDetails.priorityHigh:High`,
  [AnnotationPriority.MEDIUM]: $localize`:@@detailedSite.detailsBox.annotationDetails.priorityMedium:Medium`,
  [AnnotationPriority.LOW]: $localize`:@@detailedSite.detailsBox.annotationDetails.priorityLow:Low`
});

export const ANNOTATION_STATUS_TEXT = Object.freeze({
  [AnnotationStatus.CANCELED]: $localize`:@@detailedSite.detailsBox.annotationDetails.statusCancelled:Cancelled`,
  [AnnotationStatus.DONE]: $localize`:@@detailedSite.detailsBox.annotationDetails.statusDone:Done`,
  [AnnotationStatus.ONHOLD]: $localize`:@@detailedSite.detailsBox.annotationDetails.statusOnHold:On hold`,
  [AnnotationStatus.OPEN]: $localize`:@@detailedSite.detailsBox.annotationDetails.statusOpen:Open`
});

let newDrawingIdsCount = 0;
const NEW_DRAWING_ID_PREFIX = 'new-drawing-';

export function generateNewDrawingId() {
  return `${NEW_DRAWING_ID_PREFIX}${++newDrawingIdsCount}`;
}

export function isNewDrawing(id: string) {
  return id?.startsWith(NEW_DRAWING_ID_PREFIX);
}

export function isDrawingPositionsValid(type: DrawingType, positions: Cartesian3[]) {
  if (positions) {
    switch (type) {
      case DrawingType.ARROW:
        return positions.length === 2;
      case DrawingType.POLYLINE:
        return positions.length >= 2;
      case DrawingType.POLYGON:
        return positions.length > 2;
      default:
        return false;
    }
  }
  return false;
}

export const DEFAULT_DRAWING_STYLE_PROPS = {
  arrowLineWidthPx: 20,
  fillColor: '#f44336', // red from MATERIAL_COLORS palette
  fillOpacityPercent: 10,
  lineColor: '#f44336', // red from MATERIAL_COLORS palette
  lineWidthPx: 3
};

export enum DrawingStylePropName {
  FILL_COLOR = 'Fill Color',
  FILL_OPACITY = 'Fill Opacity',
  LINE_COLOR = 'Line Color',
  LINE_WIDTH = 'Line Width'
}

const DRAWING_NAME_DIVIDER = '-';

const SNAPSHOT_NAME_PREFIX = $localize`:@@detailedSite.measurementDetailsBox.snapshotFileName:snapshot`;
const SNAPSHOT_FILE_SUFFIX = '.jpg';

@Injectable({
  providedIn: 'root'
})
export class AnnotationUtilsService {
  constructor(private entitiesQuery: DetailedSiteEntitiesQuery, private dialog: MatDialog) {}

  onViewFile(file: AnnotationFile) {
    if (!this.isFileViewable(file)) {
      return;
    }

    this.dialog.open(AnnotationFilePreviewDialogComponent, {
      width: '90%',
      height: '90%',
      data: { file }
    });
  }

  isFileViewable(file: AnnotationFile) {
    if (!isDefined(file?.name)) {
      return false;
    }

    const extension = fileExtension(file.name);
    return Object.keys(PreviewableFileTypes).includes(extension);
  }

  generateSnapshotName(annotation: Annotation) {
    const todayDateString = moment().format('YYYY-MM-DD');
    let name = SNAPSHOT_NAME_PREFIX + ' ' + todayDateString;

    const todaySnapshots = annotation.attachments?.filter(attachment => attachment.name.startsWith(name));

    if (isDefined(todaySnapshots)) {
      const existingCounters = todaySnapshots.map(attachment => {
        const snapshotName = attachment.name;
        const lastIndexStart = snapshotName.indexOf('(');
        const lastIndexEnd = snapshotName.indexOf(')');
        return lastIndexStart > 0 ? parseInt(snapshotName.slice(lastIndexStart + 1, lastIndexEnd)) || 0 : 0;
      });

      name += ` (${Math.max(...existingCounters) + 1})`;
    }

    return name + SNAPSHOT_FILE_SUFFIX;
  }

  generateDrawingName(type: DrawingType) {
    const counter = this.calcDrawingNameCounter(type);
    return `${capitalize(type)}${DRAWING_NAME_DIVIDER}${counter}`;
  }

  private calcDrawingNameCounter(type: DrawingType) {
    const drawings = this.entitiesQuery.getDrawings()?.filter(drawing => drawing.type === type && isDefined(drawing.name));

    if (!isDefined(drawings)) {
      return 1;
    }
    const existingCounters = drawings.map(drawing => {
      const name = drawing.name;
      const lastIndex = name.lastIndexOf(DRAWING_NAME_DIVIDER);
      return lastIndex > 0 ? parseInt(name.slice(lastIndex + 1)) || 0 : 0;
    });
    return Math.max(...existingCounters) + 1;
  }

  getDrawingStyle(type: DrawingType): DrawingStyleProps {
    const activeStyleProps = this.entitiesQuery.getActiveDrawingStyleProps();

    const style: DrawingStyleProps = {
      lineColor: activeStyleProps.lineColor,
      lineWidthPx: type === DrawingType.ARROW ? activeStyleProps.arrowLineWidthPx : activeStyleProps.lineWidthPx
    };
    if (type === DrawingType.POLYGON) {
      style.fillColor = activeStyleProps.fillColor;
      style.fillOpacityPercent = activeStyleProps.fillOpacityPercent;
    }
    return style;
  }
}
