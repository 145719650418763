import { AbstractControl, ValidatorFn } from '@angular/forms';
import saveAs from 'file-saver';

export const fileExtensionFormValidator: (extension?: string, ...restExtensions: string[]) => ValidatorFn =
  (extension: string, ...restExtensions: string[]) =>
  (control: AbstractControl) => {
    if (!extension) {
      return null;
    }

    const extensions = [extension, ...restExtensions];
    return extensions &&
      extensions.length > 0 &&
      control?.value &&
      !extensions.some(ext => control.value.name.toLowerCase().endsWith('.' + ext))
      ? { invalidFileExtension: true }
      : null;
  };

/**
 * Trim and remove "invalid character" sign for CSV headers and body
 *
 * @param value CSV cell string
 * @returns cleaned value string
 */
export function cleanString(value: string) {
  return (value || '').trim().replace(/\uFFFD/g, '');
}

export const fileExtension = (fileName: string) => {
  const extensionFirstIndex = fileName.lastIndexOf('.') + 1;
  return extensionFirstIndex > 0 ? fileName.slice(extensionFirstIndex).toLowerCase() : '';
};

export const fileNameWithoutExtension = (fileName: string) => {
  const extensionFirstIndex = fileName.lastIndexOf('.');
  return extensionFirstIndex > 0 ? fileName.slice(0, extensionFirstIndex) : fileName;
};

const BOM = '\uFEFF';
const CSV_FILE_TYPE = 'text/csv;charset=utf-8';
const CSV_SUFFIX = '.csv';
export function saveCSV(content: string, fileName: string) {
  saveAs(new Blob([BOM + content], { type: CSV_FILE_TYPE }), fileName.endsWith(CSV_SUFFIX) ? fileName : fileName + CSV_SUFFIX);
}
