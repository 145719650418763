import { Injectable } from '@angular/core';
import { EntityDirtyCheckPlugin, Query, QueryEntity } from '@datorama/akita';
import { AcNotification, ActionType } from '@datumate/angular-cesium';
import { booleanContains, booleanCrosses, booleanOverlap, booleanPointInPolygon, lineString, point, polygon } from '@turf/turf';
import { isEqual, xorWith } from 'lodash';
import { BehaviorSubject, combineLatest, merge, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, mergeMap, switchMap } from 'rxjs/operators';

import { ResourceType } from '../../../shared/share-resource-dialog/share-resource-dialog.component';
import { isDefined } from '../../../shared/utils/general';
import { GeoUtils } from '../../../shared/utils/geo';
import { GeoJsonProperties } from '../../services/calc-services';
import { DeltaElevationVizOptions, positionsChanged } from '../detailed-site.utils';
import {
  AnalyticType,
  AnnotationType,
  BaseSurfaceType,
  CrossSectionPoint,
  DEFAULT_GROUP_LAYER_ID,
  DEFAULT_LAYER_COLOR,
  EntityType,
  EntityTypeWithBaseSurface,
  EntityTypeWithGeoJson,
  LATEST_GROUP_SELECTED_STORAGE_KEY,
  LATEST_LAYER_SELECTED_STORAGE_KEY,
  LineType,
  MapEntity,
  MeasurementType,
  ModelEdit,
  ModelEditType,
  PointType,
  PolygonType,
  SourceModel
} from './detailed-site-entities.model';
import {
  AnalyticState,
  AnnotationState,
  DetailedSiteEntitiesState,
  DetailedSiteEntitiesStore,
  MeasurementState,
  ModelEditState
} from './detailed-site-entities.store';

@Injectable({ providedIn: 'root' })
export class DetailedSiteEntitiesQuery extends Query<DetailedSiteEntitiesState> {
  activeEntityId$ = this.select(state => state.activeEntityId);
  activeEntity$ = this.select(['activeEntityId', 'activeEntityType']).pipe(
    switchMap(({ activeEntityId, activeEntityType }) => this.selectEntity(activeEntityId, activeEntityType))
  );

  inEditMode$ = this.select(state => !!state.activeEntityId);
  activeEditor$ = this.select(state => state.activeEditor);
  activeEditorModifying$ = this.select(state => state.activeEditorModifying);
  addingMarkerForActiveEntity$ = this.select(state => state.addingMarkerForActiveEntity);
  showMapEditorEndDrawingButtons$ = this.select(state => state.showMapEditorEndDrawingButtons);
  showDrawingTools$ = this.select(state => state.showDrawingTools);
  mapVisualizationLoading$ = this.select(state => state.mapVisualizationLoading).pipe(distinctUntilChanged());
  calculationLoading$ = this.select(state => state.calculationLoading).pipe(distinctUntilChanged());
  deltaCalculationLoading$ = this.select(state => state.deltaCalculationLoading).pipe(distinctUntilChanged());

  analyticsQuery = new QueryEntity(this.store.analytics);
  analytics$ = this.analyticsQuery.selectAll();
  measurementsQuery = new QueryEntity(this.store.measurements);
  measurements$ = this.measurementsQuery.selectAll();
  annotationsQuery = new QueryEntity(this.store.annotations);
  annotations$ = this.annotationsQuery.selectAll();
  modelEditsQuery = new QueryEntity(this.store.modelEdits);
  modelEdits$ = this.modelEditsQuery.selectAll();

  hasPinnedEntities$ = combineLatest([
    this.analyticsQuery.selectCount(entity => entity.pinned),
    this.measurementsQuery.selectCount(entity => entity.pinned),
    this.annotationsQuery.selectCount(entity => entity.pinned),
    this.modelEditsQuery.selectCount(entity => entity.pinned)
  ]).pipe(
    map(([analytics, measurements, annotations, modelEdits]) => analytics > 0 || measurements > 0 || annotations > 0 || modelEdits > 0),
    distinctUntilChanged()
  );

  layersQuery = new QueryEntity(this.store.layers);
  layers$ = this.layersQuery.selectAll();
  groupsQuery = new QueryEntity(this.store.groups);
  groups$ = this.groupsQuery.selectAll();

  drawingsQuery = new QueryEntity(this.store.drawings);
  drawings$ = this.drawingsQuery.selectAll({ filterBy: [({ markedForDelete }) => !markedForDelete] });
  activeDrawing$ = this.drawingsQuery.selectActive();

  constructor(protected store: DetailedSiteEntitiesStore) {
    super(store);
  }

  getSiteId() {
    return this.getValue().siteId;
  }

  getSiteCoordinateSystem() {
    return this.getValue().siteCoordinateSystem;
  }

  getActiveEntityId() {
    return this.getValue().activeEntityId;
  }

  getActiveEntityType() {
    return this.getValue().activeEntityType;
  }

  private getQueryByType(type: EntityType): QueryEntity<AnalyticState | MeasurementState | AnnotationState | ModelEditState> {
    if (type in AnalyticType) {
      return this.analyticsQuery;
    } else if (type in MeasurementType) {
      return this.measurementsQuery;
    } else if (type in AnnotationType) {
      return this.annotationsQuery;
    } else if (type in ModelEditType) {
      return this.modelEditsQuery;
    }
  }

  getTaskModelEdits(taskId: string) {
    return this.modelEditsQuery.getAll({
      filterBy: (edit: ModelEdit) => edit.positionsElevation && taskId in edit.positionsElevation
    });
  }

  getAllAnalytics() {
    return this.analyticsQuery.getAll();
  }

  getAllMeasurements() {
    return this.measurementsQuery.getAll();
  }

  getAllAnnotations() {
    return this.annotationsQuery.getAll();
  }

  getAllModelEdits() {
    return this.modelEditsQuery.getAll();
  }

  getAllEntitiesCount() {
    return (
      this.analyticsQuery.getCount() +
      this.measurementsQuery.getCount() +
      this.annotationsQuery.getCount() +
      this.modelEditsQuery.getCount()
    );
  }

  getAllLayers() {
    return this.layersQuery.getAll();
  }

  getLayer(layerId: string) {
    return this.layersQuery.getEntity(layerId);
  }

  getLayerColor(layerId: string) {
    const layer = this.getLayer(layerId);
    if (layer) {
      return layer.color;
    }

    return DEFAULT_LAYER_COLOR;
  }

  getAllGroups() {
    return this.groupsQuery.getAll();
  }

  getGroup(groupId: string) {
    return this.groupsQuery.getEntity(groupId);
  }

  inferDefaultGroupLayerId(entity: Partial<MapEntity>, field: 'groupId' | 'layerId') {
    if (entity.type in ModelEditType) {
      return null;
    }

    let id: string;
    if (entity[field] !== null && entity[field] !== undefined) {
      id = entity[field];
    } else {
      const storageKey = field === 'groupId' ? LATEST_GROUP_SELECTED_STORAGE_KEY : LATEST_LAYER_SELECTED_STORAGE_KEY;
      id = localStorage.getItem(storageKey);

      // make sure it exists, if not, continue infering and remove from local storage.
      const obj = field === 'groupId' ? this.getGroup(id) : this.getLayer(id);
      if (!obj) {
        id = null;
        localStorage.setItem(storageKey, DEFAULT_GROUP_LAYER_ID);
      }
    }

    if (!id) {
      id = DEFAULT_GROUP_LAYER_ID;
    }

    return id;
  }

  getCrossSectionNotification$(): Observable<AcNotification> {
    return this.select(store => store.crossSectionPoint).pipe(
      filter(p => !!p),
      map(crossPoint => {
        return {
          actionType: ActionType.ADD_UPDATE,
          id: crossPoint.id,
          entity: new CrossSectionPoint(crossPoint)
        } as AcNotification;
      })
    );
  }

  private getMapDeleteNotificationsObserver(entityType: 'point' | 'line' | 'polygon' | 'modelEdit') {
    let deletedSubject: BehaviorSubject<string>;
    if (entityType === 'point') {
      deletedSubject = this.store.deletedMarkers$;
    } else if (entityType === 'line') {
      deletedSubject = this.store.deletedLines$;
    } else if (entityType === 'polygon') {
      deletedSubject = this.store.deletedPolygons$;
    } else if (entityType === 'modelEdit') {
      deletedSubject = this.store.deletedModelEdits$;
    }

    return deletedSubject.pipe(
      filter(id => id !== undefined),
      map(
        id =>
          ({
            actionType: ActionType.DELETE,
            id
          } as AcNotification)
      )
    );
  }

  private getMapCreateNotificationsObserver(
    entities$: Observable<MapEntity[]>,
    entityType: 'point' | 'line' | 'polygon' | 'modelEdit'
  ): Observable<AcNotification> {
    const typeFilter = (entity: MapEntity) => {
      if (entityType === 'point') {
        return entity.type in PointType;
      } else if (entityType === 'line') {
        return entity.type in LineType;
      } else if (entityType === 'polygon') {
        return entity.type in PolygonType;
      } else if (entityType === 'modelEdit') {
        return entity.type in ModelEditType;
      }
    };
    return entities$.pipe(
      filter(entities => !!entities.length),
      mergeMap(entity => entity),
      filter(entity => entity && typeFilter(entity)),
      map(
        entity =>
          ({
            actionType: ActionType.ADD_UPDATE,
            id: entity.id,
            entity: {
              ...entity
            }
          } as AcNotification)
      )
    );
  }

  createMapNotificationsObserver(
    entities$: Observable<MapEntity[]>,
    entityType: 'point' | 'line' | 'polygon' | 'modelEdit'
  ): Observable<AcNotification> {
    return merge(this.getMapDeleteNotificationsObserver(entityType), this.getMapCreateNotificationsObserver(entities$, entityType));
  }

  getActiveEntity() {
    const id = this.getActiveEntityId();
    const type = this.getActiveEntityType();
    return this.getEntity(id, type);
  }

  getActiveEditor() {
    return this.getValue().activeEditor;
  }

  getIsMapEntitySelectionDisabled() {
    return this.getValue().isMapEntitySelectionDisabled;
  }

  getAddingMarkerForActiveEntity() {
    return this.getValue().addingMarkerForActiveEntity;
  }

  getEntity(id: string, type: EntityType) {
    return this.getQueryByType(type)?.getEntity(id);
  }

  selectEntity(id: string, type: EntityType): Observable<MapEntity> {
    return this.getQueryByType(type)?.selectEntity(id) || of(null);
  }

  isEntityPinned(id: string, type: EntityType) {
    const entity = this.getEntity(id, type);
    return entity && entity.pinned;
  }

  isModelEditEnabled(id: string, taskId: string) {
    const edit = this.modelEditsQuery.getEntity(id);
    if (!edit) {
      return false;
    }

    return edit.positionsElevation && taskId in edit.positionsElevation;
  }

  isEntityOnModelEdit(entity: MapEntity) {
    if (entity.type in ModelEditType || entity.type in AnnotationType) {
      return false;
    }

    const excludeTypes: EntityType[] = [MeasurementType.ANGLE];
    if (excludeTypes.includes(entity.type)) {
      return false;
    }

    if (entity.sourceModel !== SourceModel.DTM) {
      return false;
    }

    const modelEdits = this.modelEditsQuery.getAll();
    const editPolygons = modelEdits.map(edit => {
      const positions = edit.positions.map(p => GeoUtils.cartesian3ToDegArray(p));
      return polygon([[...positions, positions[0]]]);
    });

    // Check if entity overlaps with any model edits
    if (entity.type in PointType) {
      const positions = GeoUtils.cartesian3ToDegArray(entity.positions[0]);
      return editPolygons.some(polygon => booleanPointInPolygon(point(positions), polygon));
    } else if (entity.type in LineType) {
      const positions = entity.positions.map(p => GeoUtils.cartesian3ToDegArray(p));
      const entityPolyline = lineString(positions);
      return editPolygons.some(polygon => booleanCrosses(entityPolyline, polygon) || booleanContains(polygon, entityPolyline));
    } else if (entity.type in PolygonType) {
      const positions = entity.positions.map(p => GeoUtils.cartesian3ToDegArray(p));
      const entityPolygon = polygon([[...positions, positions[0]]]);
      return editPolygons.some(
        polygon =>
          booleanOverlap(entityPolygon, polygon) || booleanContains(entityPolygon, polygon) || booleanContains(polygon, entityPolygon)
      );
    }

    return false;
  }

  getPinnedEntities() {
    return [
      ...this.analyticsQuery.getAll({ filterBy: entity => entity.pinned }),
      ...this.measurementsQuery.getAll({ filterBy: entity => entity.pinned }),
      ...this.annotationsQuery.getAll({ filterBy: entity => entity.pinned }),
      ...this.modelEditsQuery.getAll({ filterBy: entity => entity.pinned })
    ];
  }

  entityDirtyCheck(id: string, type: EntityType) {
    const query = this.getQueryByType(type);
    const comparator = this.dirtyCheckComparator;
    const dirtyCheck = new EntityDirtyCheckPlugin(query, { entityIds: id, comparator });
    dirtyCheck.setHead();

    return dirtyCheck;
  }

  private dirtyCheckComparator = (head: MapEntity, current: MapEntity) => {
    if (!(head.type in AnnotationType)) {
      if (!head.calcResult || !current.calcResult) {
        return true;
      }

      if (head.type in AnalyticType) {
        // Check if number of calc results has been changed
        if (head.calcResult.length !== current.calcResult.length) {
          return true;
        }
        // Check if tasks and designs list of calc results has been changed
        const diff = xorWith(head.calcResult, current.calcResult, (res1, res2) => res1.type === res2.type && res1.id === res2.id);
        if (diff.length > 0) {
          return true;
        }
      }

      for (const headRes of head.calcResult) {
        // Check if calc values have been changed
        const currentRes = current.calcResult.find(res => res.type === headRes.type && res.id === headRes.id);
        if (
          currentRes &&
          headRes.values.some(headValue =>
            currentRes.values.every(currentValue => headValue.field !== currentValue.field || headValue.value !== currentValue.value)
          )
        ) {
          return true;
        }
      }

      if (head.type in EntityTypeWithBaseSurface) {
        // Treat null and interpolated base surface as same
        const headBaseSurfaceInterpolated = !head.baseSurface || head.baseSurface.type === BaseSurfaceType.INTERPOLATED;
        const currentBaseSurfaceInterpolated = !current.baseSurface || current.baseSurface.type === BaseSurfaceType.INTERPOLATED;
        if (headBaseSurfaceInterpolated !== currentBaseSurfaceInterpolated) {
          return true;
        }

        if (
          head.baseSurface &&
          current.baseSurface &&
          (head.baseSurface.id !== current.baseSurface.id ||
            head.baseSurface.type !== current.baseSurface.type ||
            head.baseSurface.elevation !== current.baseSurface.elevation)
        ) {
          return true;
        }
      }

      // Check viz options changes for entity (after geojson has loaded)
      if (head.type in EntityTypeWithGeoJson && head.geoJson) {
        if (!!head.geoJson !== !!current.geoJson) {
          return true;
        }

        const headProps = head.geoJson?.features?.[0]?.properties as GeoJsonProperties;
        const currentProps = current.geoJson?.features?.[0]?.properties as GeoJsonProperties;

        if (headProps?.chartOptions?.isReversed !== currentProps?.chartOptions?.isReversed) {
          return true;
        }

        const headVizOptions: DeltaElevationVizOptions = headProps?.vizOptions;
        const currentVizOptions: DeltaElevationVizOptions = currentProps?.vizOptions;
        if (!!headVizOptions !== !!currentVizOptions) {
          return true;
        }

        if (
          headVizOptions &&
          currentVizOptions &&
          (headVizOptions.opacity !== currentVizOptions.opacity ||
            !isEqual(headVizOptions.deltaHeightsRange, currentVizOptions.deltaHeightsRange) ||
            !isEqual(headVizOptions.elevationRamp, currentVizOptions.elevationRamp))
        ) {
          return true;
        }
      }
    }

    const checkFields = ['name'];
    if (!(head.type in ModelEditType)) {
      checkFields.push('groupId', 'layerId');
    }

    if (head.type in AnnotationType) {
      const detailsCheckFields = ['description', 'date', 'status', 'priority', 'category', 'assignee', 'notifiedUsers', 'hiddenPosition'];
      const tabsCheckFields = ['notes', 'attachments', 'data', 'drawings'];
      checkFields.push(...detailsCheckFields, ...tabsCheckFields);
    }

    if (checkFields.some(field => head[field] !== current[field])) {
      return true;
    }

    if (positionsChanged(head, current)) {
      return true;
    }

    return false;
  };

  getMapEntityResourceType(type: EntityType) {
    if (type in MeasurementType) {
      return ResourceType.MEASUREMENT;
    } else if (type in AnalyticType) {
      return ResourceType.ANALYTIC;
    } else if (type in AnnotationType) {
      return ResourceType.ANNOTATION;
    } else if (type in ModelEditType) {
      return ResourceType.MODEL_EDIT;
    }
  }

  getMapEntityByResourceType(id: string, resourceType: ResourceType) {
    if (resourceType === ResourceType.MEASUREMENT) {
      return this.measurementsQuery.getEntity(id);
    } else if (resourceType === ResourceType.ANALYTIC) {
      return this.analyticsQuery.getEntity(id);
    } else if (resourceType === ResourceType.ANNOTATION) {
      return this.annotationsQuery.getEntity(id);
    } else if (resourceType === ResourceType.MODEL_EDIT) {
      return this.modelEditsQuery.getEntity(id);
    }
  }

  getAllDrawings() {
    return this.drawingsQuery.getAll();
  }

  getDrawings() {
    return this.drawingsQuery.getAll({ filterBy: [({ markedForDelete }) => !markedForDelete] });
  }

  hasDrawings() {
    return isDefined(this.getDrawings());
  }

  getDrawing(id: string) {
    return this.drawingsQuery.getEntity(id);
  }

  getTempDrawings() {
    return this.drawingsQuery.getAll({ filterBy: [({ isTemp }) => isTemp] });
  }

  getDrawingEditors() {
    return this.getValue().drawingEditors;
  }

  getEditorByDrawingId(drawingId: string) {
    return this.getDrawingEditors().find(drawingEditor => drawingEditor.drawingId === drawingId)?.editor$;
  }

  getDrawingIdByEditorId(editorId: string) {
    return this.getDrawingEditors().find(drawingEditor => drawingEditor.editorId === editorId)?.drawingId;
  }

  isActiveDrawing(id: string) {
    return this.getActiveDrawingId() === id;
  }

  getActiveDrawingId(): string {
    return this.drawingsQuery.getActiveId() as string;
  }

  getActiveDrawing() {
    return this.drawingsQuery.getActive();
  }

  getActiveDrawingStyleProps() {
    return this.getValue().activeDrawingStyleProps;
  }

  hasHiddenDrawingLabels() {
    const hiddenLabelsCount = this.drawingsQuery.getCount(drawing => !drawing.markedForDelete && drawing.hiddenLabel);
    return hiddenLabelsCount > 0;
  }

  isActiveDrawingInCreation() {
    return this.getActiveDrawing()?.inCreation;
  }

  isDrawingToolsOpened() {
    return this.getValue().showDrawingTools;
  }
}
