import { Injectable } from '@angular/core';

import { CalcService } from '../../services/calc-services';
import { TerrainSamplingService } from '../../services/terrain-sampling.service';
import { DetailedSiteService } from '../../state/detailed-site.service';
import { FIELD_MAPPING } from '../../state/detailed-site.utils';
import { MapEntity, TaskOrDesignValues } from '../../state/detailed-site-entities/detailed-site-entities.model';

@Injectable({
  providedIn: 'root'
})
export class PointCalcService extends CalcService {
  constructor(private terrainSampling: TerrainSamplingService, private siteService: DetailedSiteService) {
    super();
  }

  async calcResults(
    entity: MapEntity,
    siteId: string,
    modelsData: { id: string; type: 'TASK' | 'DESIGN' }[]
  ): Promise<{ calcResult: TaskOrDesignValues[] }> {
    let calcResult: TaskOrDesignValues[] = entity?.calcResult ?? [];
    for (let i = 0; i < modelsData.length; i++) {
      const modelData = modelsData[i];
      const modelTerrain = await this.siteService.getTerrainProvider(siteId, modelData.id, modelData.type, entity.sourceModel);
      const positionsWithHeight = await this.terrainSampling.sampleTerrain(entity.positions, modelTerrain);
      calcResult = [
        ...calcResult,
        {
          id: modelData.id,
          type: modelData.type,
          values: [{ ...FIELD_MAPPING.elevation, value: positionsWithHeight[0].height }]
        }
      ];
    }

    return { calcResult };
  }
}
