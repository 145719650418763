import { Injectable } from '@angular/core';
import { Query, QueryEntity } from '@datorama/akita';

import { AccessLevelEnum } from '../../../auth/state/auth.utils';
import { User } from './users-and-teams.model';
import { UsersAndTeamsState, UsersAndTeamsStore } from './users-and-teams.store';

@Injectable({ providedIn: 'root' })
export class UsersAndTeamsQuery extends Query<UsersAndTeamsState> {
  private usersQuery = new QueryEntity(this.store.users);
  users$ = this.usersQuery.selectAll();

  private teamsQuery = new QueryEntity(this.store.teams);
  teams$ = this.teamsQuery.selectAll();

  constructor(protected store: UsersAndTeamsStore) {
    super(store);
  }

  getTenantAdmins() {
    return this.usersQuery.getAll({ filterBy: user => user.accessLevel === AccessLevelEnum.TENANTADMIN });
  }

  getUserByEmail(email: string) {
    return this.usersQuery.getAll().find((user: User) => user.email === email);
  }
}
