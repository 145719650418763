import { Injectable } from '@angular/core';
import { MapsManagerService } from '@datumate/angular-cesium';

import { isDefined } from '../../../shared/utils/general';

const ELEVATION_COLOR_CONTOUR_TYPE = 'ElevationColorContour';
const DOUBLE_CONTOUR_TYPE = 'DoubleContour';
const ELEVATION_RAMP_TYPE = 'ElevationRamp';
const DEFAULT_CONTOUR_COLOR = Cesium.Color.WHITE;
const MAJOR_CONTOUR_WIDTH = 3;
const MINOR_CONTOUR_WITH = 1;
const DEFAULT_MAJOR_CONTOUR_SPACING = 1;
const DEFAULT_MINOR_CONTOUR_SPACING = 0.25;

export interface ContourOptions {
  majorSpacing?: number;
  minorSpacing?: number;
  opacity?: number; // 0 - 1.0;
}

export interface ElevationOptions {
  maxHeight?: number; // with terrain offset height
  minHeight?: number; // with terrain offset height
  elevationRamp?: ColorRampStop[];
  opacity?: number; // 0 - 1.0;
}

export interface ColorRampStop {
  color: string;
  position: number; // 0 - 1.0;
}

export const DEFAULT_ELEVATION_RAMP_COLORS: ColorRampStop[] = [
  { color: '#0501ae', position: 0.0 }, // navy blue
  { color: '#01fefc', position: 0.25 }, // light blue
  { color: '#95ff66', position: 0.5 }, // green
  { color: '#fffa0b', position: 0.75 }, // yellow
  { color: '#ff1100', position: 1.0 } // red
];

@Injectable({
  providedIn: 'root'
})
export class ElevationContourService {
  elevationMaterial = this.createElevationMaterial();
  contourMaterial = this.createContourMaterial();
  contourElevationMaterial = this.createElevationContourMaterial();
  mapId = 'detailed-site';

  constructor(private mapsManager: MapsManagerService) {}

  updateContourLines(options: ContourOptions) {
    const viewer = this.mapsManager.getMap(this.mapId).getCesiumViewer();
    const globeMaterial = viewer.scene.globe.material;
    if (globeMaterial) {
      if (globeMaterial.type === DOUBLE_CONTOUR_TYPE || globeMaterial.type === ELEVATION_COLOR_CONTOUR_TYPE) {
        const contourMaterial =
          globeMaterial.type === DOUBLE_CONTOUR_TYPE ? globeMaterial.materials : globeMaterial.materials.contourMaterial.materials;

        const majorUniforms = contourMaterial.contourMaterialMajor.uniforms;
        majorUniforms.spacing = options.majorSpacing || majorUniforms.spacing;
        const minorUniforms = contourMaterial.contourMaterialMinor.uniforms;
        minorUniforms.spacing = options.minorSpacing || minorUniforms.spacing;

        if (typeof options.opacity === 'number') {
          majorUniforms.color = DEFAULT_CONTOUR_COLOR.withAlpha(options.opacity);
          minorUniforms.color = DEFAULT_CONTOUR_COLOR.withAlpha(options.opacity);
        }
      }
    }
  }

  updateElevationMaterial(options: ElevationOptions) {
    // Refactor after enterGeo
    const viewer = this.mapsManager.getMap(this.mapId).getCesiumViewer();
    const globeMaterial = viewer.scene.globe.material;
    if (globeMaterial) {
      if (globeMaterial.type === ELEVATION_RAMP_TYPE || globeMaterial.type === ELEVATION_COLOR_CONTOUR_TYPE) {
        const elevationMaterial =
          globeMaterial.type === ELEVATION_RAMP_TYPE ? globeMaterial : globeMaterial.materials.elevationRampMaterial;

        const elevationUniforms = elevationMaterial.uniforms;
        if (isDefined(options.minHeight)) {
          elevationUniforms.minimumHeight = options.minHeight;
        }
        if (isDefined(options.maxHeight)) {
          elevationUniforms.maximumHeight = options.maxHeight;
        }
        if (isDefined(options.elevationRamp) || isDefined(options.opacity)) {
          elevationUniforms.image = this.getColorRampImage(options.elevationRamp, options.opacity);
        }
      }
    }
  }

  toggleGlobe(
    elevation: { show: boolean; options: ElevationOptions },
    contour: { show: boolean; generationInProgress: boolean; options: ElevationOptions }
  ) {
    const viewer = this.mapsManager.getMap(this.mapId).getCesiumViewer();

    if (contour.show && elevation.show) {
      viewer.scene.globe.material = this.contourElevationMaterial;
      this.updateElevationMaterial(elevation.options);
      this.updateContourLines(contour.options);
    } else if (!contour.show && elevation.show) {
      viewer.scene.globe.material = this.elevationMaterial;
      this.updateElevationMaterial(elevation.options);
    } else if (contour.show && !elevation.show) {
      viewer.scene.globe.material = this.contourMaterial;
      this.updateContourLines(contour.options);
    } else {
      this.clear();
    }
  }

  clear() {
    const viewer = this.mapsManager.getMap(this.mapId).getCesiumViewer();
    viewer.scene.globe.material = undefined;
  }

  private getColorRampImage(rampOptions: ColorRampStop[] = DEFAULT_ELEVATION_RAMP_COLORS, alpha = 1.0) {
    const ramp = document.createElement('canvas');
    ramp.width = 100;
    ramp.height = 1;
    const ctx = ramp.getContext('2d');

    const grd = ctx.createLinearGradient(0, 0, 100, 0);
    grd.addColorStop(0, '#000000'); // black
    rampOptions.forEach(option => {
      const position = 0.95 * option.position + 0.05; // trick for black color around the map
      // (without this, color for min value will be there)
      grd.addColorStop(position, option.color);
    });

    ctx.globalAlpha = alpha;
    ctx.fillStyle = grd;
    ctx.fillRect(0, 0, 100, 1);

    return ramp;
  }

  private createContourMaterial() {
    return new Cesium.Material({
      fabric: this.createContourFabric(),
      translucent: false
    });
  }

  private createElevationMaterial() {
    const material = Cesium.Material.fromType(ELEVATION_RAMP_TYPE);
    material.uniforms.minimumHeight = 0;
    material.uniforms.maximumHeight = 100;
    material.uniforms.image = this.getColorRampImage();
    return material;
  }

  private createElevationContourMaterial() {
    // Creates a composite material with both elevation shading and contour lines
    return new Cesium.Material({
      fabric: {
        type: ELEVATION_COLOR_CONTOUR_TYPE,
        materials: {
          contourMaterial: this.createContourFabric(),
          elevationRampMaterial: {
            type: ELEVATION_RAMP_TYPE,
            uniforms: {
              minimumHeight: 0,
              maximumHeight: 100,
              image: this.getColorRampImage()
            }
          }
        },
        components: {
          diffuse: 'contourMaterial.alpha == 0.0 ? elevationRampMaterial.diffuse : contourMaterial.diffuse',
          alpha: 'max(contourMaterial.alpha, elevationRampMaterial.alpha)'
        }
      },
      translucent: false
    });
  }

  private createContourFabric() {
    return {
      type: DOUBLE_CONTOUR_TYPE,
      materials: {
        contourMaterialMajor: {
          type: 'ElevationContour',
          uniforms: {
            width: MAJOR_CONTOUR_WIDTH,
            spacing: DEFAULT_MAJOR_CONTOUR_SPACING,
            color: DEFAULT_CONTOUR_COLOR
          }
        },
        contourMaterialMinor: {
          type: 'ElevationContour',
          uniforms: {
            width: MINOR_CONTOUR_WITH,
            spacing: DEFAULT_MINOR_CONTOUR_SPACING,
            color: DEFAULT_CONTOUR_COLOR
          }
        }
      },
      components: {
        diffuse: 'contourMaterialMajor.alpha == 0.0 ? contourMaterialMinor.diffuse : contourMaterialMajor.diffuse',
        alpha: 'max(contourMaterialMinor.alpha, contourMaterialMajor.alpha)'
      }
    };
  }
}
