import { Injectable } from '@angular/core';
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita';

import { isDefined } from '../utils/general';
import { generateResourceLinksEntityId, ResourceLink, ResourceLinkEntity, ResourceLinkType } from './resource-links.model';

export interface ResourceLinksState extends EntityState<ResourceLinkEntity> {}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'resource-links' })
export class ResourceLinksStore extends EntityStore<ResourceLinksState> {
  constructor() {
    super();
  }

  insertResourceLinks(resourceId: string, resourceType: ResourceLinkType, links: ResourceLink[]) {
    this.updateResourceLinks(resourceId, resourceType, links, false);
  }

  updateResourceLinks(resourceId: string, resourceType: ResourceLinkType, links: ResourceLink[], isDirty: boolean) {
    const id = generateResourceLinksEntityId(resourceId, resourceType);

    if (!isDefined(links)) {
      // Remove from store if no links
      this.removeResourceLinks(resourceId, resourceType);
    } else {
      this.upsert(id, { id, resourceId, resourceType, links, isDirty });
    }
  }

  removeResourceLinks(resourceId: string, resourceType: ResourceLinkType) {
    const id = generateResourceLinksEntityId(resourceId, resourceType);
    this.remove(id);
  }

  removeResource(resourceId: string, resourceType: ResourceLinkType) {
    this.removeResourceLinks(resourceId, resourceType);

    this.update(null, entity => {
      const updatedLinks = entity.links.filter(link => link.resourceId !== resourceId || link.resourceType !== resourceType);
      return {
        ...entity,
        links: updatedLinks
      };
    });
  }

  removeResourcesOfType(resourceIds: string[], resourceType: ResourceLinkType) {
    if (!isDefined(resourceIds)) {
      return;
    }

    this.remove(resourceIds.map(resourceId => generateResourceLinksEntityId(resourceId, resourceType)));

    const idsSet = new Set(resourceIds);
    this.update(null, entity => {
      const updatedLinks = entity.links.filter(link => !idsSet.has(link.resourceId) || link.resourceType !== resourceType);
      return {
        ...entity,
        links: updatedLinks
      };
    });
  }

  removeAllResourcesOfType(resourceType: ResourceLinkType) {
    this.remove((entity: ResourceLinkEntity) => entity.resourceType === resourceType);

    this.update(null, entity => {
      const updatedLinks = entity.links.filter(link => link.resourceType !== resourceType);
      return {
        ...entity,
        links: updatedLinks
      };
    });
  }
}
