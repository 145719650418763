import moment from 'moment';

import { isDefined } from '../../shared/utils/general';
import { GeoUtils } from '../../shared/utils/geo';
import { avgWithoutOutliers } from '../../shared/utils/math';
import {
  FileToUpload,
  ImageToApprove,
  PerImagesValidationWarning,
  RTKStatus,
  TotalValidationWarning,
  ValidationError,
  ValidationLimits
} from './upload-wizard.model';

const BAD_RTK_LON_LAT_THRESHOLD = 0.1;
const BAD_RTK_HEIGHT_THRESHOLD = 0.2;

export const TotalValidationWarningMessage: Partial<Record<TotalValidationWarning, string>> = {
  [TotalValidationWarning.INVALIDRTKDATA]: $localize`:@@uploadWizard.imageValidation.totalValidationWarningInvalidRTK:Low-quality RTK data in images may reduce model accuracy in affected areas.`,
  [TotalValidationWarning.TOTALTIMEDIFFERENCE]: $localize`:@@uploadWizard.imageValidation.totalValidationWarningMaxTotalTime:Acquisition time exceeded 4 hours. Model quality may be impacted by changing sun angles.`,
  [TotalValidationWarning.TIMEDIFFERENCE]: $localize`:@@uploadWizard.imageValidation.totalValidationWarningTimeDifference:Time difference between neighboring images exceeds 1 hour. Model quality may be impacted by changing sun angles.`,
  [TotalValidationWarning.MISSINGCOVERAGE]: $localize`:@@uploadWizard.imageValidation.totalValidationWarningMissingCoverage:Excessive distance between images may create gaps in the model.`,
  [TotalValidationWarning.HEIGHTDIFFERENCE]: $localize`:@@uploadWizard.imageValidation.totalValidationWarningHeightDiff:Excessive height variation between images may create gaps in the model.`
};

export const PerImagesValidationWarningMessage: Partial<Record<PerImagesValidationWarning, string>> = {
  [PerImagesValidationWarning.INVALIDRTKDATA]: $localize`:@@uploadWizard.imageValidation.perImageValidationWarningInvalidRTK:Image holds low-quality RTK data.`,
  [PerImagesValidationWarning.TIMEDIFFERENCE]: $localize`:@@uploadWizard.imageValidation.perImageValidationWarningTimeDifference:Time is different from previous image by more than 1 hour.`,
  [PerImagesValidationWarning.MISSINGCOVERAGE]: $localize`:@@uploadWizard.imageValidation.perImageValidationWarningMissingCoverage:Excessive frontal distance from previous image.`,
  [PerImagesValidationWarning.HEIGHTDIFFERENCE]: $localize`:@@uploadWizard.imageValidation.perImageValidationWarningHeightDiff:Excessive height variation from previous image.`
};

export const PerImagesValidationErrorMessage: Partial<Record<ValidationError, string>> = {
  [ValidationError.NOEXIFDATA]: $localize`:@@uploadWizard.imageValidation.perImageValidationErrorNoExif:Image must be removed as it does not hold EXIF data.`,
  [ValidationError.INVALIDRESOLUTION]: $localize`:@@uploadWizard.imageValidation.perImageValidationErrorInvalidRes:Image must be removed as it does not hold resolution data.`,
  [ValidationError.LARGERESOLUTION]: $localize`:@@uploadWizard.imageValidation.perImageValidationErrorLargeRes:Resolution is greater than 50mpx.`,
  [ValidationError.DIFFERENTRESOLUTIONS]: $localize`:@@uploadWizard.imageValidation.perImageValidationErrorDifferentRes:Image must be removed as it holds different resolution data than previous image.`,
  [ValidationError.DIFFERENTMODELS]: $localize`:@@uploadWizard.imageValidation.perImageValidationErrorDifferentModel:All images must be taken using the same camera model.`,
  [ValidationError.NOLOCATION]: $localize`:@@uploadWizard.imageValidation.perImageValidationErroNoLocation:Image must be removed as it holds incomplete location data.`
};

export const TotalValidationErrorMessage: Partial<Record<ValidationError, string>> = {
  [ValidationError.NOEXIFDATA]: $localize`:@@uploadWizard.imageValidation.totalValidationErrorNoExif:Model cannot be generated: images lack EXIF data.`,
  [ValidationError.INVALIDRESOLUTION]: $localize`:@@uploadWizard.imageValidation.totalValidationErrorInvalidRes:Model cannot be generated: images lack resolution data.`,
  [ValidationError.LARGERESOLUTION]: $localize`:@@uploadWizard.imageValidation.totalValidationErrorLargeRes:Images with resolution greater than 50mpx were detected. Please upload images with lower resolution.`,
  [ValidationError.DIFFERENTRESOLUTIONS]: $localize`:@@uploadWizard.imageValidation.totalValidationErrorDifferentRes:Model cannot be generated: inconsistent resolution across images.`,
  [ValidationError.DIFFERENTMODELS]: $localize`:@@uploadWizard.imageValidation.totalValidationErrorDifferentModel:Model cannot be generated. All images must be taken using the same camera model.`,
  [ValidationError.NOLOCATION]: $localize`:@@uploadWizard.imageValidation.totalValidationErrorNoLocation:Model cannot be generated: images have incomplete location data.`
};

export type ValidationIssue = PerImagesValidationWarning | ValidationError;
export const TotalValidationIssuesMessage = { ...TotalValidationWarningMessage, ...TotalValidationErrorMessage };
export const PerImagesValidationIssuesMessage = {
  ...PerImagesValidationWarningMessage,
  ...PerImagesValidationErrorMessage
};

export interface ValidationIssues {
  errors: ValidationError[];
  warnings: PerImagesValidationWarning[];
}

export function getFileName(path: string) {
  if (!path) {
    return null;
  }

  const fileNamePosition = path.lastIndexOf('/');
  return path.slice(fileNamePosition + 1);
}

export class FileValidationUtils {
  static twoFilesDistance(checkedFile: FileToUpload, comparedFile: FileToUpload) {
    if (checkedFile.exif?.location && comparedFile.exif?.location) {
      const checkedLocation = { longitude: checkedFile.exif.location.longitude, latitude: checkedFile.exif.location.latitude };
      const comparedLocation = { longitude: comparedFile.exif.location.longitude, latitude: comparedFile.exif.location.latitude };
      return GeoUtils.distance(checkedLocation, comparedLocation);
    }
  }

  private static twoFilesAbsHeightDelta(checkedFile: FileToUpload, comparedFile: FileToUpload) {
    if (
      (checkedFile.exif?.location?.height || checkedFile.exif?.location?.height === 0) &&
      (comparedFile.exif?.location?.height || comparedFile.exif?.location?.height === 0)
    ) {
      const checkedHeight = checkedFile.exif.location.height;
      const comparedHeight = comparedFile.exif.location.height;
      return Math.abs(checkedHeight - comparedHeight);
    }
  }

  static twoFilesTimeDelta(checkedFile: FileToUpload, comparedFile: FileToUpload) {
    if (checkedFile?.exif?.date && comparedFile?.exif?.date) {
      const checkedDate = checkedFile.exif.date;
      const comparedDate = comparedFile.exif.date;
      return moment(checkedDate).diff(comparedDate, 'minutes') / 60;
    }
  }

  static avgSequenceDistanceWithoutOutliers(files: FileToUpload[]) {
    if (files && files.length > 0) {
      const distances = files
        .map((file, i) => {
          if (i > 0) {
            const prevFile = files[i - 1];
            return this.twoFilesDistance(file, prevFile);
          }
        })
        .filter(value => value !== undefined);

      if (isDefined(distances)) {
        return avgWithoutOutliers(distances);
      }
    }
  }

  static async calculateIssues(checkedFile: FileToUpload, prevFile: FileToUpload, limits: ValidationLimits): Promise<ValidationIssues> {
    if (!checkedFile) {
      return undefined;
    }

    const errors: ValidationError[] = [];
    const warnings: PerImagesValidationWarning[] = [];

    if (!checkedFile.exif) {
      errors.push(ValidationError.NOEXIFDATA);
    } else if (checkedFile?.exif) {
      if (!this.hasResolution(checkedFile)) {
        errors.push(ValidationError.INVALIDRESOLUTION);
      } else {
        if (!this.isResolutionValid(checkedFile, limits.maxResolutionPx)) {
          errors.push(ValidationError.LARGERESOLUTION);
        }
        if (prevFile?.exif && this.hasResolution(prevFile) && this.isResolutionDifferent(checkedFile, prevFile)) {
          errors.push(ValidationError.DIFFERENTRESOLUTIONS);
        }
      }

      if (!this.hasLocation(checkedFile)) {
        errors.push(ValidationError.NOLOCATION);
      } else {
        if (prevFile?.exif && this.hasLocation(prevFile)) {
          if (this.isHeightTooDifferent(checkedFile, prevFile, limits.absHeightDeltaMeter)) {
            warnings.push(PerImagesValidationWarning.HEIGHTDIFFERENCE);
          }
          if (this.isFilesTooFar(checkedFile, prevFile, limits.upperDistanceMeter)) {
            warnings.push(PerImagesValidationWarning.MISSINGCOVERAGE);
          }
        }
      }

      if (this.hasModel(checkedFile) && prevFile?.exif && this.hasModel(prevFile) && this.isModelDifferent(checkedFile, prevFile)) {
        errors.push(ValidationError.DIFFERENTMODELS);
      }

      if (prevFile?.exif && this.isTimeTooDifferent(checkedFile, prevFile, limits.neighboursTimeDeltaHour)) {
        warnings.push(PerImagesValidationWarning.TIMEDIFFERENCE);
      }
    }

    return { errors, warnings };
  }

  private static isResolutionValid(checkedFile: FileToUpload, resolutionLimit: number): boolean {
    const calcResolution = checkedFile.exif.resolution.width * checkedFile.exif.resolution.height;
    return calcResolution < resolutionLimit;
  }

  static getRTKStatus(checkedFile: FileToUpload): RTKStatus {
    const {
      camModel,
      rtk: { flag, lat, lon, height }
    } = checkedFile.exif;

    if ([lat, lon, height].every(val => !isDefined(val) || val === 0)) {
      return RTKStatus.NONE;
    }

    if (lon > BAD_RTK_LON_LAT_THRESHOLD || lat > BAD_RTK_LON_LAT_THRESHOLD || height > BAD_RTK_HEIGHT_THRESHOLD) {
      return RTKStatus.BAD;
    }

    const isDJI = camModel?.toLowerCase().startsWith('dji');
    if (isDJI) {
      if (!isDefined(flag)) {
        return RTKStatus.NONE;
      } else if (flag === 50) {
        return RTKStatus.GOOD;
      } else {
        return RTKStatus.BAD;
      }
    } else {
      return RTKStatus.GOOD;
    }
  }

  private static hasLocation(checkedFile: FileToUpload): boolean {
    return (
      isDefined(checkedFile.exif?.location?.latitude) &&
      isDefined(checkedFile.exif?.location?.longitude) &&
      isDefined(checkedFile.exif?.location?.height)
    );
  }

  private static hasModel(checkedFile: FileToUpload): boolean {
    return isDefined(checkedFile.exif.camModel);
  }

  private static hasResolution(checkedFile: FileToUpload): boolean {
    return isDefined(checkedFile.exif.resolution?.width) && isDefined(checkedFile.exif.resolution?.height);
  }

  private static isHeightTooDifferent(checkedFile: FileToUpload, comparedFile: FileToUpload, heightDeltaLimit: number): boolean {
    const heightAbsDelta = this.twoFilesAbsHeightDelta(checkedFile, comparedFile) ?? null;
    return heightAbsDelta !== null && heightAbsDelta > heightDeltaLimit;
  }

  private static isFilesTooFar(checkedFile: FileToUpload, comparedFile: FileToUpload, distanceUpperLimit: number): boolean {
    const sequenceDistance = this.twoFilesDistance(checkedFile, comparedFile) ?? null;
    return sequenceDistance !== null && sequenceDistance > distanceUpperLimit;
  }

  private static isTimeTooDifferent(checkedFile: FileToUpload, comparedFile: FileToUpload, timeDeltaLimit: number): boolean {
    const timeDelta = this.twoFilesTimeDelta(checkedFile, comparedFile) ?? null;
    return timeDelta !== null && timeDelta > timeDeltaLimit;
  }

  private static isModelDifferent(checkedFile: FileToUpload, comparedFile: FileToUpload): boolean {
    return checkedFile.exif.camModel !== comparedFile.exif.camModel;
  }

  private static isResolutionDifferent(checkedFile: FileToUpload, comparedFile: FileToUpload): boolean {
    return (
      checkedFile.exif.resolution.width !== comparedFile.exif.resolution.width ||
      checkedFile.exif.resolution.height !== comparedFile.exif.resolution.height
    );
  }
}

export class ImageValidationUtils {
  static twoImagesDistance(checkedImage: ImageToApprove, comparedImage: ImageToApprove) {
    const checkedLocation = { longitude: checkedImage.longitude, latitude: checkedImage.latitude };
    const comparedLocation = { longitude: comparedImage.longitude, latitude: comparedImage.latitude };
    return GeoUtils.distance(checkedLocation, comparedLocation);
  }

  private static twoImagesAbsHeightDelta(checkedImage: ImageToApprove, comparedImage: ImageToApprove) {
    if ((checkedImage.height || checkedImage.height === 0) && (comparedImage.height || comparedImage.height === 0)) {
      return Math.abs(checkedImage.height - comparedImage.height);
    }
  }

  static avgSequenceDistanceWithoutOutliers(images: ImageToApprove[]) {
    if (images && images.length > 0) {
      const distances = images
        .map((file, i) => {
          if (i > 0) {
            const prevFile = images[i - 1];
            return this.twoImagesDistance(file, prevFile);
          }
        })
        .filter(value => value !== undefined);

      if (isDefined(distances)) {
        return avgWithoutOutliers(distances);
      }
    }
  }

  static async calculateWarnings(
    checkedImage: ImageToApprove,
    prevImage: ImageToApprove,
    limits: ValidationLimits
  ): Promise<PerImagesValidationWarning[]> {
    if (!checkedImage || !prevImage) {
      return undefined;
    }
    const warnings: PerImagesValidationWarning[] = [];
    if (this.isHeightTooDifferent(checkedImage, prevImage, limits.absHeightDeltaMeter)) {
      warnings.push(PerImagesValidationWarning.HEIGHTDIFFERENCE);
    }
    if (this.isImagesTooFar(checkedImage, prevImage, limits.upperDistanceMeter)) {
      warnings.push(PerImagesValidationWarning.MISSINGCOVERAGE);
    }
    return warnings;
  }

  private static isHeightTooDifferent(checkedImage: ImageToApprove, comparedImage: ImageToApprove, heightDeltaLimit: number): boolean {
    const heightAbsDelta = this.twoImagesAbsHeightDelta(checkedImage, comparedImage) ?? null;
    return heightAbsDelta !== null && heightAbsDelta > heightDeltaLimit;
  }

  private static isImagesTooFar(checkedImage: ImageToApprove, comparedImage: ImageToApprove, distanceUpperLimit: number): boolean {
    const sequenceDistance = this.twoImagesDistance(checkedImage, comparedImage) ?? null;
    return sequenceDistance !== null && sequenceDistance > distanceUpperLimit;
  }
}
