import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';

import { FeatureHintPlace, FeatureHintsState, FeatureHintsStore } from './feature-hints.store';

@Injectable({ providedIn: 'root' })
export class FeatureHintsQuery extends QueryEntity<FeatureHintsState> {
  constructor(protected store: FeatureHintsStore) {
    super(store);
  }

  selectHintsByPlace(place: FeatureHintPlace) {
    return this.selectAll({
      filterBy: entity => entity.place === place
    });
  }

  getHintsByPlace(place: FeatureHintPlace) {
    return this.getAll({
      filterBy: entity => entity.place === place
    });
  }
}
